import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchActivity } from '../../redux/actions/ipp/dashboard';
import { getActivityList, getActivityTotal, getActivityLoading } from '../../redux/reducers/ipp/dashboard';
import CardContainer from '../components/container/card_container';
import DashboardList from './dashboard_list';
import { getRootPath, removeIreqIdInitialNumber, formatCaseId } from '../../common/functions';

const CASE_STATUS = [
  'Case Expedite Request Approved',
  'Case Expedite Request Updated',
  'Case Shipped',
  'Clinical Review Completed',
  'Doctor Clarification Resolved',
  'Unhold',
  'External Target Date Override',
];
const IR_STATUS = [
  'Item Request Expedite Request Approved',
  'Item Request Expedite Request Updated',
  'Item Request Shipped',
  'Item Request Unheld',
  'Item ETD Override',
];
const SS_STATUS = ['Cancelled'];

const STATUS_TRANSLATION = {
  'Case Expedite Request Approved': 'Case Expedited',
  'Case Expedite Request Updated': 'Case Est. Ship Date Updated',
  'Case Shipped': 'Case Shipped',
  'Clinical Review Completed': 'Clinical Review Completed',
  'Doctor Clarification Resolved': 'Doctor Clarification Provided',
  'Item Request Expedite Request Approved': 'IR Expedited',
  'Item Request Expedite Request Updated': 'IR Est. Ship Date Updated',
  'Item ETD Override': 'IR Est. Ship Date Updated',
  'Item Request Shipped': 'IR Shipped',
  'Item Request Unheld': 'IR Hold Removed',
  'External Target Date Override': 'Case Est. Ship Date Updated',
  Released: 'Smile Simulation Relased',
  Cancelled: 'Smile Simulation Cancelled',
  Unhold: 'Case Hold Removed',
};

function translateStatus(status) {
  if (status in STATUS_TRANSLATION) return STATUS_TRANSLATION[status];
  return status;
}

function getDescription(item) {
  return `${item.name} (${formatCaseId(item.display_id)})`;
}

function getDeliveryDetail(item) {
  let detail = '';
  let detail_url = '';
  if (item.hand_delivery_ind) detail = 'Hand Delivered';
  else if (item.tracking_info && item.tracking_info !== 'NA' && item.tracking_info !== 'N/A') {
    detail = 'Tracking Info';
    detail_url = item.tracking_url;
  }
  return { detail, detail_url };
}

function getURL(rootPath, item) {
  const isCase = CASE_STATUS.includes(item.status);
  const isIR = IR_STATUS.includes(item.status);
  const isSS = SS_STATUS.includes(item.status);

  if (isCase) {
    return `${rootPath}/case/${item.id}`;
  } else if (isIR) {
    return `${rootPath}/item/${item.id}`;
  } else if (isSS) {
    return `${rootPath}/smile/detail/${item.id}`;
  } else {
    return `${rootPath}/dashboard`;
  }
}

function getList(activity) {
  const rootPath = getRootPath(window.location.pathname);
  const activityItems = activity.map((item) => ({
    id: item.id,
    title: translateStatus(item.status),
    description: getDescription(item),
    date: item.date,
    url: getURL(rootPath, item),
    ...getDeliveryDetail(item),
  }));
  return activityItems;
}

function Activity(props) {
  const { doctor_id, activity, activity_total, activity_loading, fetchActivity } = props;

  useEffect(() => {
    fetchActivity(doctor_id);
  }, [doctor_id, fetchActivity]);

  const onReachBottom = useCallback(() => {
    if (!activity_loading) fetchActivity(doctor_id, activity.length);
  }, [activity_loading, doctor_id, activity, fetchActivity]);

  const hasMore = activity.length < activity_total;
  const isInitialLoad = activity.length === 0 && activity_loading;

  return (
    <CardContainer className="pad-md dashboard-list-container">
      <h3 className="dashboard-list__title">Activity</h3>
      <DashboardList items={getList(activity)} hasMore={hasMore} onReachBottom={onReachBottom} loading={isInitialLoad} emptyMessage="No Recent Activity" />
    </CardContainer>
  );
}

Activity.propTypes = {
  doctor_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  activity: PropTypes.array.isRequired,
  activity_total: PropTypes.number.isRequired,
  activity_loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activity: getActivityList(state),
    activity_total: getActivityTotal(state),
    activity_loading: getActivityLoading(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActivity: fetchActivity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
