// External
import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

//Internal
import Modal from '../../../components/modal/modal';
import WireSelectionOutput from './wire_selection_output';
import WireSelectionOverride from './wire_selection_override';
import WireSelectionProcess from './wire_selection_process';
import WireSelectionManualUpload from './wire_selection_manual_upload';

import { handleHttpRequestError } from '../../../common/error';
import { getPDFNameTemplate, isCaseBlocked, onReloadPage } from '../../../common/helpers';
import { removeCaseIdInitialNumber } from '../../../common/functions';

import './wire_selection.scss';
import WireSelectionRedoProgress from './wire_selection_redo_progress';

import SessionExpire from '../../../common/session_expire';
import {
  getBmfFile,
  getCustomWoFileNameError,
  getDxfFileNameError,
  getWireSelectionStatus,
  getWs,
  getWsAssignedTo,
  getWsDeSelection,
  getWsFiles,
  getWsGuideFileNameError,
  getWsStatusCommentJson,
  getWsStatuses,
  getWsTemp,
  getWsWo,
  getWsWoHistory,
  getWsPrograms,
} from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import {
  setWireManufacturingStatus,
  setDxfFileNameError,
  setCustomWoFileNameError,
  fetchWireSelectionStatus,
  setWireSelectionStatus,
  setWsAssignedTo,
  setWireSelectionInfo,
  setWsGuideFileNameError,
  fetchWireSelectionPrograms,
} from '../../../redux/actions/bpp/wire_selection/wire_selection';
import {
  getWireFabricationReviewStatus,
  getWireFabricationReviewStatusLog,
  getWiresPrevRev,
} from '../../../redux/reducers/bpp/wire_selection/wire_fabrication_review';
import { fetchIfuState } from '../../../redux/actions/ifu/ifu_state';

class TemplateContainer extends Component {
  state = {
    ws_review_assigned_to_options: [],
    mark_success_modal: false,
    mark_failed_modal: false,
    modal: '',
    modal_file: {},
    upload_modal: {},
    remove_modal: {},
    url: '',
    refresh: false,
    wo_filename_error: false,
  };

  componentDidMount() {
    this.props.fetchWireSelectionPrograms(this.props.doctor_id);
    this.props.fetchWireSelectionStatus(this.props.case_id);
    this.loadAssignedToOptions(this.props.case_details.ws_version === '1.0' ? 'Engineering' : 'Wire Selection Reviewer');
    this.loadAssignedToOptions('PRPL');
  }

  /**
   * Checks if wire selection process has errors
   * @Returns {Boolean} error status
   */
  hasWsErrors = () => {
    const error_state =
      Object.values(this.props.dxf_file_name_error).some((err) => err === true) ||
      (this.props.custom_wo_file_name_error && this.props.custom_wo_file_name_error.error) ||
      this.props.ws_guide_file_name_error;
    return error_state;
  };

  componentDidUpdate() {
    if (this.hasWsErrors() && this.state.upload_modal) {
      this.setState({
        upload_modal: '',
      });
    }
  }

  componentWillUnmount() {
    this.resetDxfFileNameError();
    this.props.setWsGuideFileNameError(false);
  }

  updateAssignedTo = (assigned_to) => {
    const that = this;
    const data = {
      assignment_id: assigned_to.value,
      prev_assignment_id: this.props.ws_assigned_to.value,
      stage: this.props.ws_status,
    };

    Axios.post(`/apiv3/wireselectionassignment/${this.props.case_id}`, data)
      .then(function (res) {
        that.props.setWsAssignedTo({
          label: assigned_to.label,
          value: assigned_to.value,
        });
      })
      .catch(function (err) {
        console.error(`Error updating assignment:`, err);
        handleHttpRequestError(err, that);
      });
  };

  wsAction = (action) => {
    const that = this;
    const data = {
      site_url: window.location.origin,
    };
    Axios.post(`/apiv3/wireselection/${this.props.case_id}/${action}`, data)
      .then(function (res) {
        if (res && res.data) {
          that.setState({ modal: '' });
          that.props.setWireSelectionInfo(res.data);
          if (action === 'complete_ws' && res.data.wm_status) {
            that.props.setWireManufacturingStatus(res.data.wm_status);
            const ws_version = that.props.case_details.ws_version;
            if (ws_version && ws_version !== '1.0') {
              Axios.post(
                `/api/email/?slug=ready-for-wire-cutting-process&caseId=${removeCaseIdInitialNumber(that.props.case_id)}&method=standard&provider=${
                  window.location.origin
                }`
              );
            }
          }
          if (action === 'generate' && that.props.case_id.includes('-DE')) {
            that.props.regenProductionTxGuide();
          }
          if (action === 'complete_review') {
            that.props.fetchIfuState(that.props.case_id);
          }

          that.props.setWireSelectionStatus(res.data.ws_status);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  wsRedo = (selections) => {
    const that = this;
    Axios.post(`/apiv3/wireselection/${this.props.case_id}/redo`, selections)
      .then(function (res) {
        if (res && res.data) {
          that.setState({ modal: '' });
          that.props.setWireSelectionInfo(res.data);
          that.props.setWireSelectionStatus(res.data.ws_status);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  wsUpload = (data) => {
    const that = this;
    Axios.put(`/apiv3/wireselection/${this.props.case_id}/upload`, data)
      .then(function (res) {
        if (res && res.data) {
          that.props.setWireSelectionInfo(res.data);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Handles remove event for wire selection files
   * @param {String} case_id Wire Selection associated case id
   * @param {String} type type of case file
   * @param {String} uri optional file location
   */
  wsRemove = (case_id, type, uri) => {
    const that = this;
    Axios.post(`/apiv3/wireselection/${this.props.case_id}/remove`, {
      case_id: case_id,
      type: type,
      uri: uri,
    })
      .then(function (res) {
        if (res && res.data) {
          that.setState({ remove_modal: '' });
          that.props.setWireSelectionInfo(res.data);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Opens upload modal
   * @function
   * @param {string} wire_name - Wire name
   * @param {string} label - Modal header label
   * @param {string} type - Upload type
   * @param {boolean} redo - Redo indicator
   * @param {string} wsg_rev - Wire selection guide rev
   * @param {boolean} replace - Indicator if file is replacing old file
   */
  onUploadClick = (wire_name, label, type, redo, wsg_rev = null, replace = false) => {
    this.resetDxfFileNameError();
    this.props.setWsGuideFileNameError(false);
    const temp = redo ? true : false;

    let config = {
      header_text: `${label} - ${removeCaseIdInitialNumber(this.props.case_id)}`,
      case_id: this.props.case_id,
      type: type,
      onUpload: (data) => {
        this.onUpload(data, this.props.case_id, wire_name, label, type, redo, wsg_rev, replace);
      },
      location: type === 'ws_guide' ? 'ws_guide_manual' : type === 'ws_custom_wo' ? 'ws_custom_wo' : `${type}_manual_${wire_name}${temp ? '_temp' : ''}`,
      replace: replace,
      wsg_rev: wsg_rev,
      ws_version: this.props.case_details.ws_version,
    };

    if (type === 'ws_guide') {
      const manual_guide = this.props.ws_files.filter((ws_file) => {
        return ws_file.file_type === 'ws_guide' && ws_file.upload_data.includes('_manual');
      });
      if (manual_guide.length > 0) {
        const manual_guide_sort_by_id = _.orderBy(manual_guide, ['id'], ['desc']);
        config.wsg_manual_id = manual_guide_sort_by_id[0].id;
      }
    }

    if (type === 'ws_dxf') {
      let prev_file_name = '';
      for (const file of this.props.ws_files) {
        if (file.upload_data.includes(`${wire_name}_manual`) && (temp ? file.upload_data.includes('/TEMP/') : true)) {
          prev_file_name = file.original_filename;
          break;
        }
      }
      config['prev_ws_manual_file_name'] = prev_file_name;
      config['wire_name'] = wire_name;
    }

    if (type === 'ws_custom_wo') {
      this.props.setCustomWoFileNameError(false, '');
    }

    this.setState({
      upload_modal: config,
    });
  };

  /**
   * Get message text for remove file modal
   * @returns {String} Removal Upload Message
   */
  getRemovalText(type) {
    const message_text = {
      ws_custom_wo: 'Are you sure you want to remove this custom work order?',
      ws_guide: 'Are you sure you want to remove the Wire Selection Guide?',
      ws_manual: 'Are you sure you want to remove this manual custom wire?',
    };
    return message_text[type];
  }

  /**
   * Wire selection file remove click event handler
   * @param {string} wire_location manual wire db path
   */
  onRemoveClick = (label, type, uri = '') => {
    let config = {
      header_text: `${label} - ${removeCaseIdInitialNumber(this.props.case_id)}`,
      message_text: this.getRemovalText(type),
      case_id: this.props.case_id,
      type: type,
      onConfirmButtonClick: () => this.wsRemove(this.props.case_id, type, uri),
    };

    this.setState({
      remove_modal: config,
    });
  };

  /**
   * Handles upload
   * @function
   * @param {object} data - File data
   * @param {string} case_id - Case id
   * @param {string} wire_name - Wire name
   * @param {string} label - Modal header label
   * @param {string} type - Upload type
   * @param {boolean} redo - Redo indicator
   * @param {string} wsg_rev - Wire selection guide rev
   * @param {boolean} replace - Indicator if file is replacing old file
   */
  onUpload = (data, case_id, wire_name, label, type, redo, wsg_rev = null, replace = false) => {
    data[0]['case_id'] = case_id;
    data[0]['mime_type'] = type === 'ws_dxf' ? 'application/dxf' : type === 'ws_guide' || type === 'ws_custom_wo' ? 'application/pdf' : 'unknown';
    data[0]['file_type'] = type === 'ws_dxf' ? 'ws_manual' : type === 'ws_guide' ? 'ws_guide' : type === 'ws_custom_wo' ? 'ws_custom_wo' : 'unknown';
    data[0]['wire_name'] = wire_name;
    data[0]['temp'] = redo;
    data[0]['replace'] = replace;
    data[0]['wsg_rev'] = wsg_rev;

    this.wsUpload(data);
    this.setState({ upload_modal: '' });
  };

  /**
   * Toggles checkbox for custom wire special properties
   * @param {object} selected_wire
   * @param {string} wire_name
   * @param {string} wire_special
   * @param {boolean} prev_checked_value
   */
  wsToggleSpecial = (selected_wire, wire_name, wire_special, prev_checked_value) => {
    if (selected_wire === 'manual_custom') {
      const that = this;
      Axios.put(`/apiv3/wireselection/${this.props.case_id}/toggle_special`, {
        wire_name: wire_name,
        wire_special: wire_special,
        prev_checked: prev_checked_value,
      })
        .then(function (res) {
          if (res && res.data) {
            that.setState({ upload_modal: '' });
            that.props.setWireSelectionInfo(res.data);
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  wsSelect = (selection, wire_name) => {
    let prev_ws_selection = '';
    for (const wire of this.props.ws) {
      if (wire.wire_name === wire_name) {
        prev_ws_selection = wire.selection_override;
        break;
      }
    }

    const that = this;

    let data = [
      {
        wire_name: wire_name,
        selection: selection,
        prev_wire_selection: prev_ws_selection,
      },
    ];

    Axios.put(`/apiv3/wireselection/${this.props.case_id}/set_template`, data)
      .then(function (res) {
        if (res && res.data) {
          that.setState({ upload_modal: '' });
          that.props.setWireSelectionInfo(res.data);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  wsSetWo = (wo) => {
    const that = this;

    let data = [
      {
        wo: wo,
        prev_wo: this.props.ws_wo,
      },
    ];

    Axios.put(`/apiv3/wireselection/${this.props.case_id}/set_wo`, data)
      .then(function (res) {
        if (res && res.data) {
          that.props.setWireSelectionInfo(res.data);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  setModal = (modal, modal_file = {}) => {
    this.setState({
      modal: modal,
      modal_file: modal_file,
    });
  };

  clearModal = () => {
    this.setState({
      modal: '',
      modal_file: {},
    });
  };

  displayModal = () => {
    const that = this;
    const { modal, modal_file } = this.state;
    const case_id = removeCaseIdInitialNumber(this.props.case_id);
    const is_manual_upload = modal_file?.upload_data?.includes('manual');

    const modals = {
      convert_success: {
        header_text: `Mark as Success - ${case_id}`,
        message_text: `Are you sure you would like to force the wire generation process to succeed?`,
        confirm_btn_text: `Mark as Success`,
      },
      convert_failed: {
        header_text: `Mark as Failed - ${case_id}`,
        message_text: `Are you sure you would like to force the wire generation process to fail?`,
        confirm_btn_text: `Mark as Failed`,
      },
      retry: {
        header_text: `Retry - ${case_id}`,
        message_text: `Are you sure you want to retry?`,
        confirm_btn_text: `Retry`,
      },
      guide_success: {
        header_text: `Mark as Success - ${case_id}`,
        message_text: `Are you sure you would like to force the guide generation process to succeed?`,
        confirm_btn_text: `Mark as Success`,
      },
      guide_failed: {
        header_text: `Mark as Failed - ${case_id}`,
        message_text: `Are you sure you would like to force the guide generation process to fail?`,
        confirm_btn_text: `Mark as Failed`,
      },
      override: {
        header_text: `Override Process - ${case_id}`,
        message_text: `Are you sure you want to override process?`,
        confirm_btn_text: `Override`,
      },
      generate: {
        header_text: `Wire Selection Guide Generation - ${case_id}`,
        message_text: `Are you sure you want to generate the Wire Selection Guide?`,
        confirm_btn_text: `Generate`,
      },
      complete_review: {
        header_text: `Wire Selection Review - ${case_id}`,
        message_text: `Are you sure you want to mark the Wire Selection Review process as complete?`,
        confirm_btn_text: `Proceed`,
      },
      go_back: {
        header_text: `Return to Wire Selection Review - ${case_id}`,
        message_text: `Are you sure you want to return to Wire Selection Review?`,
        confirm_btn_text: `Return`,
      },
      complete_ws: {
        header_text: `Custom Wire WO Creation - ${case_id}`,
        message_text: `Are you sure you want to mark the Custom Wire WO Creation process as complete?`,
        confirm_btn_text: `Complete`,
      },
      ws_pdf: {
        preset: 'pdf_viewer',
        header_text: `Wire Selection Guide - ${case_id}`,
        modal_size_class: 'modal-lg modal-lg-pdf',
        modal_body_class: 'modal-pdf',
        pdf_url: modal_file?.upload_data,
        pdf_date: modal_file?.created_date,
        close_btn_text: 'Close',
        original_filename: getPDFNameTemplate(
          this.props.case_id,
          this.props.patient_info,
          `F021-17_Wire_Selection_Guide${is_manual_upload ? '_Manual' : ''}.pdf`
        ),
      },
      bmf_pdf: {
        preset: 'pdf_viewer',
        header_text: `Adjusted Bracket Measurements Form - ${case_id}`,
        modal_size_class: 'modal-lg modal-lg-pdf',
        modal_body_class: 'modal-pdf',
        pdf_url: modal_file?.upload_data,
        pdf_date: modal_file?.created_date,
        close_btn_text: 'Close',
        original_filename: getPDFNameTemplate(this.props.case_id, this.props.patient_info, `F021-16_Adjusted_BMF${is_manual_upload ? '_Manual' : ''}.pdf`),
      },
      wo_pdf: {
        preset: 'pdf_viewer',
        header_text: `Custom Work Order - ${case_id}`,
        modal_size_class: 'modal-lg modal-lg-pdf',
        modal_body_class: 'modal-pdf',
        pdf_url: modal_file?.upload_data,
        pdf_date: modal_file?.created_date,
        original_filename: modal_file?.original_filename,
        close_btn_text: 'Close',
      },
      ws_log: {
        preset: 'log',
        log_type: 'wire_selection',
        header_text: 'Wire Selection Log',
        case_id: this.props.case_id,
        selected_tab: 'case',
        close_btn_text: 'Close',
      },
    };

    const { ws_status } = { ...this.props };
    const is_not_complete = ws_status !== 'WS Complete';

    if (modal in modals) {
      return (
        <Modal
          preset={'preset' in modals[modal] ? modal['preset'] : 'decision'}
          close_btn_text={'close_btn_text' in modals[modal] ? 'Close' : 'Cancel'}
          onConfirmButtonClick={() => {
            that.wsAction(modal);
          }}
          onCloseButtonClick={this.clearModal}
          theme="bpp"
          {...modals[modal]}
          watermark={is_not_complete}
        />
      );
    }

    return null;
  };

  loadAssignedToOptions = (group) => {
    const that = this;
    Axios.get('/apiV2/processtracker', {
      params: { action: 'get_users_group_list', group: group },
    })
      .then(function (response) {
        const result = response.data.data.task_results['get_users_group_list'];
        let assigned_to_options = [];

        const mapAssignToOptions = (userData) => {
          return {
            label: `${userData['user__first_name']} ${userData['user__last_name']}`,
            value: userData['user__id'],
          };
        };

        assigned_to_options = result.map(mapAssignToOptions);
        assigned_to_options = _.orderBy(assigned_to_options, ['label']);
        assigned_to_options.unshift({
          label: 'Unassigned',
          value: '',
        });

        if (group === 'Wire Selection Reviewer' || group === 'Engineering') {
          that.setState({
            ws_review_assigned_to_options: assigned_to_options,
          });
        } else if (group === 'PRPL') {
          that.setState({
            ws_custom_assigned_to_options: assigned_to_options,
          });
        }
      })
      .catch(function (err) {
        console.error(`Error getting job role users:`, err);
      });
  };

  loadingText = () => {
    return <div className="center-text">Loading...</div>;
  };

  /**
   * Sets DXF file name error to false
   * @function
   */
  resetDxfFileNameError = () => {
    this.props.setDxfFileNameError(false, 'case_id');
    this.props.setDxfFileNameError(false, 'format');
  };

  selectDisplayByStatus = () => {
    const ws_status = this.props.ws_status;
    const ws_props = {
      ...this.state,
      ...this.props,
      case_id: this.props.case_id,
      wire_selection_stage: ws_status,
      ws_status_comment:
        this.props.ws_statuses.length > 0
          ? this.props.ws_statuses[0] === '{'
            ? JSON.load(this.props.ws_statuses[0].status_comment)
            : this.props.ws_statuses[0].status_comment
          : '',
      ws_automated_file: this.props.ws_statuses.length > 0 ? this.props.ws_statuses[0].automated_file : '',
      is_cancel_or_hold: isCaseBlocked(this.props.case_status),
      updateAssignedTo: this.updateAssignedTo,
      onUploadClick: this.onUploadClick,
      onRemoveClick: this.onRemoveClick,
      wsSelect: this.wsSelect,
      setModal: this.setModal,
      wsSetWo: this.wsSetWo,
      wsAction: this.wsAction,
      wsRedo: this.wsRedo,
      wsToggleSpecial: this.wsToggleSpecial,
      case_details: this.props.case_details,
      arch: this.props.arch,
      patient_info: this.props.patient_info,
      current_rev: this.getCurrentRev(),
    };

    if ((this.props.case_status === 'STATUS_SHIP' || this.props.case_status === 'STATUS_MANUFACTURE') && ws_status === '') {
      return <WireSelectionOverride {...ws_props} />;
    }

    const displayByStatus = {
      '': this.loadingText(),
      'WS Conversion': <WireSelectionProcess {...ws_props} />,
      'WS Conversion Succeeded': <WireSelectionProcess {...ws_props} />,
      'WS Conversion Failed': <WireSelectionProcess {...ws_props} />,
      'WS Guide': <WireSelectionProcess {...ws_props} />,
      'WS Review': <WireSelectionProcess {...ws_props} />,
      'WS WO Status': <WireSelectionProcess {...ws_props} />,
      'WS Override': <WireSelectionOverride {...ws_props} />,
      'WS Complete': <WireSelectionOutput case_status={this.props.case_status} {...ws_props} />,
      'WS Redo': <WireSelectionRedoProgress {...ws_props} />,
    };

    return ws_status in displayByStatus ? displayByStatus[ws_status] : this.loadingText();
  };

  /**
   * Retrieves current rev
   * @function
   * @param {list} wires - List of wires
   * @returns {string} - Current rev
   */
  getCurrentRev = () => {
    for (const wire of this.props.ws) {
      if (wire.current_rev) {
        return wire.wire_rev.includes('-') ? wire.wire_rev.substr(0, wire.wire_rev.indexOf('-')) : wire.wire_rev;
      }
    }
    return 'A';
  };

  render() {
    const upload_modal = this.state.upload_modal;
    const remove_modal = this.state.remove_modal;
    const assignable_statuses = ['WS Conversion Succeeded', 'WS Review', 'WS WO Status'];
    const ws_unassigned = assignable_statuses.indexOf(this.props.ws_status) !== -1 && (!this.props.ws_assigned_to || !this.props.ws_assigned_to.value);

    return (
      <div
        className={`wire-selection-container${ws_unassigned && this.props.case_details.ws_version === '1.0' ? ' wire-selection-container__unassigned' : ''}`}
      >
        <div className="bold-text business-dark-heading">
          Wire Selection
          {this.props.case_details.ws_version && this.props.case_details.ws_version !== '1.0' && (
            <div
              className="pull-right btn-light-link"
              data-case_id={this.props.case_id}
              onClick={() => this.setModal('ws_log')}
              data-toggle="tooltip"
              data-placement="top"
              title="Wire Selection Log"
            >
              <i className="fa fa-file-text-o" aria-hidden="true" />
            </div>
          )}
        </div>
        <div className="business-dark-theme">{this.selectDisplayByStatus()}</div>
        {this.displayModal()}

        {upload_modal && upload_modal.header_text ? (
          <WireSelectionManualUpload
            {...upload_modal}
            onClose={() => {
              this.setState({ upload_modal: {} });
            }}
          />
        ) : null}

        {remove_modal && remove_modal.header_text ? (
          <Modal
            {...remove_modal}
            preset="decision"
            confirm_btn_text="Confirm"
            close_btn_text="Cancel"
            onCloseButtonClick={() => {
              this.setState({
                remove_modal: '',
              });
            }}
            theme="bpp"
          />
        ) : null}

        {this.props.refresh ? (
          <Modal
            preset="action"
            x_btn={false}
            header_text="Session Expired"
            message_text="Sorry, your session has expired. Please refresh."
            confirm_btn_text="Refresh"
            onConfirmButtonClick={onReloadPage}
            theme="bpp"
          />
        ) : null}
        {this.state.refresh && <SessionExpire />}
      </div>
    );
  }
}

TemplateContainer.propTypes = {
  dxf_file_name_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dxf_file_name_error: getDxfFileNameError(state),
    custom_wo_file_name_error: getCustomWoFileNameError(state),
    ws_status: getWireSelectionStatus(state),
    ws: getWs(state),
    ws_files: getWsFiles(state),
    ws_assigned_to: getWsAssignedTo(state),
    ws_statuses: getWsStatuses(state),
    ws_wo: getWsWo(state),
    ws_wo_history: getWsWoHistory(state),
    bmf_file: getBmfFile(state),
    ws_temp: getWsTemp(state),
    ws_de_selection: getWsDeSelection(state),
    ws_status_comment_json: getWsStatusCommentJson(state),
    wire_fabrication_review_status: getWireFabricationReviewStatus(state),
    wire_fabrication_review_status_log: getWireFabricationReviewStatusLog(state),
    ws_prev_rev: getWiresPrevRev(state),
    ws_guide_file_name_error: getWsGuideFileNameError(state),
    ws_programs: getWsPrograms(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDxfFileNameError: setDxfFileNameError,
      setCustomWoFileNameError: setCustomWoFileNameError,
      setWireManufacturingStatus: setWireManufacturingStatus,
      fetchWireSelectionStatus: fetchWireSelectionStatus,
      fetchWireSelectionPrograms: fetchWireSelectionPrograms,
      setWireSelectionStatus: setWireSelectionStatus,
      setWsAssignedTo: setWsAssignedTo,
      setWireSelectionInfo: setWireSelectionInfo,
      setWsGuideFileNameError: setWsGuideFileNameError,
      fetchIfuState: fetchIfuState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TemplateContainer);
