// External
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal
import Modal from '../../../components/modal/modal';
import { handleHttpRequestError } from '../../../common/error';
import ProcessTracker from '../../../components/workflow/process_tracker';
import { userHasPermission } from '../../../common/permission';
import { getCaseLastStatus, getLastStatusBySelectedCase, isCaseBlocked, getGuideType, getPDFNameTemplate, getAbbreviation } from '../../../common/helpers';
import { isTodayPastDate } from '../../../common/date';
import { isApplianceDesignStage } from '../../../common/case/case_status';
import { textFieldCheck, removeCaseIdInitialNumber, convertDate } from '../../../common/functions';
import SessionExpire from '../../../common/session_expire';
import { onZipClick } from '../../../common/dropbox';

// Sass
import './cad_container.scss';

//Redux Actions
import { fetchIfuState } from '../../../redux/actions/ifu/ifu_state';
import {
  openTxInstructionFileModal,
  openBpgFileModal,
  closeTxInstructionFileModal,
  closeBpgFileModal,
  openTxInstructionManualUploadModal,
  closeTxInstructionManualUploadModal,
  onManualUpload,
} from '../../../redux/actions/ifu/ifu_result';
import { setProductionTxGuideTabConfig } from '../../../redux/actions/bpp/production_tx_guide/production_tx_guide';
import { fetchDdmState } from '../../../redux/actions/ddm/ddm_state';
import { openSpecsFileModal, closeSpecsFileModal } from '../../../redux/actions/ddm/ddm_result';
import { displaySingleToothIdbOutput, fetchSingleToothIdb } from '../../../redux/actions/bpp/single_tooth_idb/single_tooth_idb';

//Redux Reducers
import { getSpecsFileModal } from '../../../redux/reducers/ddm/ddm_result';
import { getUploadPending } from '../../../redux/reducers/ifu/ifu_upload';
import { getPending } from '../../../redux/reducers/ddm/ddm_upload';
import { getDdmState } from '../../../redux/reducers/ddm/ddm_state';
import { getIfuState } from '../../../redux/reducers/ifu/ifu_state';
import { getTxInstructionFileModal, getBpgFileModal, getTxInstructionManualUploadModal } from '../../../redux/reducers/ifu/ifu_result';
import { getIsAfter3420Launch } from '../../../redux/reducers/bpp/production_tx_guide/production_tx_guide';
import {
  getIsHistoricalSingleToothIdb,
  getSingleToothIdbFinalFile,
  getSingleToothIdbRawFile,
  getSingleToothIdbStatus,
} from '../../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';
import { getWs } from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import { getWireFabricationReviewStatus } from '../../../redux/reducers/bpp/wire_selection/wire_fabrication_review';
class CADContainer extends Component {
  constructor(props) {
    super(props);

    this.BUSINESS_ROLE = 1;

    this.state = {
      assign_to_options: [],
      assignable: false,
      cad_assigned_to: [
        {
          label: 'Unassigned',
          value: '',
        },
      ],
      return_loading: false,
      setupPath: '',
      editable_status_comment: '',
      quality_control_stage_1_reason: '',
      status_comment: '',
      bmf_rev: '',
      bmf_rev_incomplete_error: false,
      checklist_pdf_modal: false,
      appliance_design_log_modal: false,
      current_stage: '',
      selected_stage: '',
      refresh: false,
      showStageIModal: false,
      uploadPending: false,
      retryIfuModal: false,
      txRegeneratedManualFile: null,
    };

    this.handleAssignTo = this.handleAssignTo.bind(this);
  }

  componentDidMount() {
    this.setState({ is_mounted: true }, this.initializeAssignedTo);
    this.setState({
      selected_stage: this.getSelectedStage(this.props.cases),
      current_stage: this.getCurrentStage(this.props.cases),
    });
    if (this.props.cases.gen_2) {
      this.props.fetchSingleToothIdb(this.props.case_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cases !== prevProps.cases) {
      this.setState({
        cad_assigned_to: this.getCadAssignedTo(this.props.cases), // Do not delete this
        selected_stage: this.getSelectedStage(this.props.cases),
        current_stage: this.getCurrentStage(this.props.cases),
      });
    }
  }

  handleAssignTo = (cad_assigned_to) => {
    if (cad_assigned_to) {
      this.setState({ cad_assigned_to: [cad_assigned_to] });
      this.updateCaseAssignment(cad_assigned_to.value);
    }
  };

  initializeAssignedTo = () => {
    this.loadAssignToOptions();
  };

  onStatusCommentChange = (event) => {
    const editable_status_comment_value = textFieldCheck(event.target.value);

    this.setState({
      editable_status_comment: editable_status_comment_value,
    });
  };

  /**
   * Loads Assigned To options
   * @function
   */
  loadAssignToOptions = () => {
    try {
      if (this.state.is_mounted) {
        var that = this;

        Axios.get('/apiV2/permission').then(function (res) {
          const permission_list = res.data.permission_list_raw;

          if (permission_list) {
            that.setState({ permission_list: permission_list, assignable: permission_list.includes('CASE_DETAILS_VIEW') });
          }
        });

        Axios.get('/apiV2/processtracker', {
          params: { action: 'get_users_group_list', group: 'CAD' },
        })
          .then(function (response) {
            const result = response.data.data.task_results['get_users_group_list'];
            let assign_to_options = [];

            const mapAssignToOptions = (userData) => {
              return {
                label: `${userData['user__first_name']} ${userData['user__last_name']}`,
                value: userData['user__id'],
              };
            };

            assign_to_options = result.map(mapAssignToOptions);
            assign_to_options = _.orderBy(assign_to_options, ['label']);
            assign_to_options.unshift({
              label: 'Unassigned',
              value: '',
            });

            if (assign_to_options && assign_to_options.length > 0) {
              that.setState({ assign_to_options: assign_to_options });
            }
          })
          .catch(function (err) {
            console.error(`Error getting job role users:`, err);
          });

        that.setState({
          cad_assigned_to: that.getCadAssignedTo(that.props.cases),
        });

        if (this.hasNoCadStatusHistory()) {
          Axios.get('/apiV2/processtracker', {
            params: { action: 'get_assigned_user', case_id: this.props.case_id },
          })
            .then(function (response) {
              const result = response.data.data.task_results['get_assigned_user'];
              if (result) {
                const mapSetupAssignToUser = (userData) => {
                  if (userData['assignment__first_name'] === null) {
                    return {
                      label: 'Unassigned',
                      value: '',
                    };
                  } else {
                    return {
                      label: `${userData['assignment__first_name']} ${userData['assignment__last_name']}`,
                      value: userData['assignment_id'],
                    };
                  }
                };

                let cad_assigned_to = result.map(mapSetupAssignToUser)[0];
                if (mapSetupAssignToUser && mapSetupAssignToUser.length > 0 && cad_assigned_to && !that.isHoldDueToCreditHold()) {
                  that.setState({ cad_assigned_to: [cad_assigned_to] });
                }
              }
            })
            .catch(function (err) {
              console.error(`Error retrieving assigned user of case:`, err);
            });
        }
      }
    } catch (error) {
      console.log('Error loading assign to options', Error);
    }
  };

  /**
   * Checks if the case is on hold due to credit hold
   * @function
   * @return {Boolean} - Returns if the conditions are met for the hold due to credit hold
   */
  isHoldDueToCreditHold = () => {
    return (
      this.props.cases.status_code === 'STATUS_HOLD' &&
      this.props.cases.hold_description === 'Credit Hold' &&
      this.props.cases.cad_process.previous_status === ''
    );
  };

  /**
   * Moves case to next stage after user clicks on Proceed/Approve button throughout the Appliance Design (CAD) section
   * @function
   * @param {String} action - Action describing which stage to enter
   * @return {Promise} - Promise object from API call
   */
  onCadProceedClick = (action) => {
    let form_data = new FormData();
    if (action === 'reject_stage_1') form_data.append('status_comment', this.state.quality_control_stage_1_reason);
    else if (this.state?.status_comment) form_data.append('status_comment', this.state.status_comment);
    const that = this;
    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/${action}`, form_data)
      .then(function (res) {
        that.setState({ quality_control_stage_1_reason: '' });
        if (res && res.data) {
          if (action === 'reject_stage_1') {
            Axios.post(
              `/api/email/?slug=assigned-case-qc-stage-1-rejected&caseId=${removeCaseIdInitialNumber(that.props.case_id)}&method=standard&doctorId=${
                that.props.case_details.doctor.id
              }&provider=${window.location.origin}`
            );
          } else if (action === 'approve_stage_1' && that.props.cases.gen_2) {
            that.props.fetchSingleToothIdb(that.props.case_id);
          }
          return res;
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Handles event when status_comment changed
   * @function
   * @param {Object} event - Event object
   */
  onDigiBpStatusCommentChange = (status_comment) => {
    this.setState({ status_comment });
  };

  /**
   * Clears status_comment in QC review reject modal
   * @function
   */
  clearDigiBpStatusComment = () => {
    this.setState({ status_comment: '' });
  };

  /**
   * Handles event when user enters a note for rejecting Stage 1
   * @function
   * @param {Object} event - Event object
   */
  onQualityControlStageIRejectReasonChange = (event) => {
    this.setState({
      quality_control_stage_1_reason: event.target.value,
    });
  };

  /**
   * Clears reason text field in QC review reject modal
   * @function
   */
  clearQualityControlStageIRejectReason = () => {
    this.setState({
      quality_control_stage_1_reason: '',
    });
  };

  /**
   * Handles event when user rejects QC review
   * @function
   * @param {String} selected_revision_stage - Selected stage to send the case back to
   * @return {Promise} - Promise from API call
   */
  onQcReviewRejectClick = (selected_revision_stage) => {
    const that = this;
    let form_data = new FormData();

    const cad_assigned_to_history = this.getCadAssignedToHistory();
    form_data.append('status_comment', this.state.editable_status_comment);
    form_data.append('cad_revision_stage', selected_revision_stage);
    form_data.append('cad_revision_user_id', cad_assigned_to_history[selected_revision_stage].value);

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/reject_qc_review`, form_data)
      .then(function (res) {
        if (res && res.data) {
          that.props.reload_information();
          for (const cases of res.data.cases) {
            if (cases.case_id === that.props.case_id) {
              that.props.setProductionTxGuideTabConfig('CAD Process', that.getSelectedStage(cases), cases);
              that.setState({
                cad_assigned_to: that.getCadAssignedTo(cases),
              });
            }
          }
          that.setBmfRevIncompleteError(false);
          if (selected_revision_stage === 'Stage I') that.props.fetchSingleToothIdb(that.props.case_id);

          Axios.post(
            `/api/email/?slug=assigned-case-qc-review-rejected&qc_review_reject_stage=${selected_revision_stage}&caseId=${removeCaseIdInitialNumber(
              that.props.case_id
            )}&method=standard&doctorId=${that.props.case_details.doctor.id}&provider=${window.location.origin}`
          );
          return res;
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Handles event when user approves QC review
   * @function
   * @param {Object} event - Event object
   * @return {Promise} - Promise from API call
   */
  onQcReviewApprovedClick = (event) => {
    let form_data = new FormData();
    if (!this.props.cases.skip_production_tx_guide && !this.props.cases.gen_2) {
      form_data.append('bmf_rev', this.state.bmf_rev);
    }
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/approve_qc_review`, form_data)
      .then(function (res) {
        if (res && res.data) {
          for (const cases of res.data.cases) {
            if (cases.case_id === that.props.case_id) {
              that.props.setProductionTxGuideTabConfig('STATUS_MANUFACTURE', '', cases);
              that.props.regenerate_production_tx_guide(cases);
            }
          }
          if (that.props.cases.gen_2 && that.props.cases.ws_version === '1.0') {
            Axios.post(
              `/api/email/?slug=ready-for-wire-cutting-process&caseId=${removeCaseIdInitialNumber(that.props.case_id)}&method=standard&provider=${
                window.location.origin
              }`
            );
          }
          return res;
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Store ipr values in case_ipr table
   * @param {string} case_id - case_id string
   * @function
   */
  checkIprFile = async (case_id) => {
    const that = this;
    Axios.put(`/apiv3/case/${case_id}/ipr/store_ipr`).catch((err) => {
      handleHttpRequestError(err, that);
    });
  };

  updateCaseAssignment = (assigned_user) => {
    var that = this;
    return Axios.post(`/apiV2/processtracker`, {
      actions: ['update_case_assignment'],
      update_case_assignment: { case_id: that.props.case_id, user_id: assigned_user, stage: that.state.selected_stage },
    })
      .then(function (res) {
        that.props.reload_information();
      })
      .catch(function (err) {
        console.error(`Error Updating Case Assignment for Case: ${that.props.case_id}`, err);
      });
  };

  txInstructionFileModal = () => {
    const instructions_type = this.props.case_id.includes('-DE') || this.props.case_id.includes('-R') ? 'Digital Enhancement' : 'Treatment';
    const original_filename = getPDFNameTemplate(
      this.props.case_id,
      this.props.patient_info,
      `${instructions_type.replace(' ', '_')}_Instructions${this.props.ifu_state && this.props.ifu_state.txinstruction_manual_file ? '_Manual' : ''}.pdf`
    );
    const file =
      this.props.ifu_state.txinstruction_regenerated_manual_file ??
      this.props.ifu_state.txinstruction_regenerated_file ??
      this.props.ifu_state.txinstruction_file ??
      this.props.ifu_state.txinstruction_manual_file;
    return (
      <Modal
        preset="pdf_viewer"
        header_text={`${instructions_type} Instructions - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={file.upload_data}
        pdf_date={file?.created_date}
        onCloseButtonClick={this.props.closeTxInstructionFileModal}
        theme="bpp"
        original_filename={original_filename}
      />
    );
  };
  /**
   * Modal to be display for bpg/afg
   * @function
   * @return {JSX} The pdf modal to be shown
   */
  bpgFileModal = () => {
    const bpg_afg_file_type = this.props.ifu_state?.bpg_file?.file_type === 'bpg_afg' ? true : false;
    const guide_type = getGuideType(this.props.cases.gen_2);

    const original_filename = getPDFNameTemplate(
      this.props.case_id,
      this.props.patient_info,
      `${bpg_afg_file_type ? 'BPG_AFG' : getAbbreviation(guide_type, ' ')}${this.props.ifu_state && this.props.ifu_state.bpg_manual_file ? '_Manual' : ''}.pdf`
    );
    const file = this.props.ifu_state.historical_vbpc_file ?? this.props.ifu_state.bpg_file ?? this.props.ifu_state.bpg_manual_file;
    return (
      <Modal
        preset="pdf_viewer"
        header_text={`${guide_type} - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={file.upload_data}
        pdf_date={file?.created_date}
        onCloseButtonClick={this.props.closeBpgFileModal}
        theme="bpp"
        original_filename={original_filename}
      />
    );
  };

  /**
   * Renders stage I Modal
   * @returns {JSX} Return to Stage I Modal
   */
  returnStageIModal = () => {
    const header_text = `Return to Appliance Design Stage I - ${removeCaseIdInitialNumber(this.props.case_id)}`;
    const message_text =
      'Returning back to Appliance Design Stage I would remove all the information from Stage I, Stage I Review, Stage II and Stage III. Are you sure you want to return to Appliance Design Stage I?';
    return (
      <Modal
        theme="bpp"
        preset="decision-return-stage-one"
        header_text={header_text}
        message_text={message_text}
        in_progress={this.state.return_loading}
        in_progress_text={'Returning back to Appliance Design Stage I'}
        confirm_btn_text="Return"
        close_btn_text="Cancel"
        onCloseButtonClick={this.hideStageIModal}
        onConfirmButtonClick={this.returnToStageI}
      />
    );
  };

  /**
   * Displays checklist PDF modal
   * @function
   */
  checklistModal = () => {
    return (
      <Modal
        preset="pdf_viewer"
        header_text={'QC Virtual Inspection Checklist - ' + removeCaseIdInitialNumber(this.props.case_id)}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={this.props.cases.checklist_file_path}
        onCloseButtonClick={this.closeChecklistPdfModal}
        theme="bpp"
        case_id={this.props.case_id}
        original_filename={getPDFNameTemplate(this.props.case_id, this.props.case_details.patient, 'F014-01_QC_Checklist.pdf')}
      />
    );
  };

  /**
   * Displays Appliance Design Log modal
   * @function
   */
  applianceDesignLogModal = () => {
    return (
      <Modal
        preset="log"
        log_type="appliance_design"
        case_id={this.props.case_id}
        selected_tab={this.props.selected_tab}
        header_text="Appliance Design Log"
        onCloseButtonClick={() => this.toggleApplianceDesignLogModal(false)}
        theme="bpp"
      />
    );
  };

  /**
   * Displays Specification Form modal
   * @function
   */
  specsModal = () => {
    const form_number = this.props.cases.gen_2 ? 'F021-40_GEN_II_DDM_Specification' : '3436-01_DDM_Specification';
    const original_filename = getPDFNameTemplate(
      this.props.case_id,
      this.props.patient_info,
      `${form_number}${this.props.ddm_state && this.props.ddm_state.specs_manual_file ? '_Manual' : ''}.pdf`
    );
    const file = this.props.ddm_state.specs_file ?? this.props.ddm_state.specs_manual_file;
    return (
      <Modal
        preset="pdf_viewer"
        header_text={`DDM Specification Form - ` + removeCaseIdInitialNumber(this.props.ddm_state.case_id)}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={file?.upload_data}
        pdf_date={file?.created_date}
        onCloseButtonClick={this.props.closeSpecsFileModal}
        theme="bpp"
        pdf_type="specs_file"
        case_id={this.props.ddm_state.case_id}
        original_filename={original_filename}
      />
    );
  };

  /**
   * Retrieves currently selected cad stage for the case
   * @function
   * @param {Object} cases - Case information
   * @return {String} - Cad stage
   */
  getSelectedStage = (cases) => {
    if (getLastStatusBySelectedCase(cases) === 'Quality Control Review') return 'Quality Control Review';
    if (cases.status_code === 'Quality Control Review') return cases.status_code;
    for (const cad_process of cases.cad_status_log) {
      for (const cad_status of cad_process.cad_statuses) {
        if (cad_status.is_selected) {
          return cad_status.cad_status;
        }
      }
    }
    return this.getCurrentStage(cases);
  };

  /**
   * Retrieves current cad stage for a case
   * @function
   * @param {Object} cases - Case information
   * @return {String} - Cad stage
   */
  getCurrentStage = (cases) => {
    if (getLastStatusBySelectedCase(cases) === 'Quality Control Review') return 'Quality Control Review';
    if (cases.status_code === 'Quality Control Review') return cases.status_code;
    for (const cad_process of cases.cad_status_log) {
      for (const cad_status of cad_process.cad_statuses) {
        if (cad_status.current_ind) {
          return cad_status.cad_status;
        }
      }
    }
    return 'Stage I';
  };

  /**
   * Gets the Assigned To for the case based on CAD status
   * @function
   * @param {Object} cases - Case information
   * @param {string} selected_stage - Current selected stage of case
   * @return {List} - Assigned To information
   */
  getCadAssignedTo(cases, selected_stage = null) {
    let stage = selected_stage ? selected_stage : this.getSelectedStage(cases);

    for (const cad_process of cases.cad_status_log) {
      for (const cad_status of cad_process.cad_statuses) {
        if (cad_status.cad_status === stage) {
          if (cad_status.assignment__first_name) {
            return [
              {
                label: `${cad_status.assignment__first_name} ${cad_status.assignment__last_name}`,
                value: cad_status.assignment_id.toString(),
              },
            ];
          }
          return [
            {
              label: 'Unassigned',
              value: '',
            },
          ];
        }
      }
    }
    return [
      {
        label: 'Unassigned',
        value: '',
      },
    ];
  }

  /**
   * Retrieves current stage of case
   * @function
   * @return {String} - Stage
   */
  getProcessState(cad_status) {
    const valid_statuses = ['CAD Process', 'STATUS_CANCEL', 'STATUS_HOLD', 'Doctor Provide Clarification'];
    if (valid_statuses.indexOf(this.props.get_case_status) === -1) {
      return this.props.get_case_status;
    }
    if (cad_status === 'Stage III') {
      if (this.props.ifu_state) {
        if (this.props.ifu_state.ifu_screen === 'result') {
          if (this.isIFUGenerationSuccessful() && this.state.current_stage === 'Quality Control Review') {
            return 'ifu-success';
          }
          if (this.isIFUGenerationFailed()) {
            return 'ifu-fail';
          }
        } else {
          return this.props.ifu_state.ifu_screen;
        }
      }
      return cad_status;
    }

    if (cad_status === 'Stage II') {
      if (this.props.ddm_state.ddm_screen === 'ddm-result') {
        if (this.isDDMGenerationSuccessful() && this.state.current_stage === 'Stage III') {
          return 'ddm-success';
        }
        if (this.isDDMGenerationFailed()) {
          return 'ddm-fail';
        }
      } else {
        return this.props.ddm_state.ddm_screen;
      }
    }
    return cad_status;
  }

  /**
   * Retrieves Assigned To for all stages in CAD process
   * @function
   * @return {Object} - Assigned To for all stages
   */
  getCadAssignedToHistory = () => {
    let assigned_to = {
      'Stage I': { name: '', value: '' },
      'Quality Control Stage I': { name: '', value: '' },
      'Stage II': { name: '', value: '' },
      'Stage III': { name: '', value: '' },
    };
    for (const cad_process of this.props.cases.cad_status_log) {
      for (const cad_status of cad_process.cad_statuses) {
        if (!assigned_to[cad_status.cad_status].name && cad_status.assignment__first_name) {
          assigned_to[cad_status.cad_status].name = `${cad_status.assignment__first_name} ${cad_status.assignment__last_name}`;
          assigned_to[cad_status.cad_status].value = cad_status.assignment_id ? cad_status.assignment_id.toString() : '';
        }
      }
    }

    if (!assigned_to['Stage I'].name) {
      for (const cad_process of this.props.cases.cad_status_log) {
        if (cad_process.cad_statuses.length === 0) {
          assigned_to['Stage I'].name = `${cad_process.assignment__first_name} ${cad_process.assignment__last_name}`;
          assigned_to['Stage I'].value = cad_process.assignment_id ? cad_process.assignment_id.toString() : '';
          break;
        }
      }
    }
    return assigned_to;
  };

  /**
   * Determines if case has no CAD status in its history (used for WIP cases in CAD process)
   * @function
   * @param {Boolean} - True or False
   */
  hasNoCadStatusHistory() {
    for (const cad_process of this.props.cases.cad_status_log) {
      if (cad_process.cad_statuses.length > 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * Handles event when user enters BMF Rev #
   * @function
   * @param {Object} event - Event object
   */
  onBmfRevChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ bmf_rev: event.target.value, bmf_rev_incomplete_error: false });
    }
  };

  /**
   * Sets error when BMF Rev # is not filled out
   * @function
   * @param {Boolean} - Error indicator
   */
  setBmfRevIncompleteError = (error) => {
    this.setState({
      bmf_rev_incomplete_error: error,
    });
  };

  openChecklistPdfModal = () => {
    this.setState({
      checklist_pdf_modal: true,
    });
  };

  closeChecklistPdfModal = () => {
    this.setState({
      checklist_pdf_modal: false,
    });
  };

  toggleApplianceDesignLogModal = (open) => {
    this.setState({
      appliance_design_log_modal: open,
    });
  };

  setCadAssignedTo = (cases) => {
    this.setState({
      cad_assigned_to: this.getCadAssignedTo(cases),
    });
  };

  /**
   * Displays appliance design target completion date
   * @function
   * @returns {JSX} JSX for target completion date
   */
  displayTargetCompletionDate = () => {
    return (
      <div className="target-date pull-right">
        <span>Target Completion Date: </span>
        <span>{this.props.cases.appliance_design_target_date ? convertDate(this.props.cases.appliance_design_target_date) : 'N/A'}</span>
      </div>
    );
  };

  /**
   * Handles event when user toggles between Stage II and Stage III
   * @function
   * @param {string} stage - Selected stage
   */
  onStageChange = (stage) => {
    const that = this;
    const data = {
      stage: stage,
    };
    Axios.put(`/apiv3/cadstatus/${this.props.case_id}`, data)
      .then(function (res) {
        that.setState({
          selected_stage: stage,
          cad_assigned_to: that.getCadAssignedTo(that.props.cases, stage),
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Determines if IFU generation is successful
   * @function
   * @returns {boolean} - True or false
   */
  isIFUGenerationSuccessful = () => {
    if (this.props.ifu_state && this.props.ifu_state.ifu_status === 'IFU Override Process') {
      return true;
    } else if (
      this.props.ifu_state &&
      (this.props.ifu_state.bpg_status === 'BPG Generation Success' ||
        this.props.ifu_state.bpg_status === '' ||
        this.props.ifu_state.bpg_status === 'BPG Manual Process') &&
      (this.props.ifu_state.txinstruction_status === 'Tx Instruction Generation Success' ||
        this.props.ifu_state.txinstruction_status === 'Tx Instruction Manual Process')
    ) {
      return true;
    } else if (
      this.props.ifu_state &&
      (this.props.ifu_state.bpg_status === 'BPG Manual Process' || this.props.ifu_state.bpg_status === '') &&
      this.props.ifu_state.txinstruction_status === 'Tx Instruction Manual Process'
    ) {
      return true;
    }
    return false;
  };

  /**
   * Determines if IFU generation has failed
   * @function
   * @returns {boolean} - True or false
   */
  isIFUGenerationFailed = () => {
    return (
      this.props.ifu_state &&
      (this.props.ifu_state.bpg_status === 'BPG Generation Failed' || this.props.ifu_state.txinstruction_status === 'Tx Instruction Generation Failed')
    );
  };

  /**
   * Determines if DDM generation is successful
   * @function
   * @returns {boolean} - True or false
   */
  isDDMGenerationSuccessful = () => {
    if (this.props.ddm_state.ddm_status === 'DDM Override Process') {
      return true;
    } else if (this.props.ddm_state.ddm_status === 'DDM Manual Process' && this.props.ddm_state.specs_manual_file && this.props.ddm_state.design_manual_file) {
      return true;
    } else if (this.props.ddm_state.generation_status === 'Generation Success') {
      return true;
    } else if (this.props.ddm_state.generation_status === 'Generation Failed') {
      return (
        (this.props.ddm_state.specs_file && this.props.ddm_state.design_file) ||
        (this.props.ddm_state.specs_manual_file && this.props.ddm_state.design_manual_file)
      );
    }
    return false;
  };

  /**
   * Determines if DDM generation has failed
   * @function
   * @returns {boolean} - True or false
   */
  isDDMGenerationFailed = () => {
    return (
      this.props.ddm_state.generation_status === 'Generation Failed' && (!this.props.ddm_state.specs_manual_file || !this.props.ddm_state.design_manual_file)
    );
  };

  /**
   * Show stage I Modal
   */
  showStageIModal = () => {
    if (!this.props.ddm_upload_pending && !this.props.ifu_upload_pending) {
      this.setState({
        showStageIModal: true,
      });
    }
  };

  /**
   * Hide Stage I Modal
   */
  hideStageIModal = () => {
    this.setState({
      showStageIModal: false,
    });
  };

  /**
   * Returns case back to Stage I
   * @function
   */
  returnToStageI = () => {
    const that = this;
    const data = { stage: 'Stage I' };
    that.setState({
      return_loading: true,
    });
    Axios.post(`/apiv3/cadstatus/${this.props.case_id}`, data)
      .then(function (res) {
        that.props.reload_information(false, () => {
          that.setState({
            showStageIModal: false,
            return_loading: false,
          });
        });
        that.props.fetchDdmState(that.props.case_id);
        that.props.fetchIfuState(that.props.case_id);
        that.props.fetchSingleToothIdb(that.props.case_id);
        that.props.setProductionTxGuideTabConfig('CAD Process', 'Stage I', that.props.cases);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  toggleRetryIfuModal = (open) => {
    this.setState({ retryIfuModal: open });
  };

  displayRetryIfuModal = () => {
    return (
      <Modal
        preset="decision"
        header_text="Retry IFU Generation"
        message_text="Are you sure you want to retry IFU generation?"
        confirm_btn_text="Retry"
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.toggleRetryIfuModal(false)}
        onConfirmButtonClick={() => {
          const that = this;
          Axios.post(`/apiv3/wireselection/${this.props.case_id}/retry_ifu`)
            .then(function (res) {
              if (res && res.data) {
                that.props.fetchIfuState(that.props.case_id);
              }
              that.toggleRetryIfuModal(false);
            })
            .catch(function (err) {
              handleHttpRequestError(err, that);
            });
        }}
        theme="bpp"
      />
    );
  };

  displayTxInstructionManualUploadModal = () => {
    const instructionsType = this.props.case_id.includes('-DE') ? 'Digital Enhancement' : 'Treatment';
    return (
      <Modal
        preset="upload-pdf"
        header_text={`${instructionsType} Instructions - ` + removeCaseIdInitialNumber(this.props.ifu_state.case_id)}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        case_id={this.props.case_id}
        folder="tx_regenerated_manual"
        type="additional"
        onUpload={(data) => {
          this.props.onManualUpload(data, this.setTxRegeneratedManualFile);
        }}
        upload_content={this.state.txRegeneratedManualFile ? [this.state.txRegeneratedManualFile] : []}
        onCloseButtonClick={() => {
          this.setState({ txRegeneratedManualFile: null });
          this.props.closeTxInstructionManualUploadModal();
        }}
        theme="bpp"
        original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, `${instructionsType.replace(' ', '_')}_Instructions_Manual.pdf`)}
      />
    );
  };

  setTxRegeneratedManualFile = () => {
    this.setState({ txRegeneratedManualFile: this.props.ifu_state.txinstruction_regenerated_manual_file });
  };

  isWsAllTemplate = () => {
    return this.props.ws.every(
      (wire) => ['none', 'template'].includes(wire.selection_override) || (!wire.selection_override && ['none', 'template'].includes(wire.selection))
    );
  };

  render() {
    const last_status = getCaseLastStatus(true, this.props.case_details, this.props.case_id);
    const instructions_type = this.props.case_id.includes('-DE') || this.props.case_id.includes('-R') ? 'Digital Enhancement' : 'Treatment';
    const cad_assigned_to_history = this.getCadAssignedToHistory();

    if (
      (isApplianceDesignStage(this.props.cases.status_code) &&
        this.props.cases.status_code !== 'STATUS_SHIP' &&
        this.props.cases.manufacturing_process &&
        this.props.cases.manufacturing_process.log.length > 0 &&
        userHasPermission('CASE_DETAILS_VIEW', this.props.user_roles_and_permissions.permissions)) ||
      (isCaseBlocked(this.props.cases.status_code) &&
        this.props.cases.manufacturing_process &&
        this.props.cases.manufacturing_process.log.length > 0 &&
        (this.props.cases.manufacturing_process.log[this.props.cases.manufacturing_process.log.length - 1].status_code.toUpperCase() === 'CAD PROCESS' ||
          this.props.cases.manufacturing_process.log[this.props.cases.manufacturing_process.log.length - 1].status_code.toUpperCase() ===
            'QUALITY CONTROL REVIEW'))
    ) {
      return (
        <div id="cad-area" className="cad-container">
          <div className="bold-text business-dark-heading">
            Appliance Design
            {isApplianceDesignStage(this.props.cases.status_code) &&
              this.props.cases.appliance_design_target_date &&
              isTodayPastDate(this.props.cases.appliance_design_target_date) && (
                <i
                  className="fa fa-flag target-date-flag"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={`Missed Appliance Design Date: ${convertDate(this.props.cases.appliance_design_target_date)}`}
                />
              )}
            {this.props.cases.cad_status_log.length > 0 && userHasPermission('CASE_DETAILS_VIEW', this.props.user_roles_and_permissions.permissions) && (
              <div
                id="setup_files"
                className="pull-right btn-light-link"
                data-case_id={this.props.cases.case_id}
                onClick={() => this.toggleApplianceDesignLogModal(true)}
                data-toggle="tooltip"
                data-placement="top"
                title="Appliance Design Log"
              >
                <i className="fa fa-file-text-o" aria-hidden="true" />
              </div>
            )}
            {isApplianceDesignStage(this.props.cases.status_code) && this.displayTargetCompletionDate()}
          </div>
          <div className="business-dark-theme">
            <div key={`cad_container`}>
              <ProcessTracker
                hideRuler={true}
                key={`cad_process_tracker`}
                process="cad"
                selected_cad_stage={this.state.selected_stage}
                stage={this.getProcessState(this.state.selected_stage)}
                last_stage={isCaseBlocked(this.props.cases.status_code) ? this.getProcessState(this.state.selected_stage) : ''}
                case_id={this.props.case_id}
                project={this}
                cad_assigned_to={this.state.cad_assigned_to}
                user_roles_and_permissions={this.props.user_roles_and_permissions}
                reload_information={this.props.reload_information}
                editable_status_comment={this.state.editable_status_comment}
                txplan_arch={this.props.txplan_arch}
                label_files={this.props.case_files?.cad_process ?? []}
                getLabelFilePath={this.getLabelFilePath}
                cad_process={this.props.cases.cad_process}
                log={this.props.cases.cad_process.log}
                adjusted_setup_status={this.props.cases.adjusted_setup_process.status}
                ref_no={this.props.ref_no}
                update_wo={this.props.update_wo}
                cases={this.props.cases}
                assign_to_options={this.state.assign_to_options}
                disabled={this.props.disabled}
                assignable={this.state.assignable}
                handleAssignTo={this.handleAssignTo}
                updateCaseAssignment={this.updateCaseAssignment}
                quality_control_stage_1_reason={this.state.quality_control_stage_1_reason}
                assigned_to_history={cad_assigned_to_history}
                bmf_rev={this.state.bmf_rev}
                bmf_rev_incomplete_error={this.state.bmf_rev_incomplete_error}
                get_case_status={this.props.get_case_status}
                getSelectedStage={this.getSelectedStage}
                setCadAssignedTo={this.setCadAssignedTo}
                wire_selection_status={this.props.wire_selection_status}
                onStageChange={this.onStageChange}
                ddm_successful={this.isDDMGenerationSuccessful()}
                ddm_failed={this.isDDMGenerationFailed()}
                ifu_successful={this.isIFUGenerationSuccessful()}
                ifu_failed={this.isIFUGenerationFailed()}
                upload_pending={this.props.ifu_upload_pending || this.props.ddm_upload_pending}
                returnToStageI={this.showStageIModal}
                current_cad_stage={this.state.current_stage}
                ddm_status={this.getProcessState('Stage II')}
                ifu_status={this.getProcessState('Stage III')}
                patient_info={this.props.patient_info}
                requested_treatment={this.props.requested_treatment}
              />
              {this.state.showStageIModal ? this.returnStageIModal() : null}
            </div>
          </div>
          {this.state.appliance_design_log_modal && this.applianceDesignLogModal()}
          {this.state.refresh && <SessionExpire />}
        </div>
      );
    } else if (
      this.props.get_case_status &&
      (this.props.get_case_status.toUpperCase() === 'STATUS_MANUFACTURE' ||
        this.props.get_case_status.toUpperCase() === 'STATUS_SHIP' ||
        last_status.toUpperCase() === 'INBRACE MANUFACTURING CASE') &&
      this.props.cases.manufacturing_process &&
      this.props.cases.manufacturing_process.log.length > 0 &&
      this.props.cases.manufacturing_process.log[this.props.cases.manufacturing_process.log.length - 1].status_code.toUpperCase() === 'STATUS_MANUFACTURE'
    ) {
      return (
        <div id="cad-area" className="cad-container">
          <div className="bold-text business-dark-heading">
            Appliance Design
            {this.props.cases.cad_status_log.length > 0 && userHasPermission('CASE_DETAILS_VIEW', this.props.user_roles_and_permissions.permissions) && (
              <div
                id="setup_files"
                className="pull-right btn-light-link"
                data-case_id={this.props.cases.case_id}
                onClick={() => this.toggleApplianceDesignLogModal(true)}
                data-toggle="tooltip"
                data-placement="top"
                title="Appliance Design Log"
              >
                <i className="fa fa-file-text-o" aria-hidden="true" />
              </div>
            )}
          </div>
          <div className="business-dark-theme">
            {!this.props.is_after_3420_launch && <div>Appliance Design WO Ref: {this.props.ref_no ? this.props.ref_no : 'N/A'}</div>}
            {this.props.cases.gen_2 &&
              !this.props.is_historical_single_tooth_idb &&
              this.props.displaySingleToothIdbOutput(this.props.single_tooth_idb_status, this.props.single_tooth_idb_final_file)}
            <div className="cad-title">DDM</div>
            <div>
              {this.props.ddm_state && this.props.ddm_state.ddm_status === 'DDM Override Process' ? (
                <div>DDM Design File generation has been skipped...</div>
              ) : this.props.ddm_state.design_file ||
                this.props.ddm_state.generation_status === 'Generation Success' ||
                this.props.ddm_state.design_manual_file ? (
                <>
                  <div className="file-display">
                    <a
                      href={
                        '/' +
                        (this.props.ddm_state.design_file
                          ? this.props.ddm_state.design_file.upload_data
                          : this.props.ddm_state.design_manual_file
                          ? this.props.ddm_state.design_manual_file.upload_data
                          : '')
                      }
                      onClick={onZipClick}
                      download
                    >
                      <div className="underline-text viewable-text center-text">
                        DDM Design File <i className="fa fa-file-zip-o" aria-hidden="true" />
                      </div>
                    </a>
                    {this.props.ddm_state.design_manual_file ? <span> (Manually Uploaded)</span> : null}
                  </div>
                </>
              ) : (
                <div>DDM Design File (Not Applicable)</div>
              )}
            </div>
            <div>
              {this.props.ddm_state && this.props.ddm_state.ddm_status === 'DDM Override Process' ? (
                <div>DDM Specification Form generation has been skipped...</div>
              ) : this.props.ddm_state.specs_file ||
                this.props.ddm_state.generation_status === 'Generation Success' ||
                this.props.ddm_state.specs_manual_file ? (
                <>
                  <div className="underline-text viewable-text" onClick={this.props.openSpecsFileModal}>
                    DDM Specification Form <i className="fa fa-file-pdf-o" aria-hidden="true" />
                  </div>
                  {this.props.ddm_state.specs_manual_file ? <span> (Manually Uploaded)</span> : null}
                </>
              ) : (
                <div>DDM Specification Form (Not Applicable)</div>
              )}
            </div>
            <div className="cad-title">IFU</div>
            <div>
              {this.props.ifu_state &&
              ((!this.props.ifu_state.txinstruction_status && this.props.get_case_status.toUpperCase() === 'STATUS_MANUFACTURE') ||
                (!this.props.ifu_state.txinstruction_status &&
                  this.props.get_case_status.toUpperCase() === 'STATUS_SHIP' &&
                  !this.props.ifu_state.txinstruction_file) ||
                this.props.ifu_state.txinstruction_status === 'Tx Instruction Regeneration Overridden' ||
                (this.props.ifu_state.ifu_status === 'IFU Override Process' && !this.props.ifu_state.txinstruction_manual_file)) ? (
                <div>Treatment Instructions generation has been overridden...</div>
              ) : (this.props.ifu_state.txinstruction_file ||
                  this.props.ifu_state.txinstruction_manual_file ||
                  this.props.ifu_state.txinstruction_regenerated_file) &&
                this.props.ifu_state.txinstruction_status !== 'Tx Instruction Gen2 Generation Process' ? (
                <>
                  <div className="underline-text viewable-text" onClick={this.props.openTxInstructionFileModal}>
                    {instructions_type} Instructions <i className="fa fa-file-pdf-o" aria-hidden="true" />
                  </div>
                  {this.props.ifu_state && this.props.ifu_state.txinstruction_manual_file ? (
                    <>
                      <span> (Manually Uploaded) </span>
                      {this.props.ifu_state.txinstruction_status === 'Tx Instruction Regeneration Manual Process' &&
                        !['WFR Process', 'WFR Rework'].includes(this.props.wire_fabrication_review_status) &&
                        (this.isWsAllTemplate()
                          ? this.props.cases.cad_process.status !== 'Production Process Complete'
                          : this.props.wire_selection_status !== 'WS Complete') && (
                          <span className="upload-ifu-icon btn-light-link" onClick={this.props.openTxInstructionManualUploadModal}>
                            <i className="fa fa-upload" />
                          </span>
                        )}
                    </>
                  ) : null}
                </>
              ) : this.props.ifu_state.txinstruction_status === 'Tx Instruction Generation Failed' ? (
                <div>
                  {instructions_type} Instructions Generation Failure{' '}
                  <button className="btn btn-light btn-retry-ifu" onClick={() => this.toggleRetryIfuModal(true)}>
                    Retry
                  </button>
                </div>
              ) : null}
            </div>
            <div>
              {this.props.ifu_state &&
              !this.props.ifu_state.historical_vbpc_file &&
              ((!this.props.ifu_state.bpg_status && this.props.get_case_status.toUpperCase() === 'STATUS_MANUFACTURE') ||
                (!this.props.ifu_state.bpg_status && this.props.get_case_status.toUpperCase() === 'STATUS_SHIP' && !this.props.ifu_state.bpg_file) ||
                (this.props.ifu_state.ifu_status === 'IFU Override Process' && !this.props.ifu_state.bpg_manual_file)) ? (
                <div>{this.props.cases.gen_2 ? <>Adhesive Fill Guide</> : <>Bracket Placement Guide</>} generation has been overridden...</div>
              ) : (
                <>
                  <div className="underline-text viewable-text" onClick={this.props.openBpgFileModal}>
                    {this.props.cases.gen_2 ? <>Adhesive Fill Guide </> : <>Bracket Placement Guide </>}
                    <i className="fa fa-file-pdf-o" aria-hidden="true" />
                  </div>
                  {this.props.ifu_state && this.props.ifu_state.bpg_manual_file ? <span> (Manually Uploaded)</span> : null}
                </>
              )}
            </div>
            {this.props.cases.checklist_file_path && (
              <>
                <div className="cad-title">Quality Control Review</div>
                <div className="underline-text viewable-text" onClick={this.openChecklistPdfModal}>
                  QC Virtual Inspection Checklist <i className="fa fa-file-pdf-o" aria-hidden="true" />
                </div>
              </>
            )}
          </div>
          {this.props.showTxInstructionFileModal ? this.txInstructionFileModal() : null}
          {this.props.showBpgFileModal ? this.bpgFileModal() : null}
          {this.state.checklist_pdf_modal && this.checklistModal()}
          {this.state.appliance_design_log_modal && this.applianceDesignLogModal()}
          {this.state.refresh && <SessionExpire />}
          {this.props.specs_file_modal ? this.specsModal() : null}
          {this.state.retryIfuModal && this.displayRetryIfuModal()}
          {this.props.txInstructionManualUploadModal && this.displayTxInstructionManualUploadModal()}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ifu_state: getIfuState(state),
    ddm_state: getDdmState(state),
    showTxInstructionFileModal: getTxInstructionFileModal(state),
    showBpgFileModal: getBpgFileModal(state),
    is_after_3420_launch: getIsAfter3420Launch(state),
    specs_file_modal: getSpecsFileModal(state),
    ifu_upload_pending: getUploadPending(state),
    ddm_upload_pending: getPending(state),
    single_tooth_idb_raw_file: getSingleToothIdbRawFile(state),
    single_tooth_idb_final_file: getSingleToothIdbFinalFile(state),
    single_tooth_idb_status: getSingleToothIdbStatus(state),
    is_historical_single_tooth_idb: getIsHistoricalSingleToothIdb(state),
    txInstructionManualUploadModal: getTxInstructionManualUploadModal(state),
    ws: getWs(state),
    wire_fabrication_review_status: getWireFabricationReviewStatus(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIfuState: fetchIfuState,
      fetchDdmState: fetchDdmState,
      openTxInstructionFileModal: openTxInstructionFileModal,
      closeTxInstructionFileModal: closeTxInstructionFileModal,
      openBpgFileModal: openBpgFileModal,
      closeBpgFileModal: closeBpgFileModal,
      setProductionTxGuideTabConfig: setProductionTxGuideTabConfig,
      openSpecsFileModal: openSpecsFileModal,
      closeSpecsFileModal: closeSpecsFileModal,
      fetchSingleToothIdb: fetchSingleToothIdb,
      displaySingleToothIdbOutput: displaySingleToothIdbOutput,
      openTxInstructionManualUploadModal: openTxInstructionManualUploadModal,
      closeTxInstructionManualUploadModal: closeTxInstructionManualUploadModal,
      onManualUpload: onManualUpload,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CADContainer);
