import { PROCESSOR, WASM_ACTION } from '../wasm_controller_const';
import { wasmControllerExecute } from '../wasm_controller';

export class ConfigurerProcessor {
  /**
   * Toggles the multi selection mode.
   * @param {Boolean} isEnabled
   */
  static toggleMultiSelection(isEnabled = false) {
    const req = {
      Processor: PROCESSOR.Configurer,
      Action: WASM_ACTION.Configurer.Selection,
      Allow: true,
      AllowMultiSelection: isEnabled,
    };
    wasmControllerExecute(req);
  }

  /**
   * Toggles the restriction notification.
   * @param {Boolean} isEnabled
   */
  static toggleRestrictionNotification(isEnabled = false) {
    const req = {
      Processor: PROCESSOR.Configurer,
      Action: WASM_ACTION.Configurer.ErrorHandler,
      EnableRestrictionNotification: isEnabled,
    };
    wasmControllerExecute(req);
  }

  /**
   * Toggles the hotkeys.
   * @param {Boolean} isEnabled
   */
  static toggleHotKeys(isEnabled = false) {
    const req = {
      Processor: PROCESSOR.Configurer,
      Action: WASM_ACTION.Configurer.HotKeys,
      EnableHotKeys: isEnabled,
    };
    wasmControllerExecute(req);
  }
}
