import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../modal/modal';
import { userHasPermission } from '../../common/permission';
import { isCaseBlocked } from '../../common/helpers';
import { removeCaseIdInitialNumber } from '../../common/functions';
import { isPostApprovalStage } from '../../common/case/case_status';
import { isProgramInFullRelease } from '../../common/utils/programs';
import SessionExpire from '../../common/session_expire';

// Redux
import {
  getSmileDesignQuestions,
  getIsEditModeSmileDesign,
  getChangeTreatmentModal,
  getRequestedTreatmentModal,
  getSmileDesignIncompleteError,
  getSessionExpire,
  getSmileDesignSelectionsSaveSuccess,
} from '../../redux/reducers/bpp/production_tx_guide/smile_design';
import { getStage1Questions } from '../../redux/reducers/bpp/production_tx_guide/stage_1';
import {
  setEditModeSmileDesign,
  onChangeInTreatment,
  onInterArchCollisionChange,
  openChangeTreatmentModal,
  closeChangeTreatmentModal,
  onConfirmButtonClickChangeTreatmentModal,
  onSmileDesignNoteChange,
  onInterArchCollisionRegionChange,
  openRequestedTreatmentModal,
  closeRequestedTreatmentModal,
  onConfirmButtonClickRequestedTreatmentModal,
  onEditSmileDesignTxGuideCancel,
  saveSmileDesignProductionTxGuideSelections,
  openSmileDesignTxGuidePdfModal,
  setRequestedTreatment,
  setTreatmentPlanRevision,
  onInBraceIfsChange,
  onInBraceIfsAppliedChange,
  resetSmileDesignSelectionsSuccess,
} from '../../redux/actions/bpp/production_tx_guide/smile_design';
import { getIfuState } from '../../redux/reducers/ifu/ifu_state';
import { getPrograms } from '../../redux/selectors/programs';
import { PROGRAMS_FRONTEND_CONSTANTS } from '../../common/constants/programs';

/**
 * Production tx guide form for Smile Design section
 * @component
 * @alias SmileDesignProductionTxGuide
 * @category BPP
 */
class SmileDesignProductionTxGuide extends Component {
  componentDidMount() {
    this.props.setRequestedTreatment(this.props.requested_treatment);
    if (this.props.submission_process && this.props.submission_process.treatment_plan_revision) {
      this.props.setTreatmentPlanRevision(this.props.submission_process.treatment_plan_revision);
    }
    this.props.setEditModeSmileDesign(!this.props.is_tab_view);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.is_tab_view) return;
    if (
      this.props.isSmileDesignSelectionsSaveSuccessful !== prevProps.isSmileDesignSelectionsSaveSuccessful &&
      this.props.isSmileDesignSelectionsSaveSuccessful
    ) {
      // Call the function to reload information here
      this.props.reloadInformation();
      this.props.resetSmileDesignSelectionSuccess();
    }
  }

  /**
   * Gets arch to treat for Requested Treatment section
   * @function
   * @return {String} Arch to Treat string
   */
  getArchToTreat() {
    const requested_arch_to_treat = this.props.requested_treatment.arch_to_treat;
    const changed_arch_to_treat = this.props.smile_design_questions.requested_treatment_selections.arch_to_treat;
    return changed_arch_to_treat ? changed_arch_to_treat : requested_arch_to_treat ? requested_arch_to_treat : 'N/A';
  }

  /**
   * Gets teeth with no brackets bonded for Requested Treatment section
   * @function
   * @return {String} Teeth with no brackets bonded
   */
  getNoBracketBonded() {
    const requested_no_bracket_bonded = this.props.requested_treatment.no_bracket_bonded;
    const changed_no_bracket_bonded = this.props.smile_design_questions.requested_treatment_selections.no_bracket_bonded;
    return changed_no_bracket_bonded && changed_no_bracket_bonded.length > 0
      ? changed_no_bracket_bonded.join(', ')
      : requested_no_bracket_bonded && requested_no_bracket_bonded.length > 0
      ? requested_no_bracket_bonded.join(', ')
      : 'N/A';
  }

  /**
   * Displays Requested Treatment section
   * @function
   * @return {JSX} JSX for Requested Treatment
   */
  displayRequestedTreatment() {
    return (
      <React.Fragment>
        <div className="guide-item-row">Arch to Treat: {this.getArchToTreat()}</div>
        <div className="guide-item-row">Teeth Excluded from Treatment: {this.getNoBracketBonded()}</div>
      </React.Fragment>
    );
  }

  /**
   * Displays selector link for Requested Treatment section
   * @function
   * @return {JSX} JSX for selector link
   */
  displayRequestedTreatmentSelection() {
    return (
      <div
        className={`bracket-link guide-item-row${this.props.incomplete_error.requested_treatment ? ' location__incomplete' : ''}${
          !this.props.is_edit_mode_smile_design || this.props.approval_date || this.props.smile_design_questions.change_treatment_smile_design
            ? ' location__disabled'
            : ''
        }`}
        onClick={this.props.openRequestedTreatmentModal}
      >
        Specify Requested Treatment{this.props.is_edit_mode_smile_design ? '*' : ''}:
      </div>
    );
  }

  /**
   * Displays Change in Treatment section
   * @function
   * @return {JSX} JSX for Change in Treatment
   */
  displayChangeInTreatment() {
    return (
      <React.Fragment>
        <div className="question-row bold-text">
          Change in Treatment:
          {this.props.stage_1_questions.change_treatment_stage_1_selections.selected_teeth && (
            <span className="treatment-override">Overridden by Appliance Design</span>
          )}
        </div>
        <div className="guide-item-row">
          <input
            type="checkbox"
            className="pointer"
            checked={this.props.smile_design_questions.change_treatment_smile_design}
            onChange={() => this.props.onChangeInTreatment(this.props.is_tab_view)}
            disabled={
              !this.props.is_edit_mode_smile_design ||
              this.props.approval_date ||
              (this.props.submission_process.treatment_plan_revision !== 'DE3.0' &&
                this.props.case_id.includes('-DE') &&
                !this.props.smile_design_questions.requested_treatment_selections.selected_teeth)
            }
          />
          Yes. Doctor consent required.
          {this.props.smile_design_questions.change_treatment_smile_design && this.displayChangeInTreatmentSelection()}
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays selection area for Change in Treatment section
   * @function
   * @return {JSX} JSX for selection
   */
  displayChangeInTreatmentSelection() {
    return (
      <div className="location">
        <span
          className={`bracket-link guide-item-row${this.props.incomplete_error.change_treatment ? ' location__incomplete' : ''}${
            !this.props.is_edit_mode_smile_design || this.props.case_status === 'CAD Process' || this.props.case_status === 'Quality Control Review'
              ? ' location__disabled'
              : ''
          }`}
          onClick={() => this.props.openChangeTreatmentModal('change_treatment_smile_design')}
        >
          Specify Change in Treatment{this.props.is_edit_mode_smile_design ? '*' : ''}
        </span>
        :
        <div className="guide-item-row">
          Arch to Treat:{' '}
          <span className={this.props.stage_1_questions.change_treatment_stage_1_selections.selected_teeth ? 'guide-item-row__override' : ''}>
            {this.props.smile_design_questions.change_treatment_smile_design_selections.arch_to_treat
              ? this.props.smile_design_questions.change_treatment_smile_design_selections.arch_to_treat
              : 'N/A'}
          </span>
        </div>
        <div className="guide-item-row">
          Teeth Excluded from Treatment:{' '}
          <span className={this.props.stage_1_questions.change_treatment_stage_1_selections.selected_teeth ? 'guide-item-row__override' : ''}>
            {this.props.smile_design_questions.change_treatment_smile_design_selections.no_bracket_bonded &&
            this.props.smile_design_questions.change_treatment_smile_design_selections.no_bracket_bonded.length > 0
              ? this.props.smile_design_questions.change_treatment_smile_design_selections.no_bracket_bonded.join(', ')
              : 'N/A'}
          </span>
        </div>
      </div>
    );
  }

  /**
   * Displays special requests section
   *
   * Works for DE2 and DE3 versions
   * Also works for non-DE or Tx Plan versions
   *
   * For DE loop through valid keys and return first valid key if it exists otherwise return N/A
   *
   * For Tx Plan, return notes if it exists, otherwise return N/A
   */
  getTxPlanSpecialInstructions() {
    if (this.props.case_id.includes('-DE')) {
      const validKeys = ['de2_special_instruction', 'de3_setup_instruction'];

      for (let i = 0; i < validKeys.length; i++) {
        if (this.props.tx_plan[validKeys[i]]) {
          return this.props.tx_plan[validKeys[i]];
        }
      }

      return 'N/A';
    }

    return this.props.tx_plan.notes ? this.props.tx_plan.notes : 'N/A';
  }

  /**
   * Displays special requests section
   * @function
   * @return {JSX} JSX for special requests
   */
  displaySpecialRequests() {
    return (
      <React.Fragment>
        <div className="question-row bold-text">Special Requests from Doctor:</div>
        <div className="guide-item-row">{this.getTxPlanSpecialInstructions()}</div>
        <div className="question-row bold-text">Special Instructions:</div>
        <div>
          <textarea
            className="form-control"
            placeholder="Enter additional special requests from the doctor"
            rows={3}
            maxLength={750}
            onChange={this.props.onSmileDesignNoteChange}
            value={this.props.smile_design_questions.smile_design_notes}
            disabled={!this.props.is_edit_mode_smile_design}
          />
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays inter-arch collision section
   * @function
   * @return {JSX} JSX for inter-arch collision
   */
  displayInterArchCollision() {
    return (
      <div className="row question-row">
        <div className="col-lg-4 bold-text">Ideal Inter-Arch {this.props.gen_2 ? 'Interference' : 'Collision'}*:</div>
        <div className="col-lg-8 ">
          <div>
            <input
              type="radio"
              checked={!this.props.smile_design_questions.inter_arch_collision}
              onChange={() => this.props.onInterArchCollisionChange(this.props.is_tab_view)}
              disabled={!this.props.is_edit_mode_smile_design}
            />
            Do not allow
          </div>
          <div>
            <input
              type="radio"
              checked={this.props.smile_design_questions.inter_arch_collision}
              onChange={() => this.props.onInterArchCollisionChange(this.props.is_tab_view)}
              disabled={!this.props.is_edit_mode_smile_design}
            />
            <span className={this.props.incomplete_error.inter_arch_collision_region ? 'region-error' : ''}>Allow at region:</span>
            <input
              className={`form-control${this.props.incomplete_error.inter_arch_collision_region ? ' region-error' : ''}`}
              type="text"
              placeholder="Specify region"
              onChange={this.props.onInterArchCollisionRegionChange}
              value={this.props.smile_design_questions.inter_arch_collision ? this.props.smile_design_questions.inter_arch_collision_region : ''}
              disabled={!this.props.is_edit_mode_smile_design || !this.props.smile_design_questions.inter_arch_collision}
              maxLength={50}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Displays inbrace_ifs section
   * @function
   * @return {JSX} JSX for inbrace_ifs
   */
  displayInBraceIfs() {
    const ifsProgram = this.props.programs.find((program) => program.program_id === PROGRAMS_FRONTEND_CONSTANTS.inbrace_ifs.program_id);
    const inbraceIfsProgramIsInFullRelease = isProgramInFullRelease(ifsProgram);
    const is_doctor_enrolled_in_ifs = this.props.production_tx_guide.case_program_enrollments.some(
      (item) => item.program_id === PROGRAMS_FRONTEND_CONSTANTS.inbrace_ifs.program_id
    );
    if ((is_doctor_enrolled_in_ifs || inbraceIfsProgramIsInFullRelease) && this.props.gen_2) {
      const disabled = (isPostApprovalStage(this.props.case_status) && this.props.case_status !== 'Provider Edit Review') || this.props.cad_status;
      return (
        <div className="row question-row">
          <div className="col-lg-4 bold-text">InBrace IFS Required:</div>
          <div className="col-lg-8">
            <div>
              <input
                type="radio"
                checked={!this.props.smile_design_questions.inbrace_ifs_required}
                onChange={() => this.props.onInBraceIfsChange(this.props.is_tab_view, false)}
                disabled={!this.props.is_edit_mode_smile_design || disabled}
              />
              No
            </div>
            <div>
              <input
                type="radio"
                checked={this.props.smile_design_questions.inbrace_ifs_required}
                onChange={() => this.props.onInBraceIfsChange(this.props.is_tab_view, true)}
                disabled={!this.props.is_edit_mode_smile_design || disabled}
              />
              <span className={this.props.incomplete_error.inbrace_ifs_applied ? 'region-error' : ''}>Yes</span>
            </div>
            <textarea
              className={`inbrace_ifs-input form-control${this.props.incomplete_error.inbrace_ifs_applied ? ' region-error' : ''}`}
              placeholder="Specify InBrace IFS applied"
              onChange={this.props.onInBraceIfsAppliedChange}
              value={this.props.smile_design_questions.inbrace_ifs_required ? this.props.smile_design_questions.inbrace_ifs_applied : ''}
              disabled={!this.props.is_edit_mode_smile_design || !this.props.smile_design_questions.inbrace_ifs_required || this.props.cad_status}
              maxLength={500}
            />
          </div>
        </div>
      );
    }
  }

  /**
   * Displays requested treatment teeth selector modal
   * @function
   * @return {JSX} JSX for modal
   */
  displayRequestedTreatmentModal() {
    return (
      <Modal
        theme="bpp"
        preset="teeth-chart-8"
        modal_size_class="modal-lg"
        header_text={`Requested Treatment - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        confirm_btn_text="Apply"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickRequestedTreatmentModal(this.props.is_tab_view)}
        close_btn_text="Cancel"
        onCloseButtonClick={this.props.closeRequestedTreatmentModal}
      />
    );
  }

  /**
   * Displays change in treatment teeth selector modal
   * @function
   * @return {JSX} JSX for modal
   */
  displayChangeTreatmentModal() {
    return (
      <Modal
        theme="bpp"
        preset="teeth-chart-8"
        modal_size_class="modal-lg"
        header_text={`Change in Treatment - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        confirm_btn_text="Apply"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickChangeTreatmentModal(this.props.is_tab_view, this.props.gen_2)}
        close_btn_text="Cancel"
        onCloseButtonClick={this.props.closeChangeTreatmentModal}
      />
    );
  }

  /**
   * Displays buttons under questions in tab view
   * @function
   * @return {JSX} JSX for tab buttons
   */
  displayTabButtons() {
    return this.props.is_edit_mode_smile_design && this.props.case_status !== 'Quality Control Review' && !isCaseBlocked(this.props.case_status) ? (
      <React.Fragment>
        {this.hasIncompleteError() && <div className="incomplete-error">Please complete the required field(s) marked in red.</div>}
        <div className="button-panel">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              this.props.saveSmileDesignProductionTxGuideSelections();
            }}
          >
            Save
          </button>
          <button type="button" className="btn btn-light btn-cancel" onClick={this.props.onEditSmileDesignTxGuideCancel}>
            Cancel
          </button>
        </div>{' '}
      </React.Fragment>
    ) : (
      <div className="button-panel">
        <button type="button" className="btn btn-light" onClick={() => this.props.openSmileDesignTxGuidePdfModal(this.props.case_id)}>
          Preview Form
        </button>

        {this.canEditQuestions() && (
          <button type="button" className="btn btn-light" onClick={() => this.props.setEditModeSmileDesign(true)}>
            Edit
          </button>
        )}
      </div>
    );
  }

  canEditQuestions() {
    return (
      this.props.case_status !== 'Quality Control Review' &&
      this.props.case_status !== 'STATUS_DOCTOR_APPROVAL' &&
      !isCaseBlocked(this.props.case_status) &&
      !(this.props.ifu_state && (this.props.ifu_state.ifu_screen === 'in-progress' || this.props.ifu_state.ifu_screen === 'result')) &&
      userHasPermission('SETUP_PREP_COMPLETE', this.props.user_roles_and_permissions.permissions) &&
      !(this.props.gen_2 && this.props.case_status === 'CAD Process' && this.props.cad_status !== 'Stage I')
    );
  }

  hasIncompleteError() {
    return (
      this.props.incomplete_error.change_treatment ||
      this.props.incomplete_error.requested_treatment ||
      this.props.incomplete_error.inter_arch_collision_region ||
      this.props.incomplete_error.inbrace_ifs_applied
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="question-container">
          <div className={`${this.props.className ? this.props.className : ''} process-box design`}>
            <div className="question-row bold-text">Requested Treatment:</div>
            {this.props.case_id.includes('-DE') &&
              this.props.submission_process.treatment_plan_revision !== 'DE3.0' &&
              this.displayRequestedTreatmentSelection()}
            {this.displayRequestedTreatment()}
            {this.displayChangeInTreatment()}
            {this.displaySpecialRequests()}
            {this.displayInterArchCollision()}
            {this.displayInBraceIfs()}
            {this.props.change_treatment_modal && this.displayChangeTreatmentModal()}
            {this.props.requested_treatment_modal && this.displayRequestedTreatmentModal()}
            {this.props.is_tab_view && this.displayTabButtons()}
            {this.props.session_expire && <SessionExpire />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    smile_design_questions: getSmileDesignQuestions(state),
    is_edit_mode_smile_design: getIsEditModeSmileDesign(state),
    change_treatment_modal: getChangeTreatmentModal(state),
    requested_treatment_modal: getRequestedTreatmentModal(state),
    incomplete_error: getSmileDesignIncompleteError(state),
    stage_1_questions: getStage1Questions(state),
    session_expire: getSessionExpire(state),
    ifu_state: getIfuState(state),
    programs: getPrograms(state),
    isSmileDesignSelectionsSaveSuccessful: getSmileDesignSelectionsSaveSuccess(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setEditModeSmileDesign: setEditModeSmileDesign,
      onChangeInTreatment: onChangeInTreatment,
      onInterArchCollisionChange: onInterArchCollisionChange,
      onInterArchCollisionRegionChange: onInterArchCollisionRegionChange,
      openChangeTreatmentModal: openChangeTreatmentModal,
      closeChangeTreatmentModal: closeChangeTreatmentModal,
      onConfirmButtonClickChangeTreatmentModal: onConfirmButtonClickChangeTreatmentModal,
      onSmileDesignNoteChange: onSmileDesignNoteChange,
      openRequestedTreatmentModal: openRequestedTreatmentModal,
      closeRequestedTreatmentModal: closeRequestedTreatmentModal,
      onConfirmButtonClickRequestedTreatmentModal: onConfirmButtonClickRequestedTreatmentModal,
      onEditSmileDesignTxGuideCancel: onEditSmileDesignTxGuideCancel,
      saveSmileDesignProductionTxGuideSelections: saveSmileDesignProductionTxGuideSelections,
      openSmileDesignTxGuidePdfModal: openSmileDesignTxGuidePdfModal,
      setRequestedTreatment: setRequestedTreatment,
      setTreatmentPlanRevision: setTreatmentPlanRevision,
      onInBraceIfsChange: onInBraceIfsChange,
      onInBraceIfsAppliedChange: onInBraceIfsAppliedChange,
      resetSmileDesignSelectionSuccess: resetSmileDesignSelectionsSuccess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SmileDesignProductionTxGuide);
