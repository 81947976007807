import './patient_info.scss';

import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isRoleAllowedToAssign, convertDate } from '../../common/functions';
import { getDoctorsIdFromRoute } from '../../common/route';
import { userHasPermissions } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import AlertMessage from '../components/alert_message';
import Axios from 'axios';
import Button from '../components/buttons/button';
import Dropdown from '../components/inputs/dropdown';
import React, { Component } from 'react';
import TextBox from '../components/inputs/text_box';
import PatientInfoFields from './patient_info_fields';

// Redux
import { fetchDsoDoctorData } from '../../redux/actions/ipp/case_details';
import { getDsoDoctors } from '../../redux/reducers/ipp/case_details';

//internal components
import AttributionQuestions from '../../components/attribution_questions/attribution_questions';

class PatientInformation extends Component {
  state = {
    permission_roles: ['Doctor'],
  };

  componentDidMount() {
    let that = this;
    if (isRoleAllowedToAssign(this.props.doctorRole)) {
      this.props.fetchDsoDoctorData(getDoctorsIdFromRoute());
    }
    Axios.get(`/apiV2/account_light/${getDoctorsIdFromRoute()}`)
      .then(function (doctor_res) {
        that.setState({
          permission_roles: doctor_res.data.permission_roles,
          referral: doctor_res.data.referral,
        });
      })
      .catch(function (err) {});

    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  userHasProspectView = () => {
    return _.intersection(['Doctor-Prospect'], this.state.permission_roles).length > 0;
  };
  /**
   * Compares to see if the doctor role is allowed in the assign list when populating
   * @param {String} doctor_id - The dso doctor id coming in
   * @param {String} doctor_role - The role of the dso ipp account
   */
  isAllowedAssignedDoctorName = (doctor_id, doctor_role) => {
    return (
      this.props.doctorRole === 'DSO' ||
      this.props.doctorRole === 'DSO_Doctor_Override' ||
      (this.props.doctorRole === 'DSO_Doctor' && (doctor_role === 'DSO_Doctor_Override' || doctor_id.toString() === this.props.doctorRoleId))
    );
  };
  /**
   * Determines if assign dso doctor dropdown is avialable
   */
  isAssignedAvailable = () => {
    const assigned_doctor = this.props.dso_doctors.filter((doctor) => {
      return doctor.id.toString() === this.props.assignedDoctor.toString();
    });

    return (
      this.props.doctorRole === 'DSO' ||
      this.props.doctorRole === 'DSO_Doctor_Override' ||
      (this.props.doctorRole === 'DSO_Doctor' &&
        ((this.props.assignedDoctor && this.props.assignedDoctor.toString() === this.props.doctorRoleId.toString()) ||
          (assigned_doctor && assigned_doctor.length === 0) ||
          (assigned_doctor && assigned_doctor[0] && (assigned_doctor[0].role === 'DSO_Doctor_Override' || assigned_doctor[0].role === 'DSO'))))
    );
  };

  getAssignedDoctorOptionValue() {
    const selectable_doctors = this.props.dso_doctors.filter(
      (doctor) =>
        doctor.user__is_active && doctor.id.toString() !== doctor.account_link_id.toString() && this.isAllowedAssignedDoctorName(doctor.id, doctor.role)
    );
    const options = selectable_doctors.map((doctor) => ({
      label: `${doctor.user__first_name} ${doctor.user__last_name}`,
      value: doctor.id,
    }));

    const value = options.find((option) => parseInt(option.value) === parseInt(this.props.assignedDoctor));
    return { options, value };
  }

  getProspectLabel(prospect) {
    const fname = prospect.first_name ? prospect.first_name : '';
    const lname = prospect.last_name ? prospect.last_name : '';
    const dob = prospect.dob ? `(DOB: ${convertDate(prospect.dob)})` : '';
    const label = fname || lname ? `${fname} ${lname} ${dob}` : prospect.email;

    return label;
  }

  getProspectOptionsValues() {
    const options = this.props.prospect_data.map((prospect) => ({
      label: this.getProspectLabel(prospect),
      value: prospect.id,
    }));
    const value = options.find((option) => parseInt(option.value) === parseInt(this.props.selected_prospect_id));
    return { options, value };
  }

  getEmptyProspectOptionsValues() {
    const options = [
      {
        label: 'Select Prospect...',
        value: '',
      },
    ];
    const value = options[0];
    return { options, value };
  }

  render() {
    const message = (
      <>
        Before you prescribe treatment, remember to review the{' '}
        <a className="midnight" href="/static/files/SM-004-Rev-B-InBrace-Informed-Consent.pdf" target="_blank" rel="noopener noreferrer">
          Patient Informed Consent Form
        </a>
      </>
    );
    return (
      <form className="case-form-container">
        <AlertMessage message={message} theme="info" />
        <h3>Patient Info</h3>
        <div className="col-lg-6">
          {/* {this.props.doctorRole === 'Doctor' && (this.props.referral || this.state.referral) ? (
            <div>
              <label htmlFor="referralCode" className="emphasis" id="referralCodeLabel">
                Patient Referral Code
              </label>
              {this.props.patientReferralCode ? (
                <>
                  <div className="inline-input display-inline">
                    <TextBox
                      id="referralCode"
                      type="text"
                      className="form-control patient-field"
                      value={this.props.patientReferralCode}
                      maxLength="30"
                      disabled={true}
                    />
                  </div>
                  <label htmlFor="deleteCode" id="deleteCode" className="label-delete-referral-code" onClick={this.props.onDeleteLabelClick}>
                    X
                  </label>
                </>
              ) : this.props.editPatientReferralCode || !this.props.patientReferralCode ? (
                <>
                  <div className="inline-input display-inline">
                    <TextBox
                      id="referralCode"
                      type="text"
                      className="form-control patient-field "
                      onChange={this.props.onInputChange_ReferralCode}
                      value={this.props.editPatientReferralCode}
                      onFocus={this.props.onReferralCodeFocus}
                      maxLength="30"
                      disabled={this.props.disabledEdit}
                      placeholder="Optional"
                    />
                  </div>
                  <Button
                    id="mrReferralCodeButton"
                    onClick={this.props.onValidateButtonClick}
                    disabled={!this.props.editPatientReferralCode}
                    className="display-inline"
                  >
                    Validate
                  </Button>
                </>
              ) : null}
            </div>
          ) : null} */}
          {isRoleAllowedToAssign(this.props.doctorRole) && this.isAssignedAvailable() && this.props.load_dso_assign ? (
            <>
              <label htmlFor="assignedDoctor" className="emphasis" id="assignedDoctorLabel">
                Assigned Doctor{' '}
                <span data-toggle="tooltip" title="If no doctor is found, create a doctor through the accounts page.">
                  <i className="fa fa-info-circle" />
                </span>
              </label>
              <Dropdown
                id="assignedDoctor"
                className="patient-field form-group"
                placeholder="Assign Doctor..."
                onChange={this.props.onInputChange_assignedDoctor}
                {...this.getAssignedDoctorOptionValue()}
              />
            </>
          ) : null}
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermissions(['IPP_PROSPECT'], user_roles_and_permissions.permissions) && this.userHasProspectView() && !this.props.prospect_id ? (
                <>
                  <label className="emphasis" htmlFor="prospect" id="prospectLabel">
                    Prospect{' '}
                    <span data-toggle="tooltip" title="This field links the selected prospect to this case and will automatically fill out the fields below.">
                      <i className="fa fa-info-circle" />
                    </span>
                  </label>
                  {this.props.prospect_data && this.props.prospect_data.length > 0 ? (
                    <Dropdown
                      id="prospect"
                      className="patient-field"
                      onChange={this.props.onProspectChange}
                      isClearable
                      placeholder="Select Prospect..."
                      {...this.getProspectOptionsValues()}
                    />
                  ) : (
                    <div data-toggle="tooltip" className="patient-field" title="No prospects ready for submission">
                      <Dropdown
                        id="prospect"
                        className="patient-field"
                        disabled={true}
                        isClearable
                        placeholder="Select Prospect..."
                        {...this.getEmptyProspectOptionsValues()}
                      />
                    </div>
                  )}
                </>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
          <PatientInfoFields {...this.props} patientChartNoRequired={false} />
        </div>
        <div className="col-lg-6 padding-top info-border-left">
          <AttributionQuestions
            questions={this.props.attribution_questions}
            attributions={this.props.attributions}
            onAttributionsChange={this.props.onAttributionsChange}
            onAttributionOtherChange={this.props.onAttributionOtherChange}
            is_attribution_allowed={this.props.is_attribution_allowed}
            disabledEdit={this.props.disabledEdit}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dso_doctors: getDsoDoctors(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDsoDoctorData: fetchDsoDoctorData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PatientInformation);
