import {
  CLOSE_CASE_FILTER_PREFERENCES_MODAL,
  OPEN_CASE_FILTER_PREFERENCES_MODAL,
  EDIT_CASE_FILTER_PREFERENCES_MODAL,
} from '../actions/case_filter_preferences_modal';

export const case_filter_preferences_options = {
  'Cases Assigned to Me': {},
  'Unassigned Cases': {},
  'INBRACE Action Required': {
    'Folder Generation': [],
    'Folder Success/Failed': [],
    'Ready for Segmentation': [],
    'Verify Records': [],
    'Create Initial Setup': [],
    'Doctor Revision': [],
    'Internal Setup Review': [],
    'Internal Setup Revision': [],
    'Upload Setup': [],
    'Setup in Conversion': [],
    'Conversion Failed': [],
    'Release Setup': [],
    'Post Approval': [
      'Provider Edit Review',
      'Final Design Upload',
      'Final Design in Conversion',
      'Final Design Conversion Failed',
      'Final Design Ready for Release',
    ],
    CAD: ['Stage I', 'Quality Control Stage I', 'Stage II', 'Stage III'],
    'CAD Revision': ['Stage I', 'Quality Control Stage I', 'Stage II', 'Stage III'],
    'QC Approval': [],
    'Manufacture Case': [
      'Wire Generation',
      'Wire Generation Failed',
      'Wire Selection',
      'Wire Selection Guide',
      'Custom Wire WO Creation',
      'Wire Cutting',
      'Wire Awaiting Pickup',
      'Labels',
      'Wire Fabrication Review',
    ],
  },
  'Doctor Action Required': { 'Awaiting New Records': [], 'Awaiting Clarification': [], 'Awaiting Approval': [] },
  'Active Cases': {},
  'Shipped Cases': {},
  'Held Cases': {},
  'Cancelled Cases': {},
  'Case Precheck': {},
  'Clinical Review Cases': {},
  'Active Expedited Cases': {},
  'Pending Expedited Request': {},
  'Late Cases': {},
  'False DE': {},
  'Early DE': {},
};

export const case_filter_preferences_disabled = ['Cases Assigned to Me'];

const initialState = {
  modal: false,
  case_filter_preferences_editable: {},
  case_filter_preferences_options: case_filter_preferences_options,
  case_filter_preferences_disabled: case_filter_preferences_disabled,
};

export function caseFilterPreferencesModalReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_CASE_FILTER_PREFERENCES_MODAL:
      return {
        ...state,
        modal: false,
      };

    case OPEN_CASE_FILTER_PREFERENCES_MODAL:
      return {
        ...state,
        modal: true,
        case_filter_preferences_editable: action.case_filter_preferences_editable,
      };

    case EDIT_CASE_FILTER_PREFERENCES_MODAL:
      return {
        ...state,
        case_filter_preferences_editable: action.case_filter_preferences_editable,
      };

    default:
      return state;
  }
}

export const getCaseFilterPreferencesEditable = (state) => state.caseFilterPreferencesModalReducer.case_filter_preferences_editable;
export const getCaseFilterPreferencesDisabled = (state) => state.caseFilterPreferencesModalReducer.case_filter_preferences_disabled;
export const getCaseFilterPreferencesModal = (state) => state.caseFilterPreferencesModalReducer.modal;
export const getCaseFilterPreferencesOptions = (state) => state.caseFilterPreferencesModalReducer.case_filter_preferences_options;
