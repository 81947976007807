import React from 'react';
import _ from 'lodash';
import Axios from 'axios';
import {
  setSelectedTeeth,
  setMessage,
  setTextBody,
  setShowArchSelection,
  setArchDisabled,
  setTeethSelectorLabel,
  setTreatmentArch,
  setOpposingTreatmentArch,
  setShowTextBox,
  setTextBoxLabel,
  setTextBoxPlaceholder,
  setError,
  clearTeethChartInformation,
  setDisabledTeeth,
  setShowPresets,
  setTreatmentPlanRevision as setTeethChartTreatmentPlanRevisionAction,
} from '../../common/teeth_chart';
import { orderTeeth, getUnselectedTeeth, removeOpposingTeeth, textFieldCheck, removeEmoji, orderGen2Wires } from '../../../../common/functions';
import { isStage1TxGuideFormCompleted } from '../../../../common/helpers';
import { setIFUIncorrectFileNameError } from '../../ifu/ifu_upload';
import TeethUtils from '../../../../common/teeth_utils';

// Action Types
export const OPEN_CHANGE_TREATMENT_STAGE_1_MODAL = 'OPEN_CHANGE_TREATMENT_STAGE_1_MODAL';
export const CLOSE_CHANGE_TREATMENT_STAGE_1_MODAL = 'CLOSE_CHANGE_TREATMENT_STAGE_1_MODAL';
export const SET_EDIT_MODE_STAGE_1 = 'SET_EDIT_MODE_STAGE_1';
export const SET_STAGE_1_QUESTIONS = 'SET_STAGE_1_QUESTIONS';
export const FETCH_STAGE_1_QUESTIONS_SUCCESS = 'FETCH_STAGE_1_QUESTIONS_SUCCESS';
export const UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_ERROR = 'UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_ERROR';
export const UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_PENDING = 'UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_PENDING';
export const UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_SUCCESS = 'UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_SUCCESS';
export const SET_REQUESTED_TREATMENT_STAGE_1 = 'SET_REQUESTED_TREATMENT_STAGE_1';
export const SET_TREATMENT_PLAN_REVISION = 'SET_TREATMENT_PLAN_REVISION';
export const OPEN_STAGE_1_TEETH_SELECTOR_MODAL = 'OPEN_STAGE_1_TEETH_SELECTOR_MODAL';
export const CLOSE_STAGE_1_TEETH_SELECTOR_MODAL = 'CLOSE_STAGE_1_TEETH_SELECTOR_MODAL';
export const SET_STAGE_1_INCOMPLETE_ERROR = 'SET_STAGE_1_INCOMPLETE_ERROR';
export const OPEN_STAGE_1_TX_GUIDE_PDF_MODAL = 'OPEN_STAGE_1_TX_GUIDE_PDF_MODAL';
export const CLOSE_STAGE_1_TX_GUIDE_PDF_MODAL = 'CLOSE_STAGE_1_TX_GUIDE_PDF_MODAL';
export const SET_STAGE_1_TX_GUIDE_FILE_PATH = 'SET_STAGE_1_TX_GUIDE_FILE_PATH';
export const SET_STAGE_1_QUESTIONS_OLD = 'SET_STAGE_1_QUESTIONS_OLD';
export const SET_STAGE_1_SESSION_EXPIRED = 'SET_STAGE_1_SESSION_EXPIRED';
export const SET_EDIT_NOTES_MODE = 'SET_EDIT_NOTES_MODE';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Sets selections for the case's Stage 1 production tx guide
 * @function
 * @param {String} case_id - Case id
 * @param {Object} production_tx_guide - Selections to update the treatment guide with
 * @return {Object} Action type
 */
export function setStage1ProductionTxGuide(case_id, production_tx_guide) {
  return (dispatch, getState) => {
    const { stage_1_questions } = getState().stage1ProductionTxGuideReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);

    dispatch(clearStage1IncompleteErrors());

    for (let question of Object.keys(stage_1_questions_updated)) {
      if (!(question === 'wire_selections' && !production_tx_guide.wire_selections)) {
        stage_1_questions_updated[question] = production_tx_guide[question];
      }
    }
    let stage_1_questions_old = _.cloneDeep(stage_1_questions_updated);

    dispatch({
      type: FETCH_STAGE_1_QUESTIONS_SUCCESS,
      stage_1_questions: stage_1_questions_updated,
      case_id: case_id,
    });

    dispatch({
      type: SET_STAGE_1_QUESTIONS_OLD,
      stage_1_questions_old: stage_1_questions_old,
    });
  };
}

/**
 * Handles event when user requests/unrequests change in treatment
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onChangeInTreatmentStage1(is_tab_view) {
  return (dispatch, getState) => {
    const { stage_1_questions, requested_treatment } = getState().stage1ProductionTxGuideReducer;
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    const { gen_2 } = getState().productionTxGuideReducer;

    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);
    stage_1_questions_updated.change_treatment_stage_1 = !stage_1_questions.change_treatment_stage_1;
    dispatch(setStage1IncompleteError('change_treatment', false));
    dispatch(setStage1IncompleteError('no_treatment', false));

    const current_treatment_arch = getChangeTreatmentStage1Information(
      stage_1_questions_updated,
      smile_design_questions,
      'treatment_arch',
      requested_treatment,
      'both'
    );
    const current_no_bracket_bonded = getChangeTreatmentStage1Information(
      stage_1_questions_updated,
      smile_design_questions,
      'no_bracket_bonded',
      requested_treatment,
      []
    );

    stage_1_questions_updated.wire_selections = setWires(stage_1_questions_updated, smile_design_questions, requested_treatment, current_treatment_arch, gen_2);

    for (const bracket_options_type of Object.keys(stage_1_questions_updated.stage_1_selected_teeth)) {
      stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type] = stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type].filter(
        (b) => {
          if (current_treatment_arch === 'upper' || current_treatment_arch === 'lower') {
            return b.charAt(0) === `${current_treatment_arch.charAt(0).toUpperCase()}` && current_no_bracket_bonded.indexOf(b) === -1;
          }
          return current_no_bracket_bonded.indexOf(b) === -1;
        }
      );

      if (stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type].length === 0) {
        delete stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type];
      }
    }

    if (current_treatment_arch === 'upper' || current_treatment_arch === 'lower') {
      stage_1_questions_updated[`${current_treatment_arch === 'upper' ? 'lower' : 'upper'}_idb_tray`] = false;
    }

    stage_1_questions_updated.treatment_arch = current_treatment_arch;

    if (is_tab_view) {
      dispatch(updateStage1ProductionTxGuideSuccess(stage_1_questions_updated));
    } else {
      dispatch(updateStage1ProductionTxGuide(stage_1_questions_updated));
    }
  };
}

/**
 * Handles event when user enters note
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type
 */
export function onStage1NoteChange(event) {
  return (dispatch, getState) => {
    const { stage_1_questions } = getState().stage1ProductionTxGuideReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);

    stage_1_questions_updated.stage_1_notes = removeEmoji(textFieldCheck(event.target.value));

    dispatch({
      type: SET_STAGE_1_QUESTIONS,
      stage_1_questions: stage_1_questions_updated,
    });
  };
}

/**
 * Sets error when treatment guide form is incomplete
 * @function
 * @param {String} error_type - Incomplete error type
 * @param {Boolean} is_error = True or False
 * @return {Object} Action type
 */
export function setStage1IncompleteError(error_type, is_error) {
  return {
    type: SET_STAGE_1_INCOMPLETE_ERROR,
    error_type: error_type,
    is_error: is_error,
  };
}

/**
 * Toggles edit mode for treatment guide
 * @function
 * @param {Boolean} is_edit_mode - True or False
 * @return {Object} Action type
 */
export function setEditModeStage1(is_edit_mode) {
  return {
    type: SET_EDIT_MODE_STAGE_1,
    is_edit_mode_stage_1: is_edit_mode,
  };
}

export function setEditNotesMode(is_edit_notes_mode) {
  return {
    type: SET_EDIT_NOTES_MODE,
    is_edit_notes_mode: is_edit_notes_mode,
  };
}

/**
 * Handles event when user cancels editing production tx guide
 * @function
 * @return {Object} Action type
 */
export function onEditStage1TxGuideCancel() {
  return (dispatch) => {
    dispatch(setStage1IncompleteError('change_treatment', false));
    dispatch(setStage1IncompleteError('wire_selections', false));
    dispatch(setStage1IncompleteError('no_treatment', false));
    dispatch(fetchStage1ProductionTxGuide());
  };
}

export function setRequestedTreatmentStage1(requested_treatment) {
  return {
    type: SET_REQUESTED_TREATMENT_STAGE_1,
    requested_treatment: requested_treatment,
  };
}

export function setTreatmentPlanRevision(treatment_plan_revision) {
  return {
    type: SET_TREATMENT_PLAN_REVISION,
    treatment_plan_revision: treatment_plan_revision,
  };
}

/**
 * Handles event when user selects IDB tray
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @param {String} idb_tray - Selected idb tray
 * @return {Object} Action type
 */
export function onChangeIdbTray(is_tab_view, tray) {
  return (dispatch, getState) => {
    const { stage_1_questions } = getState().stage1ProductionTxGuideReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);
    stage_1_questions_updated[tray] = !stage_1_questions[tray];

    if (is_tab_view) {
      dispatch(updateStage1ProductionTxGuideSuccess(stage_1_questions_updated));
    } else {
      dispatch(updateStage1ProductionTxGuide(stage_1_questions_updated));
    }
  };
}

/**
 * Handles event when user selects a wire
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @param {String} selection - Wire selection
 * @param {String} wire_type - Upper or Lower
 * @return {Object} Action type
 */
export function onWireSelect(is_tab_view, selection, wire_type) {
  return (dispatch, getState) => {
    dispatch(setStage1IncompleteError('wire_selections', false));
    const { stage_1_questions, wire_config } = getState().stage1ProductionTxGuideReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);
    let wires = stage_1_questions_updated.wire_selections;

    const wire = wire_type === 'Upper' ? wire_config.upper[selection] : wire_config.lower[selection];

    wires = wires.filter((w) => {
      return w.indexOf(wire_type) === -1;
    });

    wires.push.apply(wires, wire);
    stage_1_questions_updated.wire_selections = wires;

    if (is_tab_view) {
      dispatch(updateStage1ProductionTxGuideSuccess(stage_1_questions_updated));
    } else {
      dispatch(updateStage1ProductionTxGuide(stage_1_questions_updated));
    }
  };
}

/**
 * Handles event when user selects a wire for a Gen 2.0 case
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @param {String} selection - Wire selection
 * @return {Object} Action type
 */
export function onWireSelectGen2(selections, type, is_tab_view) {
  return (dispatch, getState) => {
    dispatch(setStage1IncompleteError('wire_selections', false));
    const { stage_1_questions } = getState().stage1ProductionTxGuideReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);
    let wires = stage_1_questions_updated.wire_selections;

    for (const selection of selections) {
      if (wires.indexOf(selection) === -1) {
        wires.push(selection);
      }
    }
    const filtered_wires = _.cloneDeep(stage_1_questions_updated.wire_selections).filter((w) => {
      return w.indexOf(type) !== -1;
    });
    for (const wire of filtered_wires) {
      if (selections.indexOf(wire) === -1) {
        wires = wires.filter((w) => {
          return w.indexOf(wire) === -1;
        });
      }
    }
    stage_1_questions_updated.wire_selections = orderGen2Wires(wires);
    if (is_tab_view) {
      dispatch(updateStage1ProductionTxGuideSuccess(stage_1_questions_updated));
    } else {
      dispatch(updateStage1ProductionTxGuide(stage_1_questions_updated));
    }
  };
}

/**
 * Retrieves production tx guide for a case
 * @function
 * @return {Object} Action type
 */
export function fetchStage1ProductionTxGuide(edit_mode = false) {
  return (dispatch, getState) => {
    const { case_id } = getState().stage1ProductionTxGuideReducer;
    Axios.get(`/apiv3/productiontxguide/${case_id}`)
      .then((res) => {
        dispatch(setStage1ProductionTxGuide(case_id, res.data));
        dispatch(setEditModeStage1(edit_mode));
        dispatch(setEditNotesMode(edit_mode));
      })
      .catch((error) => {});
  };
}

/**
 * API call for updating the production tx guide
 * @function
 * @param {Object} updates - Selections to update the production tx guide with
 * @return {Object} Action type
 */
export function updateStage1ProductionTxGuide(updates) {
  return (dispatch, getState) => {
    const { case_id, stage_1_questions_old } = getState().stage1ProductionTxGuideReducer;
    const data = { update_data: _.cloneDeep(updates), old_values: stage_1_questions_old };
    if (!data.update_data.change_treatment_stage_1) {
      data.update_data.change_treatment_stage_1_selections = {};
    }

    dispatch(updateStage1ProductionTxGuidePending());
    delete data.update_data.treatment_arch;
    Axios.put(`/apiv3/productiontxguide/${case_id}/stage_1`, data)
      .then((res) => {
        dispatch(updateStage1ProductionTxGuideSuccess(updates));
        dispatch(closeChangeTreatmentStage1Modal());
        dispatch(closeAllTeethSelectorModals());
        dispatch({
          type: SET_STAGE_1_QUESTIONS_OLD,
          stage_1_questions_old: _.cloneDeep(data.update_data),
        });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          dispatch({
            type: SET_STAGE_1_SESSION_EXPIRED,
            session_expired: true,
          });
        }
        dispatch(updateStage1ProductionTxGuideError());
      });
  };
}

/**
 * Returns the previous and new treatment arch based on the stage 1 questions and the current state.
 * @param {Object} stage_1_questions - The stage 1 questions object.
 * @param {Object} state - The current state object.
 * @returns {Object} An object containing the previous and new treatment arch.
 */
function getArchs(stage1Questions, state) {
  const {
    change_treatment_stage_1: newChangeTreatment,
    change_treatment_stage_1_selections: newChangeTreatmentSelections,
    treatment_arch: newTreatmentArch,
  } = stage1Questions;

  const {
    stage_1_questions: { change_treatment_stage_1_selections: currentChangeTreatmentSelections, treatment_arch: currentTreatmentArch },
  } = state;

  const newArch = newChangeTreatment ? newChangeTreatmentSelections.treatment_arch : newTreatmentArch;
  const currentArch = newChangeTreatment ? currentTreatmentArch : currentChangeTreatmentSelections.treatment_arch;

  return { currentArch, newArch };
}

/**
 * Action creator that updates the stage 1 production text guide with new questions and last informed archs.
 * @param {Object} stage_1_questions - The new stage 1 questions to update the guide with.
 * @return {Object} Action type
 */
export function updateStage1ProductionTxGuideSuccess(stage1Questions) {
  return (dispatch, getState) => {
    const state = getState().stage1ProductionTxGuideReducer;
    const { last_informed_archs: lastInformedArchs, stage_1_questions: stateStage1Questions } = state;
    const { wire_selections: stateWireSelections = [] } = stateStage1Questions;

    const { currentArch, newArch } = getArchs(stage1Questions, state);
    if (newArch === currentArch) {
      dispatch({
        type: UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_SUCCESS,
        stage_1_questions: stage1Questions,
        last_informed_archs: lastInformedArchs,
      });
      return;
    }

    const lastInformedArchsUpdated = { ...lastInformedArchs };
    if (['upper', 'lower'].includes(newArch)) {
      delete lastInformedArchsUpdated[newArch];

      const opposite_arch = newArch === 'upper' ? 'lower' : 'upper';
      if ([opposite_arch, 'both'].includes(currentArch)) {
        if (!lastInformedArchsUpdated[opposite_arch]) lastInformedArchsUpdated[opposite_arch] = {};
        lastInformedArchsUpdated[opposite_arch][`${opposite_arch}_idb_tray`] = stateStage1Questions[`${opposite_arch}_idb_tray`];
        lastInformedArchsUpdated[opposite_arch].wire_selections = stateWireSelections.filter((wire) => wire.toLowerCase().startsWith(opposite_arch));
      }
    } else {
      delete lastInformedArchsUpdated.lower;
      delete lastInformedArchsUpdated.upper;
    }

    const addedArch = newArch === 'both' ? (currentArch === 'upper' ? 'lower' : 'upper') : newArch;
    if (lastInformedArchs?.[addedArch]) {
      stage1Questions[`${addedArch}_idb_tray`] = lastInformedArchs[addedArch][`${addedArch}_idb_tray`];
      stage1Questions.wire_selections = [...stateWireSelections, ...lastInformedArchs[addedArch].wire_selections];
    }

    dispatch({
      type: UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_SUCCESS,
      stage_1_questions: stage1Questions,
      last_informed_archs: lastInformedArchsUpdated,
    });
  };
}

export function updateStage1ProductionTxGuidePending() {
  return {
    type: UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_PENDING,
  };
}

export function updateStage1ProductionTxGuideError() {
  return {
    type: UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_ERROR,
  };
}

/**
 * Handles event when user saves production tx guide edits in tab
 * @function
 * @return {Object} Action type
 */
export function saveStage1ProductionTxGuideSelections(gen_2) {
  return (dispatch, getState) => {
    const { case_id, stage_1_questions, treatment_plan_revision } = getState().stage1ProductionTxGuideReducer;
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    const form_completion = isStage1TxGuideFormCompleted(case_id, stage_1_questions, smile_design_questions, treatment_plan_revision, gen_2);

    if (form_completion.is_completed) {
      if (!stage_1_questions.change_treatment_stage_1) {
        stage_1_questions.change_treatment_stage_1_selections = {};
      }
      dispatch(updateStage1ProductionTxGuide(stage_1_questions));
      dispatch(setEditModeStage1(false));
      dispatch(setEditNotesMode(false));
    } else {
      for (const incompleted_type of form_completion.incompleted_types) {
        dispatch(setStage1IncompleteError(incompleted_type, true));
      }
    }
  };
}

/**
 * Removes wire selection for arch if arch is removed from change in treatment. Also adds default wire selection if change in treatment brings back the arch
 * @function
 * @param {Object} stage_1_questions - Stage I production tx guide questions
 * @param {Object} smile_design_questions - Smile design production tx guide questions
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} treatment_arch - Selected treatment arch from change in treatment
 * @param {Boolean} gen_2 - Gen 2.0 indicator
 * @return {Object} Action type
 */
function setWires(stage_1_questions, smile_design_questions, requested_treatment, treatment_arch, gen_2) {
  const previous_arch = getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'treatment_arch', requested_treatment);
  let wires = stage_1_questions.wire_selections;

  if (!gen_2) {
    if (previous_arch === 'upper' && treatment_arch !== 'upper') {
      wires.push('Lower DE Smartwire 2 (.016 NiTi)');
    } else if (previous_arch === 'lower' && treatment_arch !== 'lower') {
      wires.push('Upper DE Smartwire 2 (.016 NiTi)');
    }
    if (treatment_arch === 'upper' || treatment_arch === 'lower') {
      wires = wires.filter((w) => {
        return w.toLowerCase().includes(treatment_arch);
      });
    }
  } else {
    const upperWires = ['Upper DE Smartwire 1', 'Upper DE Smartwire 2'];
    const lowerWires = ['Lower DE Smartwire 1', 'Lower DE Smartwire 2'];
    wires = treatment_arch === 'upper' ? upperWires : treatment_arch === 'lower' ? lowerWires : upperWires.concat(lowerWires);
  }
  return wires;
}

// Modals

/**
 * Opens Change in Treatment teeth selector modal
 * @function
 * @return {Object} Action type
 * @param {String} Cad Status Stage
 */
export function openChangeTreatmentStage1Modal(cad_status) {
  return (dispatch, getState) => {
    const { stage_1_questions, requested_treatment, treatment_plan_revision } = getState().stage1ProductionTxGuideReducer;
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;

    const current_arch_to_treat = getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'arch_to_treat', requested_treatment);
    const current_no_bracket_bonded = getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'no_bracket_bonded', requested_treatment);
    const arch_lock_stages = ['Stage II', 'Stage III'];

    dispatch(setTeethChartTreatmentPlanRevisionAction(treatment_plan_revision));
    dispatch(setShowArchSelection(true));
    arch_lock_stages.includes(cad_status) && dispatch(setArchDisabled(true));
    dispatch(setStage1IncompleteError('change_treatment', false));
    dispatch(setStage1IncompleteError('no_treatment', false));
    dispatch(setMessage('Specify change in treatment.'));
    dispatch(setTeethSelectorLabel('Extent of Treatment:'));
    dispatch(setShowTextBox(true));
    dispatch(setTextBoxLabel('Reason(s)*:'));
    dispatch(setTextBoxPlaceholder('Enter reason(s) here...'));
    dispatch(setTextBody(getTeethSelectorModalTextBody(current_arch_to_treat, current_no_bracket_bonded)));
    dispatch(setTreatmentArch(getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'treatment_arch', requested_treatment, 'both')));
    dispatch(
      setOpposingTreatmentArch(
        getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'opposing_treatment_arch', requested_treatment, '')
      )
    );
    dispatch(setSelectedTeeth(getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'selected_teeth', requested_treatment, [])));
    dispatch(setShowPresets());

    dispatch({
      type: OPEN_CHANGE_TREATMENT_STAGE_1_MODAL,
    });
  };
}

/**
 * Gets values selected from Change in Treatment or Smile Design change in treatment (to display in the change treatment modal)
 * @function
 * @param {Object} stage_1_questions - Production tx guide questions
 * @param {Object} smile_design_questions - Smile Design Production tx guide questions
 * @param {String} treatment_item - Item to retrieve
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} default_value - Default value if item doesn't exist
 * @return {String} Treatment value
 */
function getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, treatment_item, requested_treatment, default_value = null) {
  return stage_1_questions && stage_1_questions.change_treatment_stage_1 && stage_1_questions.change_treatment_stage_1_selections[treatment_item]
    ? stage_1_questions.change_treatment_stage_1_selections[treatment_item]
    : smile_design_questions && smile_design_questions.change_treatment_smile_design_selections[treatment_item]
    ? smile_design_questions.change_treatment_smile_design_selections[treatment_item]
    : smile_design_questions && smile_design_questions.requested_treatment_selections[treatment_item]
    ? smile_design_questions.requested_treatment_selections[treatment_item]
    : requested_treatment[treatment_item]
    ? requested_treatment[treatment_item]
    : default_value;
}

/**
 * Gets values from Smile Design change in treatment
 * @function
 * @param {Object} smile_design_questions - Smile Design Production tx guide questions
 * @param {String} treatment_item - Item to retrieve
 * @param {String} default_value - Default value if item doesn't exist
 * @return {String} Treatment value
 */
function getChangeTreatmentSmileDesignInformation(smile_design_questions, treatment_item, default_value = null) {
  return smile_design_questions && smile_design_questions.change_treatment_smile_design_selections[treatment_item]
    ? smile_design_questions.change_treatment_smile_design_selections[treatment_item]
    : default_value;
}

/**
 * Gets values selected from requested treatment
 * @function
 * @param {Object} smile_design_questions - Smile Design Production tx guide questions
 * @param {String} treatment_item - Item to retrieve
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} default_value - Default value if item doesn't exist
 * @return {String} Treatment value
 */
function getRequestedTreatmentInformation(smile_design_questions, treatment_item, requested_treatment, default_value = null) {
  return smile_design_questions && smile_design_questions.requested_treatment_selections[treatment_item]
    ? smile_design_questions.requested_treatment_selections[treatment_item]
    : requested_treatment[treatment_item]
    ? requested_treatment[treatment_item]
    : default_value;
}

/**
 * Displays requested arch to treat and teeth with no bracket bonded to display in teeth selector modal
 * @function
 * @param {String} arch_to_treat - Arch to treat
 * @param {Array} no_bracket_bonded - Teeth with no bracket bonded
 * @return {JSX} JSX with teeth information
 */
function getTeethSelectorModalTextBody(arch_to_treat, no_bracket_bonded) {
  return (
    <React.Fragment>
      <div className="p-slim grey-text">All other automation processes will be updated according to the new change in treatment.</div>
      <div className="p-slim grey-text modal-text-body">
        <div>Current Treatment: {arch_to_treat ? arch_to_treat : 'N/A'}.</div>
        <div>Teeth Excluded from Treatment: {no_bracket_bonded && no_bracket_bonded.length > 0 ? no_bracket_bonded.join(', ') : 'N/A'}</div>
      </div>
    </React.Fragment>
  );
}

export function closeChangeTreatmentStage1Modal() {
  return (dispatch) => {
    dispatch(clearTeethChartInformation());
    dispatch({
      type: CLOSE_CHANGE_TREATMENT_STAGE_1_MODAL,
    });
  };
}

/**
 * Handles event when user saves teeth selection in Change in Treatment modal
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onConfirmButtonClickChangeTreatmentStage1Modal(is_tab_view) {
  return (dispatch, getState) => {
    const { stage_1_questions, requested_treatment, case_id } = getState().stage1ProductionTxGuideReducer;
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    const { selected_teeth, treatment_arch, opposing_treatment_arch, text_box_note } = getState().teethChartReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);
    const formatted_teeth = orderTeeth(removeOpposingTeeth(treatment_arch, selected_teeth));
    const error = getError(
      stage_1_questions,
      smile_design_questions,
      formatted_teeth,
      treatment_arch,
      requested_treatment,
      opposing_treatment_arch,
      text_box_note
    );
    const { gen_2 } = getState().productionTxGuideReducer;

    if (_.isEmpty(error)) {
      stage_1_questions_updated.wire_selections = setWires(stage_1_questions_updated, smile_design_questions, requested_treatment, treatment_arch, gen_2);
      const no_bracket_bonded = getUnselectedTeeth(formatted_teeth);
      stage_1_questions_updated.change_treatment_stage_1_selections = {
        selected_teeth: formatted_teeth,
        treatment_arch: treatment_arch,
        opposing_treatment_arch: opposing_treatment_arch,
        arch_to_treat: TeethUtils.formatTreatmentArch(treatment_arch, formatted_teeth, opposing_treatment_arch, case_id),
        no_bracket_bonded: no_bracket_bonded,
        reason: text_box_note,
      };

      for (const bracket_options_type of Object.keys(stage_1_questions_updated.stage_1_selected_teeth)) {
        stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type] = stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type].filter(
          (b) => {
            if (treatment_arch === 'upper' || treatment_arch === 'lower') {
              return b.charAt(0) === `${treatment_arch.charAt(0).toUpperCase()}` && no_bracket_bonded.indexOf(b) === -1;
            }
            return no_bracket_bonded.indexOf(b) === -1;
          }
        );
        if (stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type].length === 0) {
          delete stage_1_questions_updated.stage_1_selected_teeth[bracket_options_type];
        }
      }

      if (treatment_arch === 'upper' || treatment_arch === 'lower') {
        stage_1_questions_updated[`${treatment_arch === 'upper' ? 'lower' : 'upper'}_idb_tray`] = false;
      }

      if (is_tab_view) {
        dispatch(updateStage1ProductionTxGuideSuccess(stage_1_questions_updated));
        dispatch(closeChangeTreatmentStage1Modal());
      } else {
        dispatch(updateStage1ProductionTxGuide(stage_1_questions_updated));
      }
    } else {
      error.type ? dispatch(setTeethSelectionError(error.message, error.type)) : dispatch(setTeethSelectionError(error.message));
    }
  };
}

/**
 * Opens Teeth selector modal
 * @function
 * @param {String} modal_type - Modal type
 * @param {Boolean} is_de - DE Status
 * @return {Object} Action type
 */
export function openStage1TeethSelectorModal(modal_type, is_de) {
  return (dispatch, getState) => {
    const { stage_1_questions, requested_treatment } = getState().stage1ProductionTxGuideReducer;
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);

    const messages = {
      not_included: 'Specify teeth with no bracket included in the initial IDB tray and needs to be direct bonded later.',
      collisions: 'Specify teeth with expected interference with opposing teeth/bracket/Smartwire.',
      repositioned: 'Specify teeth that require reposition using single-tooth IDB tray.',
      offset: 'Specify teeth with offsetted bracket that need to be repositioned later.',
      new: 'Specify teeth that need to bond new brackets using single-tooth IDB trays.',
      arch: 'Specify teeth that need to bond new brackets using arch segments.',
    };

    dispatch(setMessage(messages[modal_type]));
    dispatch(setSelectedTeeth(stage_1_questions.stage_1_selected_teeth[modal_type] ? stage_1_questions.stage_1_selected_teeth[modal_type] : []));
    dispatch(setTreatmentArch(getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'treatment_arch', requested_treatment, 'both')));

    let disabled_teeth = _.cloneDeep(
      getChangeTreatmentStage1Information(stage_1_questions_updated, smile_design_questions, 'no_bracket_bonded', requested_treatment, [])
    );
    if (is_de) {
      const de_selections = ['repositioned', 'new', 'arch'];
      if (de_selections.includes(modal_type)) {
        const others = de_selections.filter((selection) => selection !== modal_type);
        for (let selection of others) {
          if (stage_1_questions_updated.stage_1_selected_teeth[selection])
            for (const tooth of stage_1_questions_updated.stage_1_selected_teeth[selection]) {
              disabled_teeth.push(tooth);
            }
        }
      }
    } else {
      if (['not_included', 'offset'].includes(modal_type)) {
        if ((modal_type === 'offset' ? 'not_included' : 'offset') in stage_1_questions_updated.stage_1_selected_teeth) {
          for (const tooth of stage_1_questions_updated.stage_1_selected_teeth[modal_type === 'offset' ? 'not_included' : 'offset']) {
            disabled_teeth.push(tooth);
          }
        }
      }
    }
    dispatch(setDisabledTeeth(disabled_teeth));

    dispatch({
      type: OPEN_STAGE_1_TEETH_SELECTOR_MODAL,
      modal_type: modal_type,
    });
  };
}

export function closeStage1TeethSelectorModal(modal_type) {
  return (dispatch) => {
    dispatch(clearTeethChartInformation());
    dispatch({
      type: CLOSE_STAGE_1_TEETH_SELECTOR_MODAL,
      modal_type: modal_type,
    });
  };
}

/**
 * Handles event when user saves selection in teeth selector modal
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @param {String} modal_type - Modal type
 * @return {Object} Action type
 */
export function onConfirmButtonClickTeethSelectorModal(is_tab_view, modal_type) {
  return (dispatch, getState) => {
    const { stage_1_questions } = getState().stage1ProductionTxGuideReducer;
    const { selected_teeth } = getState().teethChartReducer;
    let stage_1_questions_updated = _.cloneDeep(stage_1_questions);

    if (selected_teeth.length === 0) {
      delete stage_1_questions_updated.stage_1_selected_teeth[modal_type];
    } else {
      stage_1_questions_updated.stage_1_selected_teeth[modal_type] = orderTeeth(selected_teeth);
    }
    if (is_tab_view) {
      dispatch(updateStage1ProductionTxGuideSuccess(stage_1_questions_updated));
      dispatch(closeStage1TeethSelectorModal(modal_type));
    } else {
      dispatch(updateStage1ProductionTxGuide(stage_1_questions_updated));
    }
  };
}

/**
 * Determines if there is an error in the teeth selector modals
 * @function
 * @param {Object} smile_design_questions - Smile Design Treatment guide questions
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} treatment_arch - Selector treatment arch
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} text_box_note - Note entered in text box in modal
 * @return {Object} Error information
 */
function getError(stage_1_questions, smile_design_questions, selected_teeth, treatment_arch, requested_treatment, opposing_treatment_arch, text_box_note = '') {
  let error = {};
  if (selected_teeth.length === 0) {
    error.message = 'Please specify change in treatment.';
  } else if (hasSameTeethAsCurrentTreatment(stage_1_questions, smile_design_questions, requested_treatment, selected_teeth, opposing_treatment_arch)) {
    error.message = 'Your treatment selections are identical to the current treatment. Please update again.';
  } else if (hasSameTeethAsSmileDesignTreatment(smile_design_questions, selected_teeth, opposing_treatment_arch)) {
    error.message = 'Your treatment selections are identical to the Change in Treatment made by the smile design team. Please update again.';
  } else if (
    !smile_design_questions.change_treatment_smile_design_selections.selected_teeth &&
    hasSameTeethAsRequestedTreatment(smile_design_questions, requested_treatment, selected_teeth, opposing_treatment_arch)
  ) {
    error.message = 'Your treatment selections are identical to the requested treatment. Please update again.';
  } else if (hasMissingTeethFromArch(selected_teeth, treatment_arch, 'upper')) {
    error.message = 'Please specify the extent of treatment for the upper arch.';
  } else if (hasMissingTeethFromArch(selected_teeth, treatment_arch, 'lower')) {
    error.message = 'Please specify the extent of treatment for the lower arch.';
  } else if (!text_box_note) {
    error.message = 'Please enter reason for change in treatment.';
    error.type = 'text_box';
  }
  return error;
}

/**
 * Determines if selected teeth is identical to current treatment
 * @function
 * @param {Object} stage_1_questions - Stage I Treatment guide questions
 * @param {Object} smile_design_questions - Smile Design Treatment guide questions
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} opposing_treatment_arch - Selected opposing treatment arch
 * @return {Boolean} True or False
 */
function hasSameTeethAsCurrentTreatment(stage_1_questions, smile_design_questions, requested_treatment, selected_teeth, opposing_treatment_arch) {
  const current_treatment_teeth = getChangeTreatmentStage1Information(stage_1_questions, smile_design_questions, 'selected_teeth', requested_treatment, []);
  const current_opposing_treatment_arch = getChangeTreatmentStage1Information(
    stage_1_questions,
    smile_design_questions,
    'opposing_treatment_arch',
    requested_treatment,
    ''
  );
  return selected_teeth.join(',') === orderTeeth(current_treatment_teeth).join(',') && opposing_treatment_arch === current_opposing_treatment_arch;
}

/**
 * Determines if selected teeth is identical to smile design change in treatment
 * @function
 * @param {Object} smile_design_questions - Smile Design Treatment guide questions
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} opposing_treatment_arch - Selected opposing treatment arch
 * @return {Boolean} True or False
 */
function hasSameTeethAsSmileDesignTreatment(smile_design_questions, selected_teeth, opposing_treatment_arch) {
  const smile_design_treatment_teeth = getChangeTreatmentSmileDesignInformation(smile_design_questions, 'selected_teeth', []);
  const smile_design_opposing_treatment_arch = getChangeTreatmentSmileDesignInformation(smile_design_questions, 'opposing_treatment_arch', '');
  return selected_teeth.join(',') === orderTeeth(smile_design_treatment_teeth).join(',') && opposing_treatment_arch === smile_design_opposing_treatment_arch;
}

/**
 * Determines if selected teeth is identical to requested treatment
 * @function
 * @param {Object} smile_design_questions - Smile Design Treatment guide questions
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} opposing_treatment_arch - Selected opposing treatment arch
 * @return {Boolean} True or False
 */
function hasSameTeethAsRequestedTreatment(smile_design_questions, requested_treatment, selected_teeth, opposing_treatment_arch) {
  const requested_treatment_teeth = getRequestedTreatmentInformation(smile_design_questions, 'selected_teeth', requested_treatment, []);
  const requested_opposing_treatment_arch = getRequestedTreatmentInformation(smile_design_questions, 'opposing_treatment_arch', requested_treatment, '');
  return (
    !smile_design_questions.change_treatment_smile_design_selections.selected_teeth &&
    selected_teeth.join(',') === orderTeeth(requested_treatment_teeth).join(',') &&
    opposing_treatment_arch === requested_opposing_treatment_arch
  );
}

/**
 * Determines if selected teeth is missing teeth from upper or lower arch
 * @function
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} treatment_arch - Selected treatment arch
 * @param {String} arch - Upper or Lower
 * @return {Boolean} True or False
 */
function hasMissingTeethFromArch(selected_teeth, treatment_arch, arch) {
  return treatment_arch === 'both' && selected_teeth.filter((tooth) => tooth.startsWith(`${arch[0].toUpperCase()}`)).length === 0;
}

/**
 * Sets error to be displayed in teeth selector modal
 * @function
 * @param {String} error_message - Error message
 * @param {String} error_type - Type of error
 * @return {Object} Action type
 */
function setTeethSelectionError(error_message, error_type = 'teeth_selection') {
  return (dispatch) => {
    dispatch(setError(true, error_message, error_type));
  };
}

function closeAllTeethSelectorModals() {
  return (dispatch, getState) => {
    const { teeth_selector_modals } = getState().stage1ProductionTxGuideReducer;
    for (let modal_type in teeth_selector_modals) {
      dispatch(closeStage1TeethSelectorModal(modal_type));
    }
  };
}

export function openStage1TxGuidePdfModal(case_id) {
  return (dispatch) => {
    dispatch({
      type: OPEN_STAGE_1_TX_GUIDE_PDF_MODAL,
      file_path: '',
    });
    Axios.post(`/apiv3/productiontxguide/${case_id}/generate`).then((res) => {
      dispatch({
        type: SET_STAGE_1_TX_GUIDE_FILE_PATH,
        file_path: res.data.file_path,
      });
    });
  };
}

export function closeStage1TxGuidePdfModal() {
  return {
    type: CLOSE_STAGE_1_TX_GUIDE_PDF_MODAL,
  };
}

function clearStage1IncompleteErrors() {
  return (dispatch) => {
    dispatch(setIFUIncorrectFileNameError('case_id', false));
    dispatch(setIFUIncorrectFileNameError('format', false));
    dispatch(setStage1IncompleteError('change_treatment', false));
    dispatch(setStage1IncompleteError('wire_selections', false));
    dispatch(setStage1IncompleteError('no_treatment', false));
  };
}
