import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { oneLineBreakLimited } from '../../common/functions';
import { SummaryContainer, SummaryContainerSection, SummaryHeader, useCommonSubmissionSummary } from '../components/commons_submission_summary';
import { ToothViewer } from '../case_submission/tx_plan';
import * as nomenclature from '../../common/nomenclature';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';

import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { buildRecordStates, onLoadImageRecord } from '../../redux/actions/record_viewer/record_viewer';
import { convertDate } from '../../common/functions';

/**
 * Component for displaying summary on de submission page
 * @function
 * @param {object} props - Props object
 * @return {JSX} - JSX for submission summary
 */
const DESubmissionSummary = (props) => {
  const { getTeethValue, getAnteriorPosteriorValue, getBracketPlacementValue, getAuxiliariesValue } = useCommonSubmissionSummary({
    buildRecordStates: props.buildRecordStates,
    photoUpload: props.photoUpload,
    xrayUpload: props.xrayUpload,
    scanUpload: props.scanUpload,
    anteriorPosteriorR: props.de3_anterior_posterior_r,
    anteriorPosteriorL: props.de3_anterior_posterior_l,
    anteriorPosteriorCorrections: props.de3_anterior_posterior_corrections
      ? {
          classIIOrIIICorrectionSimulation: props.de3_anterior_posterior_corrections.class_II_or_III_correction_simulation,
          posteriorIPR: props.de3_anterior_posterior_corrections.posterior_IPR,
        }
      : undefined,
    anteriorPosteriorNotes: props.de3_anterior_posterior_notes,
    bracketTeeth: props.de3_bracket_teeth,
    archToTreat: props.de3_arch_to_treat,
    missingTeeth: props.de3_missing_teeth,
    extractTeeth: props.de3_extract_teeth,
    auxiliariesWillBeUsed: props.de3_auxiliaries,
    auxiliariesNotes: props.de3_auxiliaries_notes,
    // iTero integration
    selectedIteroScanRecords: props.selectedIteroScanRecords,
    uploadMethod: props.uploadMethod,
  });

  const getReasonValue = () => {
    return props.de3_reason ? oneLineBreakLimited(props.de3_reason) : 'N/A';
  };

  const getDateOfNextAppointmentValue = () => {
    return props.de3_next_appointment_date ? convertDate(props.de3_next_appointment_date) : 'N/A';
  };

  const getDateOfDigitalEnhancementValue = () => {
    return props.de3_intra_date ? convertDate(props.de3_intra_date) : 'N/A';
  };

  const getMostRecentlyInsertedSmartwireValue = () => {
    const de3_upper_smartwire = props.de3_upper_smartwire;
    const de3_upper_smartwire_date = props.de3_upper_smartwire_date;
    const de3_lower_smartwire = props.de3_lower_smartwire;
    const de3_lower_smartwire_date = props.de3_lower_smartwire_date;

    const smartwire_dict = {
      none: 'None',
      stage0: nomenclature.SMARTWIRE_0,
      stage1: nomenclature.SMARTWIRE_1,
      stage2: nomenclature.SMARTWIRE_2,
      stage3: nomenclature.SMARTWIRE_3,
      de0: nomenclature.DE_SMARTWIRE_0,
      de1: nomenclature.DE_SMARTWIRE_1,
      de2: nomenclature.DE_SMARTWIRE_2,
      de3: nomenclature.DE_SMARTWIRE_3,
    };

    const upper_smartwire = de3_upper_smartwire_date
      ? `${smartwire_dict[de3_upper_smartwire]} (inserted ${convertDate(de3_upper_smartwire_date)})`
      : smartwire_dict[de3_upper_smartwire];
    const lower_smartwire = de3_lower_smartwire_date
      ? `${smartwire_dict[de3_lower_smartwire]} (inserted ${convertDate(de3_lower_smartwire_date)})`
      : smartwire_dict[de3_lower_smartwire];

    return (
      <div>
        <p>Upper: {upper_smartwire}</p>
        <p>Lower: {lower_smartwire}</p>
      </div>
    );
  };

  const getArchToTreatValue = () => {
    const arch_to_treat_dict = {
      both: 'Both arches',
      upper: 'Upper arch (Smartwire provided for upper arch only)',
      lower: 'Lower arch (Smartwire provided for lower arch only)',
    };

    const opposing_arch_to_treat_dict = {
      upper: {
        current: 'Use current lower arch for Smile Design',
        both: 'Set up both arches',
      },
      lower: {
        current: 'Use current upper arch for Smile Design',
        both: 'Set up both arches',
      },
    };

    const arch_to_treat = props.de3_arch_to_treat;
    const opposing_arch_to_treat = props.de3_arch_opposing_treat;

    if (arch_to_treat === 'both') {
      return arch_to_treat_dict['both'];
    }

    return opposing_arch_to_treat
      ? `${arch_to_treat_dict[arch_to_treat]}. ${opposing_arch_to_treat_dict[arch_to_treat][opposing_arch_to_treat]}`
      : arch_to_treat_dict[arch_to_treat];
  };

  const getMidlinesValue = () => {
    const midlines_dict = {
      show_resulting_after_aligment: 'Show resulting midline after alignment',
      improve_with_ipr: 'Improve midlines with IPR',
      improve_with_ap_shift: 'Improve midlines with AP shift (elastics required)',
      improve_with_ipr_and_ap_shift: 'Improve midlines with IPR and AP shift',
    };

    return midlines_dict[props.de3_midlines];
  };

  const getSetupAdditionalInstructionsValue = () => {
    const additional_instructions_dict = {
      yes: 'Yes',
      no: 'No thanks. Perform only what I have specified in the next question',
    };

    return additional_instructions_dict[props.de3_setup_additional_instruction];
  };

  const getSetupInstructionsValue = () => {
    const setup_instructions_dict = {
      accept_current_improve_detailing: 'Accept current occlusion but improve on detailing',
      settle_current_improve_detailing: 'Settle the current occlusion and improve on detailing',
      same: 'Same treatment goals as the most recently approved Smile Design',
      adjust_treatment_improve_detailing: 'Adjust treatment goals and improve on detailing',
      specific_tooth_movements: 'Only specific tooth movements as listed below',
    };

    const setup = setup_instructions_dict[props.de3_setup];
    const instruction = props.de3_setup_instruction ? `, "${oneLineBreakLimited(props.de3_setup_instruction).trim()}"` : '';

    return setup + instruction;
  };

  const fields = [
    {
      title: 'Reason(s) for Digital Enhancement',
      value: getReasonValue(),
    },
    {
      title: 'Date of next appointment',
      value: getDateOfNextAppointmentValue(),
    },
    {
      title: 'Date of Digital Enhancement Toothprints',
      value: getDateOfDigitalEnhancementValue(),
    },
    {
      title: 'Most recently inserted Smartwire',
      value: getMostRecentlyInsertedSmartwireValue(),
    },
    {
      title: 'Missing/Previously Extracted teeth',
      value: getTeethValue(props.de3_missing_teeth),
    },
    {
      title: 'Teeth to be extracted',
      value: getTeethValue(props.de3_extract_teeth),
    },
    {
      title: 'Arch to treat for Digital Enhancement',
      value: getArchToTreatValue(),
    },
    {
      title: 'Digital Enhancement Smartwires designed for the following bracket placement',
      value: getBracketPlacementValue(),
    },
    {
      title: 'Do not move the following teeth',
      value: getTeethValue(props.de3_teeth_restriction),
    },
    {
      title: 'Anterior-Posterior',
      value: getAnteriorPosteriorValue(),
    },
    {
      title: 'Midlines',
      value: getMidlinesValue(),
    },
    {
      title: 'Auxiliaries',
      value: getAuxiliariesValue(),
    },
    {
      title: 'Allow InBrace to perform additional Smile Design adjustments as needed',
      value: getSetupAdditionalInstructionsValue(),
    },
    {
      title: 'How would you like your Digital Enhancement Smile Design',
      value: getSetupInstructionsValue(),
    },
  ];

  return (
    <>
      <SummaryHeader />
      <ThumbnailURLInterface {...props.thumbnailComponentProps} recordScroll={true} />
      <div className="submission-summary">
        <SummaryContainer>
          <SummaryContainerSection>
            <ol className="submission-summary__ol">
              {fields.map((field, index) => {
                return (
                  <li className="submission-summary__field" key={index}>
                    <div className="emphasis">{field.title}</div>
                    <div className="submission-summary__field__value">{field.value}</div>
                  </li>
                );
              })}
            </ol>
          </SummaryContainerSection>
          <SummaryContainerSection>
            <ToothViewer teethSets={props.getImpliedTeethSets()} />
          </SummaryContainerSection>
        </SummaryContainer>
        <div className="line-division--primary" />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DESubmissionSummary);
