import Axios from 'axios';
import React from 'react';
import Moment from 'moment';
import moment from 'moment-timezone';
import { parsePhoneNumber } from 'libphonenumber-js';
import emojiRegex from 'emoji-regex';
import ValidationUtils from './validation/validation-utils';
import { getNewRecordState } from '../doctor/components/record_viewer/record_viewer_helpers';
import { slash2dashFormat } from './date';
import { getJWTToken } from './token';
import { UNITED_STATES } from './constants/countries';

let uuidv3 = require('uuid/v3');

export const ordered_teeth = [
  'UR8',
  'UR7',
  'UR6',
  'UR5',
  'UR4',
  'UR3',
  'UR2',
  'UR1',
  'UL1',
  'UL2',
  'UL3',
  'UL4',
  'UL5',
  'UL6',
  'UL7',
  'UL8',
  'LR8',
  'LR7',
  'LR6',
  'LR5',
  'LR4',
  'LR3',
  'LR2',
  'LR1',
  'LL1',
  'LL2',
  'LL3',
  'LL4',
  'LL5',
  'LL6',
  'LL7',
  'LL8',
];

const ordered_gen_2_wires = [
  'Upper DE Smartwire 1',
  'Upper DE Smartwire 2',
  'Upper DE Smartwire 3',
  'Lower DE Smartwire 1',
  'Lower DE Smartwire 2',
  'Lower DE Smartwire 3',
];

/**
 * Checks the role if it's one that is allowed to assigned a doctor.
 * @param {String} role - The role assigned to a user in db_doctor when an
 * account is created.
 */
const isRoleAllowedToAssign = (role) => {
  return role === 'DSO' || role === 'DSO_Doctor_Override' || role === 'DSO_Doctor';
};
/**
 * Disabled edit on draft if DSO account is not allowed to make changes
 * @param {String} dso_doctor_role - The role of the dso ipp account
 * @param {String} dso_doctor_id - The dso doctor id related to the role
 * @param {String} dso_doctor_assigned_id - The assigned dso doctor id
 */
const dsoDraftDisabled = (dso_doctor_role, dso_doctor_id, dso_doctor_assigned_id) => {
  return (
    dso_doctor_role === 'DSO_Doctor' &&
    ((dso_doctor_id && dso_doctor_assigned_id && dso_doctor_id.toString() !== dso_doctor_assigned_id.toString()) || dso_doctor_assigned_id === '')
  );
};
/**
 * Determines if assignment dropdown should be loaded on initial patient info screen
 * @param {String} dso_doctor_role - The role of the dso ipp account
 * @param {String} dso_doctor_id - The dso doctor id related to the role
 * @param {String} dso_doctor_assigned_id - The assigned dso doctor id
 */
const isDSOAssignedLoadable = (dso_doctor_role, dso_doctor_id, dso_doctor_assigned_id) => {
  return dso_doctor_role === 'DSO_Doctor_Override' || dso_doctor_role === 'DSO' || dso_doctor_id.toString() !== dso_doctor_assigned_id.toString();
};
const computeDaysPast = (date) => {
  // Set Date to PST of 0th Hour
  let days = 0;

  if (date && typeof date === 'string' && date !== 'Invalid date') {
    const start_date_in_second = Moment(date).startOf('day').valueOf();

    const todays_date_in_second = Moment().startOf('day').valueOf();

    const second_in_a_day = 24 * 60 * 60 * 1000;

    days = Math.round((todays_date_in_second - start_date_in_second) / second_in_a_day);
  }

  return days === 1 ? days + ' day' : days + ' days';
};

const convertDate = (date) => {
  //Fail safe catch
  date = slash2dashFormat(date);

  if (date && typeof date === 'string' && date !== 'Invalid date') {
    let result = date.split('-');

    if (result[0] === '1900') {
      return '-';
    } else {
      return Moment(date).format('MM/DD/YYYY');
    }
  } else {
    return date;
  }
};

/**
 * Converts datetime with given format
 *
 * @param {Date} date - date value.
 * @param {String} format - type of format to be used.
 * @function
 */
const convertDateWithFormat = (date, format = 'MM/DD/YYYY') => {
  //Fail safe catch
  date = slash2dashFormat(date);

  if (date && typeof date === 'string' && date !== 'Invalid date') {
    let result = date.split('-');

    if (result[0] === '1900') {
      return '-';
    } else {
      return Moment(date).format(format);
    }
  } else {
    return date;
  }
};

/**
 * Converts date with given format to age
 *
 * @param {Date} date - date value.
 * @function
 */
const calculateAge = (birthday) => {
  var ageDifMs = Date.now() - parseDate(birthday).getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

/**
 * Converts date string to date
 *
 * @param {string} input - date value.
 * @function
 */
function parseDate(input) {
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}

const convertDateToMilliseconds = (date) => {
  if (date && typeof date === 'object' && typeof date.getTime === 'function') {
    return date.getTime();
  }

  if (date && typeof date === 'string' && date.length > 10) {
    date = date.split('T')[0];
  }

  let timestamp = moment.tz(date, 'America/Los_Angeles').format();
  timestamp = timestamp === 'Invalid date' ? 0 : timestamp;
  return new Date(timestamp).getTime();
};

const convertInputDate = (date) => {
  //Fail safe catch
  date = slash2dashFormat(date);

  if (date && typeof date === 'string' && date !== 'Invalid date') {
    let result = date.split('-');

    if (result[0] === '1900') {
      return '-';
    } else {
      return Moment(date).format('YYYY-MM-DD');
    }
  } else {
    return date;
  }
};

const modalClose = (id) => {
  const dismissButton = document.getElementById(id);
  if (dismissButton) {
    dismissButton.click();
  }

  modalClear();
};

const modalClear = () => {
  if (document && document.body && document.body.className) {
    document.body.className = document.body.className.replace('modal-open', '');
  }

  if (document && document.body && document.body.style && document.body.style.paddingRight) {
    document.body.style.paddingRight = '';
  }

  const backdrop = document.getElementsByClassName('modal-backdrop');

  if (backdrop && backdrop.length > 0) {
    backdrop[0].remove();
  }
};

/**
 * A function that returns the root case id
 * @param {string} case_id - Contains the case id
 * @return {string} A sanitized case id
 * @function
 */

function getBaseCaseId(case_id) {
  const splitId = case_id.split('-');
  return splitId[0];
}

/**
 * A function that returns the display version for the case id, and also
 * handles the -DE10 issue.
 * @param {string} case_id - Contains the case id
 * @return {string} A sanitized case id
 * @function
 */
const removeCaseIdInitialNumber = (case_id) => {
  const split_id = case_id.split('-');
  if (split_id.length > 1 && (split_id[1] === 'DE10' || split_id[1] === 'R10' || split_id[1].length >= 4)) {
    return case_id;
  }

  return case_id.replace('-R1', '-R').replace('-DE1', '-DE');
};
/**
 * A function that returns the display version for the ireq id, and also
 * handles the -IR10 issue.
 * @param {string} ireq_id - Contains the item request id
 * @return {string} A sanitized item request id
 * @function
 */
const removeIreqIdInitialNumber = (ireq_id) => {
  return ireq_id.endsWith('-IR1')
    ? ireq_id.replace('-IR1', '-IR').replace('-DE1-', '-DE-').replace('-R1', '-R').replace('-R0', '-R10').replace('-IR0', '-IR10')
    : ireq_id.replace('-DE1-', '-DE-').replace('-R1', '-R').replace('-R0', '-R10');
};

const formatCaseId = (idString) => {
  const parts = idString.split("-");

  // Handle DE part
  if (parts[1]?.startsWith("DE")) {
    const deNumber = parts[1].slice(2);
    parts[1] = deNumber === "1" ? "DE" : parts[1];
  }

  // Handle IR part
  if (parts[2]?.startsWith("IR")) {
    const irNumber = parts[2].slice(2);
    parts[2] = irNumber === "1" ? "IR" : parts[2];
  }

  return parts.filter(Boolean).join("-");
}
/**
 * Return FAD base URL
 * @function
 */
const getBasedFADURLBySite = () => {
  const is_testing =
    window.location.hostname.indexOf('local') >= 0 || window.location.hostname.indexOf('testing') >= 0 || window.location.hostname.indexOf('staging') >= 0;
  return is_testing ? 'inbrace-com-staging.herokuapp' : 'inbrace';
};
const formatShipping = (address) => {
  let addressStr = '';
  let comma = true;
  let addressField = ['addr1', 'addr2', 'city', 'state', 'zip'];

  if (address) {
    addressField.map(function (entry) {
      if (address[entry]) {
        if (addressStr === '') {
          addressStr = address[entry];
        } else {
          if (entry === 'addr2' || comma) {
            addressStr = addressStr + ', ' + address[entry];
            comma = false;
          } else {
            addressStr = addressStr + ' ' + address[entry];
          }
        }
      }
      return '';
    });
  }
};

const convertSex = (sex) => {
  if (!sex) {
    return sex;
  }

  if (sex === 'M') {
    return 'Male';
  } else if (sex === 'F') {
    return 'Female';
  } else if (sex === 'N') {
    return 'Non-Binary';
  } else {
    return 'Undisclosed';
  }
};

/**
 * Trims beginning spaces and line breaks from text
 *
 * @param {string} text
 * @return {string} text
 * @function
 */
const textFieldCheck = (text) => {
  if (text && text.length > 0) {
    if (text[0] === ' ' || text[0] === '\n') {
      text = text.substr(1, text.length);
    }
  }

  return text;
};
/**
 * Prevent Spacing in text
 *
 * @param {string} text - text entered by user
 * @return {string} text - formatted text
 * @function
 */
const preventSpacesInText = (text) => {
  return text.replace(' ', '');
};
/**
 * Prevent any illegal character from aws s3 folder objects
 *
 * @param {string} text - text entered by user
 * @return {string} text - formatted text
 * @function
 */
const awsObjectConstriant = (text) => {
  // eslint-disable-next-line
  return text.replace(/([\[&$@=;:+ ,?\\\/{^}%`\]\?>[~<#|\(\)\!\*\.\"\'\-\_])*/g, '');
};
/**
 * Prevent Patient Chart from using any non-standard char value
 *
 * @param {string} text - text entered by user
 * @return {string} text - formatted text
 * @function
 */
const patientChartTextConstraint = (text) => {
  return removeEmoji(awsObjectConstriant(textFieldCheck(preventSpacesInText(text))));
};
const textFieldLimited = (text) => {
  const text_limit = 750;

  if (text && text.length > 0 && text.length > text_limit) {
    text = text.substr(0, text_limit);
  }

  return text;
};

const removeSpaces = (text) => {
  text = text.replace(/ /g, '');
  return text;
};

const removeLineBreaks = (text) => {
  text = text.replace(/(\r\n|\n|\r)/gm, '');
  return text;
};

const oneLineBreakLimited = (text) => {
  return text.replace(/(\s?\n){2,}/g, '\n');
};

const removeSpecialChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[\[\]~!@#\$%\^&\*\(\)\-\+`_={}\|:"<>\?\\;',\.\/]/g, '');
  return text;
};

const removeNumberChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[0-9]/g, '');
  return text;
};

const safeSearchChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[\[\]~!@#\$%\^&\*\(\)\+`_={}\|:"<>\?\\;',\.\/]/g, '');
  return text;
};

const safeSearchCharPlusEmail = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[\[\]~!#\$%\^&\*\(\)\+`={}\|:"<>\?\\;',\/]/g, '');
  return text;
};

const phoneNumberChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[A-Za-z\[\]~!@#\$%\^&\*\+`_={}\|:"<>\?\\;',\.\/]/g, '');
  return text;
};

const phoneNumberCharInternational = (text) => {
  text = text.replace(/[A-Za-z\[\]~!@#\$%\^&\*`_={}\|:"<>\?\\;',\.\/]/g, '');
  return text;
};

/**
 * Validation for zip code input - only allow numbers and dashes with 10 characters max
 * @function
 * @param {String} text - Zip code input
 * @return {String} Formatted zip code with validation applied
 */
const zipNumberChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[A-Za-z\[\]~!@#\$%\^&\*\(\)\+`_={}\|:"<>\?\\;',\.\/]/g, '').replace(' ', '');

  if (text.length > 10) {
    text = text.substr(0, 10);
  }

  return text;
};

const npiNumberChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[^\w]/g, '');

  if (text.length > 15) {
    text = text.substr(0, 15);
  }

  return text;
};

/**
 * Allow only numbers up to 2 digits for business target days input
 * @function
 * @param text {string} - String input
 * @return {string} - Filtered string
 */
const targetDaysNumberChar = (text) => {
  // eslint-disable-next-line
  text = text.replace(/[A-Za-z\[\]~!@#\$%\^&\*\(\)\+`_={}\|:"<>\?\\;',\.\/\-]/g, '').replace(' ', '');

  if (text.length > 2) {
    text = text.substr(0, 2);
  }

  return text;
};

const checkdaysdiff = (case_date) => {
  let current_date = Date.now();
  case_date = Date.parse(case_date);
  let days = (current_date - case_date) / 1000 / 60 / 60 / 24;
  if (days < 14) {
    return true;
  } else {
    return false;
  }
};

//From https://www.w3schools.com/js/js_cookies.asp
const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (cookie_name, cookie_value, ex_days = 1) => {
  let expires = 'expires=';
  const d = new Date();
  d.setTime(d.getTime() + ex_days * 24 * 60 * 60 * 1000);
  expires = `${expires}${d.toUTCString()}`;
  document.cookie = cookie_name + '=' + cookie_value + ';' + expires + ';path=/';
};

const setTokenHeader = () => {
  const token = getJWTToken();
  const csrf = getCookie('csrftoken');

  if (token && csrf) {
    Axios.defaults.headers.common = {
      Authorization: 'Token ' + token,
      'X-CSRFToken': csrf,
    };
  } else if (token) {
    Axios.defaults.headers.common = {
      Authorization: 'Token ' + token,
    };
  }
};

const resetTokenHeader = () => {
  Axios.defaults.headers.common = {
    Authorization: '',
  };

  document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
  Axios.delete('/apiv3/session');
};

const generateId = (url) => {
  return uuidv3(url, uuidv3.URL);
};

const removeElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.parentNode.removeChild(element);
  }
};

const onThumbnailStlLoad = (id, reload) => {
  if (reload === false) {
    return;
  }
};

const onThumbnailLoad = (url, id, reload) => {
  setTokenHeader();

  if (reload === false) {
    return;
  }

  Axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  })
    .then((response) => {
      // Have to be very careful since this is an async call and the user may not
      // be on this page when we get the returned response
      let url = '';

      if (response.data.size !== 0) {
        url = window.URL.createObjectURL(new Blob([response.data]));
      } else {
        url = '/static/img/no-image.png';
      }
      if (document.querySelector(`#_${id}`)) {
        document.querySelector(`#_${id}`).src = url;
        document.querySelector(`#_${id}`).classList.add('image-fixed-size');
        document.querySelector(`#_${id}`).classList.remove('img-hide');
        removeElement(`loader_${id}`);
      }

      if (document.querySelector(`[src=_${id}]`)) {
        document.querySelector(`[src=_${id}]`).src = url;
        document.querySelector(`[src=_${id}]`).classList.add('image-fixed-size');
      }

      if (document.querySelector(`#new_${id}`)) {
        document.querySelector(`#new_${id}`).classList.remove('img-hide');
      }

      return url;
    })
    .catch(function (error) {
      if (document.querySelector(`#_${id}`)) {
        document.querySelector(`#_${id}`).src = '/static/img/no-image.png';
      }
    });
};

const downloadFile = (path) => {
  Axios({
    url: `/api/link/?file=${path}`,
    method: 'GET',
  }).then((response) => {
    const URL = response.data;
    const win = window.open(URL, '_blank');

    if (win) {
      win.focus();
      win.onblur = function () {
        win.close();
      };
    }
  });
};

const onDownload = (event) => {
  setTokenHeader();
  event.preventDefault();
  let href = event.currentTarget.href;
  let startingPath = href.indexOf('/', 9);
  let endPath = href.lastIndexOf('_');

  if (startingPath >= 0 && endPath >= 0) {
    let newRef = decodeURI(href.substring(startingPath, href.length));
    downloadFile(newRef);
  }
};

const sortImageFiles = (data) => {
  let photos = [];
  let xrays = [];
  let scans = [{}, {}];

  if (data && data.files) {
    for (let i = 0; i < data.files.length; i++) {
      if (data.files[i].file_type === 'photos') {
        photos.push(data.files[i]);
      } else if (data.files[i].file_type === 'xrays') {
        xrays.push(data.files[i]);
      } else if (data.files[i].file_type === 'upperscans') {
        scans[0] = data.files[i];
      } else if (data.files[i].file_type === 'lowerscans') {
        scans[1] = data.files[i];
      }
    }
  }

  return photos.concat(xrays.concat(scans));
};

const sortRawFiles = (data) => {
  let photos = [];
  let xrays = [];
  let scans = [{}, {}];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].file_type === 'photos') {
        photos.push(data[i]);
      } else if (data[i].file_type === 'xrays') {
        xrays.push(data[i]);
      } else if (data[i].file_type === 'upperscans') {
        scans[0] = data[i];
      } else if (data[i].file_type === 'lowerscans') {
        scans[1] = data[i];
      }
    }
  }

  return photos.concat(xrays.concat(scans));
};

const configLightBox = (files) => {
  let configure = files.map((file) => {
    if (file.file_type && file.file_type === 'upperscans') {
      return {
        src: `/static/img/upper.png`,
        caption: (
          <p>
            <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
              <i className="fa fa-download" aria-hidden="true" /> Download Original File
            </a>
          </p>
        ),
      };
    } else if (file.file_type && file.file_type === 'lowerscans') {
      return {
        src: `/static/img/lower.png`,
        caption: (
          <p>
            <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
              <i className="fa fa-download" aria-hidden="true" /> Download Original File
            </a>
          </p>
        ),
      };
    } else if (file.file_type && file.file_type === 'multi_scans') {
      return {
        src: `${file.file_url}`,
        alt: `${file.upload_data}`,
        caption: <p />,
      };
    } else if (file.file_type && file.file_type === 'scans') {
      return {
        src: `${file.file_url}`,
        caption: (
          <p>
            <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
              <i className="fa fa-download" aria-hidden="true" /> Download Original File
            </a>
          </p>
        ),
      };
    } else if (file.file_type && file.file_type === 'iteroScans') {
      return {
        src: `${file.file_url}`,
        caption: (
          <p>
            <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
              <i className="fa fa-download" aria-hidden="true" /> Download Original File
            </a>
          </p>
        ),
      };
    } else if (file.file_type && (file.file_type === 'photos' || file.file_type === 'xrays')) {
      return {
        src: `_${generateId(file.upload_data)}`,
        caption: (
          <p>
            <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
              <i className="fa fa-download" aria-hidden="true" /> Download Original File
            </a>
          </p>
        ),
      };
    } else if (file.file_type && (file.file_type === 'progress_records' || file.file_type === 'navigator')) {
      if (file.upload_data && file.upload_data.includes('.stl')) {
        return {
          src: `${file.file_url}`,
          caption: (
            <p>
              <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
                <i className="fa fa-download" aria-hidden="true" /> Download Original File
              </a>
            </p>
          ),
        };
      } else {
        return {
          src: `_${generateId(file.upload_data)}`,
          caption: (
            <p>
              <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
                <i className="fa fa-download" aria-hidden="true" /> Download Original File
              </a>
            </p>
          ),
        };
      }
    } else if (file.file_type && file.file_type === 'repaired_scans') {
      return {
        src: `${file.file_url}`,
        caption: (
          <p>
            <a href={`/${file.upload_data}`} download onClick={onDownload} className="footer_download">
              <i className="fa fa-download" aria-hidden="true" /> Download Original File
            </a>
          </p>
        ),
      };
    } else {
      return {
        src: '',
        caption: <p />,
      };
    }
  });
  return configure;
};

const getRefinementSuffix = (case_id) => {
  let result = case_id.match(/-(R)(\d*)|-(DE)(\d*)/);

  if (result && result.length > 0 && result[1] !== undefined) {
    return `(${result[1]}${result[2]})`;
  } else if (result && result.length > 0 && result[3] !== undefined) {
    return `(${result[3]}${result[4]})`;
  } else {
    return '';
  }
};

const getRootPath = (path) => {
  if (!path) {
    return path;
  }

  let match = path.match(/(\/portal\/\w+)/);

  if (match && match[1]) {
    return match[1];
  }
};

const getDoctorIdFromPath = (path) => {
  let rootPath = getRootPath(path);

  if (rootPath) {
    let doctorMatch = rootPath.match(/\/portal\/(\w+)/);

    if (doctorMatch) {
      return doctorMatch[1];
    }
  }

  return;
};

const firstLetterCapital = (text) => {
  // eslint-disable-next-line
  text = text
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
  return text;
};

/**
 * Formats the state based on the country.
 * If the country is United States, returns the state as is.
 * Otherwise, capitalizes the first letter of the state.
 *
 * @param {string} state - The state to be formatted.
 * @param {string} country - The country to determine the formatting rules.
 * @returns {string} - The formatted state.
 */
const formatState = (state, country) => {
  state = state.trim();
  if (country === UNITED_STATES) return state;
  return firstLetterCapital(state);
};

const makeUpperCaseAfterCommas = (str) => {
  var splitStr = str.toLowerCase().split(', ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = firstLetterCapital(splitStr[i]);
  }
  return splitStr.join(', ');
};

// eslint-disable-next-line
const itemRequestReasons = (text) => {
  // eslint-disable-next-line
  var dict = {
    smartwire_1: 'Occlusal interference caused breakage',
    smartwire_2: 'Trouble inserting/removing wire - cut the wire',
    smartwire_3: 'Patient discomfort - cut the wire',
    smartwire_4: 'Unknown cause of wire breakage',
    smartwire_5: 'Other reason:',
    brackets_1: 'Trouble with bracket springboard',
    brackets_2: 'Debonded - bracket not usable',
    brackets_3: 'Other reason:',
    idb_1: 'Original DE tray lost',
    idb_2: 'Original DE tray damaged',
    idb_3: 'Segmented bonding jig needed',
  };
};

/**
 * Generate a random password of n length - defaults to 8
 * @param {int} length
 * @returns {string} password
 */
function generatePass(length = 8) {
  var pass = '';
  var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';

  for (let i = 1; i <= length; i++) {
    var char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
}

function checkToken() {
  var token = getJWTToken();

  if (token) {
    return true;
  } else {
    return false;
  }
}

function getReportFileName(report_type) {
  const d = new Date();
  //get the month
  let month = d.getMonth();
  //get the day
  //convert day to string
  let day = d.getDate().toString();
  //get the year
  let year = d.getFullYear();
  //get the hour
  let hour = d.getHours();
  //get the minute
  let minute = d.getMinutes();

  //pull the last two digits of the year
  year = year.toString().substr(-2);

  //increment month by 1 since it is 0 indexed
  //converts month to a string
  month = (month + 1).toString();

  //if month is 1-9 pad right with a 0 for two digits
  if (month.length === 1) {
    month = '0' + month;
  }

  //if day is between 1-9 pad right with a 0 for two digits
  if (day.length === 1) {
    day = '0' + day;
  }

  //if HOUR is 1-9 pad right with a 0 for two digits
  if (hour <= 9) {
    hour = '0' + hour;
  }

  //if Minute is 1-9 pad right with a 0 for two digits
  if (minute.length === 1) {
    minute = '0' + minute;
  }

  return `${report_type}_${year}${month}${day}_${hour}${minute}.csv`;
}

async function getStlFileUrl(file_name, file_path) {
  let url = '';
  let response = await Axios({
    url: '/apiV2/stldownload/' + file_name + '/' + file_path,
    method: 'GET',
    responseType: 'arraybuffer',
  });
  url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/stl' }));
  return url;
}

function isHTML(str) {
  var a = document.createElement('div');
  a.innerHTML = str;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
}

/**
 * Gets the active addresses given list of addresses
 * @function
 * @param addresses {Array} - List of addresses
 * @return {Array} Active addresses
 */
function getActiveAddress(addresses) {
  let result = [];

  if (addresses && addresses.length > 0) {
    let active_address = addresses.filter((address) => {
      return address.is_active;
    });

    result = active_address;
  }

  return result;
}

/**
 * Gets the id of the active address given a list of addresses
 * @function
 * @param addresses {Array} - List of addresses
 * @return {Number} ID of the first active address; returns empty list if there are no active addresses
 */
function getNextActiveAddressId(addresses) {
  let active_addresses = getActiveAddress(addresses);
  let result = active_addresses.length > 0 ? active_addresses[0].id : [];

  return result;
}

function isActiveAddressById(addresses, id = '') {
  let is_active = false;

  if (addresses && addresses.length > 0) {
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].id === id) {
        is_active = addresses[i].is_active;
        break;
      }
    }
  }

  return is_active;
}

/**
 * Checks which reason is selected under idb_required category.
 *
 * This also clears out the selection for the other one.
 * used during item request submission or item request update
 * @param {Object} request_reasons - The request_reasons contains all the reason data for the item request.
 * @return {Object} Returns request_reasons json object back.
 */
function clearIdbRequiredReason(request_reasons) {
  request_reasons.forEach((reason) => {
    if (reason.category === 'idb_required') {
      if (reason.segment_preference === 'individual_teeth_segments') {
        reason.arch_segments_options.forEach((arch_segments_option) => {
          arch_segments_option.active = false;
        });
      } else {
        reason.selectedTeeth = [];
      }
    }
  });

  return request_reasons;
}
/**
 * Clears the segmentation selection by quanity
 *
 * @param {Object} item_part_quantity - The item_part_quantity contains the quanity of each item
 * @param {Object} request_reasons - The request_reasons contains all the reason data for the item request.
 * @return {Object} Returns request_reasons json object back.
 */
function clearIdbRequiredReasonForArchWhenNoQuantity(item_part_quantity, request_reasons) {
  const has_upper_idb_parts = item_part_quantity.filter((item) => {
    return item.part_category === 'idb' && item.description.indexOf('Upper') >= 0;
  });
  const has_lower_idb_parts = item_part_quantity.filter((item) => {
    return item.part_category === 'idb' && item.description.indexOf('Lower') >= 0;
  });

  const has_no_upper_idb = has_upper_idb_parts.filter((item) => item.quantity === 0).length === has_upper_idb_parts.length;
  const has_no_lower_idb = has_lower_idb_parts.filter((item) => item.quantity === 0).length === has_lower_idb_parts.length;

  request_reasons.forEach((reason) => {
    if (reason.category === 'idb_required' && (reason.segment_preference === 'arch_segments' || reason.segment_preference === 'individual_teeth_segments')) {
      reason.booleanValue = false;
      reason.arch_segments_options.forEach((arch_segments_option) => {
        if ((has_no_upper_idb && arch_segments_option.title.indexOf('Upper') >= 0) || (has_no_lower_idb && arch_segments_option.title.indexOf('Lower') >= 0)) {
          arch_segments_option.active = false;
        }
      });

      if (has_no_upper_idb && reason.selectedTeeth.length > 0) {
        reason.selectedTeeth = reason.selectedTeeth.filter((t) => t.indexOf('L') >= 0);
      }

      if (has_no_lower_idb && reason.selectedTeeth.length > 0) {
        reason.selectedTeeth = reason.selectedTeeth.filter((t) => t.indexOf('U') >= 0);
      }
    }
  });

  return request_reasons;
}
/**
 * Clears the segmentation selection when no idb tray's are present, if there is one present set default
 *
 * @param {Object} item_part_quantity - The item_part_quantity contains the quanity of each item
 * @param {Object} request_reasons - The request_reasons contains all the reason data for the item request.
 * @return {Object} Returns request_reasons json object back.
 */
function clearIdbRequiredReasonForArchByQuantity(item_part_quantity, request_reasons) {
  const has_idb_parts = item_part_quantity.filter((item) => {
    return item.part_category === 'idb' && item.quantity > 0;
  });

  if (has_idb_parts && has_idb_parts.length === 0) {
    request_reasons.forEach((reason) => {
      if (reason.category === 'idb_required' && (reason.segment_preference === 'arch_segments' || reason.segment_preference === 'individual_teeth_segments')) {
        reason.booleanValue = false;
        reason.arch_segments_options.forEach((arch_segments_option) => {
          arch_segments_option.active = false;
        });
        reason.selectedTeeth = [];
      }
    });
  } else {
    request_reasons.forEach((reason) => {
      if (reason.category === 'idb_required' && (reason.segment_preference === 'arch_segments' || reason.segment_preference === 'individual_teeth_segments')) {
        reason.booleanValue = true;
      }
    });
  }

  return request_reasons;
}

/**
 * Clears the selected teeth when no idb tray's are present
 *
 * @param {Object} request_reasons - The request_reasons contains all the reason data for the item request.
 * @return {Object} Returns request_reasons json object back.
 */
function clearIdbSelectedTeeth(request_reasons) {
  request_reasons.forEach((reason) => {
    if (reason.category === 'idb_required') {
      reason.booleanValue = false;
      reason.selectedTeeth = [];
    }
  });
  return request_reasons;
}

/**
 * Clears single_tooth_selection boolean if there are no teeth selected
 *
 * @param {Object} requestReasons - The request_reasons contains all the reason data for the item request.
 * @return {Object} Returns requestReasons json object back.
 */
function clearSingleToothSelection(requestReasons) {
  requestReasons.forEach((reason) => {
    if (reason.category === 'idb_required') {
      if (!reason.selectedTeeth || reason.selectedTeeth.length === 0) {
        reason.single_tooth_selection = false;
      }
    }
  });
  return requestReasons;
}

/**
 * Appends http as appropriate
 *
 * @param {String} text - raw html from react quill
 * @return {String} Returns update anchor tag with http
 */
function formatHTMLLinks(text) {
  return text.replace('http://', '').replace('https://', '').replace('a href="', 'a href="http://');
}
/**
 * Returns the environment name from hostname
 *
 * @return {String} Returns the environment base on url, if empty consider this as production or unknown
 */
function getEnvName() {
  const hostname = window.location.hostname;
  const is_heroku = hostname.indexOf('.herokuapp.com') >= 0;

  if (is_heroku) {
    const matches = hostname.match(/inbrace-([a-zA-Z\-\d]+)\./);

    if (matches && matches.length && matches.length === 2) {
      return matches[1];
    }
  }

  return '';
}
/**
 * Returns new date with numDays added
 * @param {Date} dateObj - Date Object that needs to be updated
 * @param {Int} numDays - Nuber of days that needs to be added
 * @return {Date} Returns the new date string
 */
function addDays(dateObj, numDays) {
  dateObj.setDate(dateObj.getDate() + numDays);
  return dateObj;
}

/**
 * Draws the profile picture canvas to be displayed on the IPP profile page, with correct positioning
 *
 * @function
 * @param {object} - Instance of class
 * @param {boolean} - Flag indicating if the user is on the edit DSO Doctor account page
 */
function drawProfilePicture(that, is_edit_dso_doctor_account_page) {
  if (that.props.profile_image || that.props.profile_image_dso_doctor) {
    let canvas = that.canvasRef.current;
    if (canvas) {
      let context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      let image = new Image();

      image.src = is_edit_dso_doctor_account_page ? that.props.profile_image_dso_doctor : that.props.profile_image;

      image.onload = function () {
        const profile_picture_width_in_px = that.state.profile_picture_width_in_px;
        const profile_picture_height_in_px = that.state.profile_picture_width_in_px;
        const profile_metadata = is_edit_dso_doctor_account_page ? that.props.profile_metadata_dso_doctor : that.props.profile_metadata;
        const image_crop_info = getImageCropInfo(image, profile_metadata);

        const source_x = image.width * (image_crop_info.x / 100);
        const source_y = image.height * (image_crop_info.y / 100);
        const source_width = image.width * (image_crop_info.width / 100);
        const source_height = image.height * (image_crop_info.height / 100);
        const dest_width = profile_picture_width_in_px;
        const dest_height = profile_picture_height_in_px;
        const dest_x = 0;
        const dest_y = 0;

        context.drawImage(image, source_x, source_y, source_width, source_height, dest_x, dest_y, dest_width, dest_height);
      };
    }
  }
}

/**
 * Draws the logo picture canvas to be displayed on the IPP logo page, with correct positioning
 *
 * @function
 * @param {object} - Instance of class
 * @param {boolean} - Flag indicating if the user is on the edit DSO Doctor account page
 */
function drawLogoPicture(that) {
  if (that.props.logo_image) {
    let canvas = that.canvasRef.current;
    if (canvas) {
      let context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      let image = new Image();

      image.src = that.props.logo_image;

      image.onload = function () {
        const dest_x = 0;
        const dest_y = 0;

        context.drawImage(image, dest_x, dest_y, image.width, image.height, dest_x, dest_y, image.width, image.height);
      };
    }
  }
}

/**
 * Retrieves where the crop should be initially placed.
 *
 * If previous crop info is unavailable, place the crop in the center of the picture
 *
 * @function
 * @param {object} image - Image object
 * @param {object} profile_metadata - Profile picture metadata containing x y coordinates and width/height
 * @return {object} - Object with x y coordinates and width/height of the crop
 */
function getImageCropInfo(image, profile_metadata) {
  let x = profile_metadata.x;
  let y = profile_metadata.y;
  let width = profile_metadata.width;
  let height = profile_metadata.height;

  // Previous crop info is unavailable - place crop in center
  if (width === '' || height === '') {
    const default_crop = getDefaultImageCropInfo(image);
    width = default_crop.width;
    height = default_crop.height;
    x = default_crop.x;
    y = default_crop.y;
  }

  return {
    x: x,
    y: y,
    width: width,
    height: height,
  };
}

/**
 * Retrieves where the crop should be placed (center of the picture) when there is no previously saved positioning
 *
 * @function
 * @param {object} image - Image object
 * @return {object} - Object with x y coordinates and width/height of the crop
 */
function getDefaultImageCropInfo(image) {
  const profile_picture_half_width_in_px = 50;
  const profile_picture_half_height_in_px = 50;
  const half = 2;

  const width_in_px = image.height < image.width ? image.height / 2 : image.width / 2;
  const height_in_px = width_in_px;

  const width = (width_in_px / image.width) * 100;
  const height = (height_in_px / image.height) * 100;

  const x = profile_picture_half_width_in_px - width / half;
  const y = profile_picture_half_height_in_px - height / half;

  return {
    x: x,
    y: y,
    width: width,
    height: height,
  };
}

/**
 * Determines if the mark shipped date is valid
 * @function
 * @param mark_shipped_cases {Array} - list of cases to be mark shipped
 * @param mark_shipped_date {String} - The mark shipped date
 * @return {Boolean} Mark shipped date is valid or invalid
 */
function isValidMarkShippedDate(mark_shipped_cases, mark_shipped_date) {
  if (!isDateOnOrBeforeToday(mark_shipped_date)) {
    return false;
  }
  for (const cases of mark_shipped_cases) {
    if (!cases.id.includes('-IR')) {
      const isBeforeProductionReceivedDate = !isDateOnOrAfterSecondDate(mark_shipped_date, cases.production_received_date);
      if (isBeforeProductionReceivedDate) {
        return false;
      }
    } else {
      const approval_date = moment.tz(cases.approval_date, 'America/Los_Angeles').toISOString();
      const isBeforeApprovalDate = cases.approval_date && !isDateOnOrAfterSecondDate(mark_shipped_date, approval_date);
      if (isBeforeApprovalDate) {
        return false;
      }
    }
  }
  return true;
}

/**
 * Determines if the first date is on or after the second date
 * @function
 * @param date {String} - Date
 * @param date2 {String} - Second Date
 * @return {Boolean} - True or False
 */
function isDateOnOrAfterSecondDate(date, date2) {
  return convertDateToMilliseconds(date) >= convertDateToMilliseconds(date2);
}

/**
 * Determines if the date is on or before today
 * @param {String} date - Date
 * @returns {Boolean} - Whether date is <= today
 */
function isDateOnOrBeforeToday(date) {
  return convertDateToMilliseconds(date) <= convertDateToMilliseconds(new Date());
}
/**
 * Determines if the date is on or after min date
 * @param {String} date - Date
 * @param {String} min_date - Date
 * @returns {Boolean} - Whether date is <= min
 */
const isDateOnOrAfterMinDate = (date, min_date) => {
  if (!isDateValid(date)) return false;
  const entry = Moment(date);
  const is_greater_than_min_date = entry.isSameOrAfter(min_date);
  return is_greater_than_min_date;
};

const isDateValid = (date) => {
  const entry = Moment(date);
  const minimalAcceptableYear = 2000;
  const isDateFormatValid = entry.isValid();
  const isYearAboveMinimum = entry.year() > minimalAcceptableYear;
  return isDateFormatValid && isYearAboveMinimum;
};

/**
 * Determines if different address information (street name, state, zip, etc) was entered when editing the address
 * @function
 * @return {Boolean} True or False depending on if the address information is changed
 */
function addressIsChanged(old_address, new_address) {
  const old_address_line_2 = old_address.address_line_2 ? old_address.address_line_2 : null;
  const new_address_line_2 = new_address.address_line_2 ? new_address.address_line_2 : null;
  if (
    old_address.address_line_1 !== new_address.address_line_1 ||
    old_address_line_2 !== new_address_line_2 ||
    old_address.city !== new_address.city ||
    old_address.state !== new_address.state ||
    old_address.zip !== new_address.zip
  ) {
    return true;
  }
  return false;
}

/**
 * Translates the value of sex into value that would match.
 *
 *@param {String} value - value that needs to be translated.
 * @function
 */
const translateSex = (value) => {
  value = value ? value.toUpperCase() : '';

  if (value && (value === 'M' || value.toLowerCase() === 'male')) {
    return 'Male';
  } else if (value && (value === 'F' || value.toLowerCase() === 'female')) {
    return 'Female';
  } else if (value && (value === 'N' || value.toLowerCase() === 'non-binary')) {
    return 'Non-Binary';
  } else if (value && (value.toLowerCase() === 'unknown' || value.toLowerCase() === 'undisclosed')) {
    return 'Undisclosed';
  } else {
    return '';
  }
};

/**
 * Translates the valid phone number to national format.
 *@function
 *@param {String} phoneNumberString - value that needs to be translated.
 *@param {String} [countryCode='US'] - Country code
 *@returns {String} formatted phone number
 */
const formatPhoneNumber = (phoneNumberString, countryCode = 'US') => {
  try {
    const phoneNumber = parsePhoneNumber(phoneNumberString, countryCode);
    if (countryCode === 'US') {
      return phoneNumber.formatNational();
    }
    return phoneNumber.formatInternational();
  } catch {
    return phoneNumberString;
  }
};

/**
 * Returns current year
 */
function getCurrentYear() {
  const current_date = new Date();
  return current_date.getFullYear();
}

/**
 * Remove duplicates from comma separate string
 * @param {string} str
 * @returns {string}
 */
const removeDuplicatesFromString = (temp_string) => {
  if (temp_string) {
    let temp_list = temp_string.split(', ');
    temp_list = [...new Set(temp_list)];
    return temp_list.join(', ');
  }
  return temp_string;
};

/**
 * Removes duplicates from an array of objects based on the specified property.
 *
 * @param {Array<Object>} array - The array of objects.
 * @param {string} property - The property to check for duplicates.
 * @returns {Array<Object>} - A new array with duplicates removed.
 */
const removeDuplicatesFromArray = (array, property) => {
  const seen = new Set();
  /**
   * Function to determine if an object has a unique property value.
   * @param {Object} obj - The object to check.
   * @returns {boolean} - True if the property value is unique, false otherwise.
   */
  const isUnique = (obj) => {
    const propValue = obj[property];
    if (!seen.has(propValue)) {
      seen.add(propValue);
      return true;
    }
    return false;
  };
  // Using filter and the isUnique function to create a new array with duplicates removed.
  return array.filter(isUnique);
};

/**
 * Checks if the given phone number is valid for a specific country code.
 *
 * @param {string} phoneNumber - The phone number to validate.
 * @param {string} [countryCode='US'] - The country code for the phone number (default is 'US').
 * @returns {boolean} Returns true if the phone number is valid, otherwise false.
 * @function
 */
const isValidPhoneNo = (phoneNumber, countryCode = 'US') => {
  let isValid = false;

  try {
    const parsedNumber = parsePhoneNumber(phoneNumber, countryCode);
    const clearPhoneNumber = phoneNumber.replace(/[^0-9\+]/g, '');
    const isValidInternational = ValidationUtils.validateInternationalPhone(clearPhoneNumber);
    const isValidNumber = parsedNumber && parsedNumber.isValid() && parsedNumber.country === countryCode;

    isValid = isValidInternational && isValidNumber;
  } catch (error) {
    // Do Nothing
  }

  return isValid;
};
/**
 * Check if email is valid
 * @function
 */
const isValidEmail = (value) => {
  return ValidationUtils.isValidEmail(value);
};
/**
 * Check if the url is valid or not
 * @param {string} value
 * @returns {boolean}
 * @function
 */
const isValidUrl = (value) => {
  return ValidationUtils.isValidURL(value);
};

/**
 * Determines if the social media entried is a valid url
 *
 * @function
 * @param {String} value - Contains the url value entered by the user
 * @param {String} mode - Contains the field name
 * @return {Boolean} Returns true if social media url meets all criteria
 */
const isValidSocialMedia = (value, mode) => {
  if (value === undefined || value === null || value === '') return true;

  const social_media = 'https://www.' + mode + '.com/';
  const social_media_min = social_media + 'a';
  const has_minimum_length = value.length >= social_media_min.length;
  const starts_with_social_media = value.substring(0, social_media.length) === social_media;

  return value && has_minimum_length && starts_with_social_media;
};

/**
 * Remove emojis from the string
 * @param {string} str
 * @returns {string}
 */
const removeEmoji = (str) => {
  const regex = emojiRegex();
  str = str.replace(regex, '');
  return str;
};
/**
 * Check if an emoji is present in the string
 * @param {string} str
 * @returns {boolean}
 */
const hasEmoji = (str) => {
  let regex = emojiRegex();
  return regex.test(str);
};
/**
 * Orders an array of tooth numbers
 * @function
 * @param {Array} teeth - Teeth to sort
 * @return {Array} Sorted list
 */
function orderTeeth(teeth) {
  let selected_teeth_set = new Set(teeth);
  let result = [];
  for (const ordered_tooth of ordered_teeth) {
    if (selected_teeth_set.has(ordered_tooth)) {
      result.push(ordered_tooth);
      selected_teeth_set.delete(ordered_tooth);
    }
  }
  return result;
}

/**
 * Gets unselected teeth
 * @function
 * @param {Array} selected_teeth - Selected bracket teeth
 * @return {Array} Unselected teeth
 */
function getUnselectedTeeth(selected_teeth) {
  let unselected_teeth = [];
  for (const tooth of ordered_teeth) {
    if (selected_teeth.indexOf(tooth) === -1) {
      unselected_teeth.push(tooth);
    }
  }
  return unselected_teeth;
}

/**
 * Removes teeth from opposing arch
 * @function
 * @param {String} treatment_arch - Current arch
 * @param {Array} selected_teeth - Selected teeth
 * @return {Array} Array without opposing teeth
 */
function removeOpposingTeeth(treatment_arch, selected_teeth) {
  if (treatment_arch !== 'both') {
    return selected_teeth.filter((tooth) => tooth.startsWith(treatment_arch[0].toUpperCase()));
  }
  return selected_teeth;
}

/**
 * Orders an array of gen 2.0 wires
 * @function
 * @param {Array} wires - Wires to sort
 * @return {Array} Sorted list
 */
function orderGen2Wires(wires) {
  let selected_wires_set = new Set(wires);
  let result = [];
  for (const ordered_wire of ordered_gen_2_wires) {
    if (selected_wires_set.has(ordered_wire)) {
      result.push(ordered_wire);
      selected_wires_set.delete(ordered_wire);
    }
  }
  return result;
}

/**
 * Check if user input is a valid date of birth
 * @function
 * @param {String} date - Wires to sort
 * @return {Boolean} True if valid false if not valid (no year ealier than 1900)
 */
function validDOB(inputDOB) {
  let isValidDate = true;
  let dateMin = Date.parse('1900-01-01');
  let dateNow = Date.now();
  let inputDate = Date.now();
  if (inputDOB === '') {
    return false;
  }
  if (inputDOB) {
    inputDate = Date.parse(inputDOB);
    if (inputDate > dateNow || inputDate < dateMin) {
      isValidDate = false;
    }
  }
  return isValidDate;
}

/**
 * Redirect to case detail page
 * @function
 * @param {String} case_id - Case_id
 * @param {String} path - History path Ex. this.props.history.location.pathname
 * @param {Boolean} redirect - Should redirect to the new url
 * @returns {String} - New url
 */
function redirectToCaseDetail(case_id, path, redirect) {
  const index = path.lastIndexOf('/');
  const new_path = path.substring(0, index + 1) + case_id;
  return redirect ? (window.location.href = new_path) : new_path;
}

/**
 * Check if case is a de
 * @function
 * @param {String} case_id Case id
 * @return {Boolean} True if case is de
 */
function isCaseDE(case_id) {
  return case_id && (case_id.indexOf('-DE') >= 0 || case_id.indexOf('-R') >= 0);
}

/**
 * Update parent and local state
 * @param {string} id - Id of selected photo
 * @param {string} action - Type of user action
 * @param {Object} props - Props
 */
function onImageToolAction(id, action, props) {
  const no_id = id && typeof id === 'string' ? id.includes('-') : false;
  let file_record = props.record_states;
  let index = no_id ? id.split('-').pop() : file_record?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);
  const file_folder = index < 0 ? 'photos' : file_record[index].file_type;
  props.onRecordViewerAction(id, action);
  if (index >= 0 && file_record[index].state && props.updateRecordState) {
    let new_state = getNewRecordState(action, file_record[index]?.state);
    props.updateRecordState(id, new_state, file_folder);
  }
}
/**
 * Convert record states to css styles
 * @param {Object} record_states - Image record state
 */
function recordStatesToCssStyle(record_states, ignore_zoom = false) {
  const { scale, flipH, flipV, rotate } = record_states;
  const scaleX = flipH ? -1 : 1;
  const scaleY = flipV ? -1 : 1;
  let scale_val = ignore_zoom ? `scaleX(${scaleX}) scaleY(${scaleY})` : `scale(${scale}) scaleX(${scaleX}) scaleY(${scaleY})`;

  let style = `${scale_val} rotate(${rotate}deg)`;

  return style;
}
/**
 * Get the list of disabled tooth
 * @param {Object} item_parts_quantity - Contains the json of the ireq parts
 */
function getDisabledListOfTeethByIDBTray(item_parts_quantity) {
  const has_upper_idb_tray =
    item_parts_quantity.filter(
      (part) =>
        part.part_category === 'idb' &&
        (part.description.indexOf('Upper IDB Tray') >= 0 ||
          part.description.indexOf('IDB Tray - Upper') >= 0 ||
          part.description.indexOf('Upper DE IDB Tray') >= 0) &&
        part.quantity > 0
    ).length > 0;
  const has_lower_idb_tray =
    item_parts_quantity.filter(
      (part) =>
        part.part_category === 'idb' &&
        (part.description.indexOf('Lower IDB Tray') >= 0 ||
          part.description.indexOf('IDB Tray - Lower') >= 0 ||
          part.description.indexOf('Lower DE IDB Tray') >= 0) &&
        part.quantity > 0
    ).length > 0;
  let disabledTooth = [];

  if (!has_upper_idb_tray) {
    disabledTooth = ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'];
  }

  if (!has_lower_idb_tray) {
    disabledTooth = disabledTooth.concat(['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1', 'LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8']);
  }

  return disabledTooth;
}

const quillEmptyTextRegexPattern = /<p><br><\/p>|<p class="ql-indent-\d+"><br><\/p>/;

const HTMLTagsRegex = /(<([^>]+)>)/gi;

function removeHTMLTags(text) {
  return text.replace(HTMLTagsRegex, '');
}

/**
 * Check if the text is empty, empty ul/li tags are considered text
 * @param {String} text - Text
 * @returns {Boolean} - True if empty
 */
function isEmptyQuillText(text) {
  return quillEmptyTextRegexPattern.test(text);
}

function getQuillHTMLTextContent(text) {
  return removeHTMLTags(text).trim();
}

/**
 * Check if the text is empty, ignore all the html tags including ul/li tags
 * @param {String} text - Text
 * @returns {Boolean} - True if empty
 */
function isEmptyQuillTextV2(text) {
  return getQuillHTMLTextContent(text).length === 0;
}

/**
 * Get just the text content length from the quill html
 *
 * Uses isEmptyQuillTextV2() function to check if the content is empty
 * Removes all html tags and line breaks from the content
 *
 * @param {String} content - Text
 * @returns {Number} - Length of the text
 */
function getQuillHTMLTextContentLength(content) {
  return content && !isEmptyQuillTextV2(content) ? removeLineBreaks(getQuillHTMLTextContent(content)).length : 0;
}

export {
  addDays,
  addressIsChanged,
  awsObjectConstriant,
  calculateAge,
  checkToken,
  checkdaysdiff,
  clearIdbRequiredReason,
  clearIdbRequiredReasonForArchByQuantity,
  clearIdbRequiredReasonForArchWhenNoQuantity,
  clearIdbSelectedTeeth,
  clearSingleToothSelection,
  computeDaysPast,
  configLightBox,
  convertDate,
  convertDateToMilliseconds,
  convertDateWithFormat,
  convertInputDate,
  convertSex,
  downloadFile,
  drawLogoPicture,
  drawProfilePicture,
  dsoDraftDisabled,
  firstLetterCapital,
  formatState,
  formatCaseId,
  formatHTMLLinks,
  formatPhoneNumber,
  formatShipping,
  generateId,
  generatePass,
  getActiveAddress,
  getBaseCaseId,
  getBasedFADURLBySite,
  getCookie,
  getCurrentYear,
  getDefaultImageCropInfo,
  getDisabledListOfTeethByIDBTray,
  getDoctorIdFromPath,
  getEnvName,
  getImageCropInfo,
  getNextActiveAddressId,
  getQuillHTMLTextContent,
  getQuillHTMLTextContentLength,
  getRefinementSuffix,
  getReportFileName,
  getRootPath,
  getStlFileUrl,
  getUnselectedTeeth,
  hasEmoji,
  isActiveAddressById,
  isCaseDE,
  isDSOAssignedLoadable,
  isDateOnOrAfterMinDate,
  isDateOnOrAfterSecondDate,
  isDateOnOrBeforeToday,
  isDateValid,
  isEmptyQuillText,
  isEmptyQuillTextV2,
  isHTML,
  isRoleAllowedToAssign,
  isValidEmail,
  isValidMarkShippedDate,
  isValidPhoneNo,
  isValidSocialMedia,
  isValidUrl,
  makeUpperCaseAfterCommas,
  modalClear,
  modalClose,
  npiNumberChar,
  onDownload,
  onImageToolAction,
  onThumbnailLoad,
  onThumbnailStlLoad,
  oneLineBreakLimited,
  orderGen2Wires,
  orderTeeth,
  patientChartTextConstraint,
  phoneNumberChar,
  phoneNumberCharInternational,
  preventSpacesInText,
  quillEmptyTextRegexPattern,
  recordStatesToCssStyle,
  redirectToCaseDetail,
  removeCaseIdInitialNumber,
  removeDuplicatesFromString,
  removeDuplicatesFromArray,
  removeEmoji,
  removeIreqIdInitialNumber,
  removeLineBreaks,
  removeNumberChar,
  removeOpposingTeeth,
  removeSpaces,
  removeSpecialChar,
  resetTokenHeader,
  safeSearchChar,
  safeSearchCharPlusEmail,
  setCookie,
  setTokenHeader,
  sortImageFiles,
  sortRawFiles,
  targetDaysNumberChar,
  textFieldCheck,
  textFieldLimited,
  translateSex,
  validDOB,
  zipNumberChar,
};
