import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../modal/modal';
import { userHasPermission } from '../../common/permission';
import { isCaseBlocked } from '../../common/helpers';
import { removeCaseIdInitialNumber } from '../../common/functions';
import SessionExpire from '../../common/session_expire';
import Datalist from '../../components/form/list/datalist';

// Redux
import {
  getStage1Questions,
  getIsEditModeStage1,
  getIsEditNotesMode,
  getChangeTreatmentStage1Modal,
  getTeethSelectorModals,
  getWireConfig,
  getStage1IncompleteError,
  getStage1SessionExpired,
} from '../../redux/reducers/bpp/production_tx_guide/stage_1';
import { getSmileDesignQuestions } from '../../redux/reducers/bpp/production_tx_guide/smile_design';
import {
  setEditModeStage1,
  onChangeInTreatmentStage1,
  openChangeTreatmentStage1Modal,
  closeChangeTreatmentStage1Modal,
  onConfirmButtonClickChangeTreatmentStage1Modal,
  openStage1TeethSelectorModal,
  closeStage1TeethSelectorModal,
  onConfirmButtonClickTeethSelectorModal,
  onStage1NoteChange,
  onEditStage1TxGuideCancel,
  saveStage1ProductionTxGuideSelections,
  onChangeIdbTray,
  onWireSelect,
  onWireSelectGen2,
  openStage1TxGuidePdfModal,
  setRequestedTreatmentStage1,
  setTreatmentPlanRevision,
  setEditNotesMode,
} from '../../redux/actions/bpp/production_tx_guide/stage_1';
import { getIfuState } from '../../redux/reducers/ifu/ifu_state';
import { getIsAfter3420Launch } from '../../redux/reducers/bpp/production_tx_guide/production_tx_guide';
import { getIsHistoricalSingleToothIdb } from '../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';
import { fetchSingleToothIdb } from '../../redux/actions/bpp/single_tooth_idb/single_tooth_idb';

/**
 * Production tx guide form for Stage 1 Appliance Design section
 * @component
 * @alias Stage1ProductionTxGuide
 * @category BPP
 */
class Stage1ProductionTxGuide extends Component {
  componentDidMount() {
    this.props.setRequestedTreatmentStage1(this.props.requested_treatment);
    if (this.props.submission_process && this.props.submission_process.treatment_plan_revision) {
      this.props.setTreatmentPlanRevision(this.props.submission_process.treatment_plan_revision);
    }
    this.props.setEditModeStage1(!this.props.is_tab_view);
    this.props.setEditNotesMode(!this.props.is_tab_view);
  }

  componentDidUpdate() {
    this.props.fetchSingleToothIdb(this.props.case_id);
  }

  /**
   * Displays Change in Treatment section
   * @function
   * @return {JSX} JSX for Change in Treatment
   */
  displayChangeInTreatment() {
    return (
      <React.Fragment>
        <div className={`question-row bold-text${this.props.incomplete_error.no_treatment ? ' location__incomplete' : ''}`}>
          Change in Treatment{this.hasNoSmileDesignTreatmentSelectionDE() ? '*' : ''}:
        </div>
        <div className="guide-item-row">
          <input
            type="checkbox"
            className="pointer"
            checked={this.props.stage_1_questions.change_treatment_stage_1}
            onChange={() => this.props.onChangeInTreatmentStage1(this.props.is_tab_view)}
            disabled={!this.props.is_edit_mode_stage_1}
          />
          Yes. Doctor consent required.
          {this.props.stage_1_questions.change_treatment_stage_1 && this.displayChangeInTreatmentSelection()}
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays selection area for Change in Treatment section
   * @function
   * @return {JSX} JSX for selection
   */
  displayChangeInTreatmentSelection() {
    return (
      <div className="location">
        <span
          className={`bracket-link guide-item-row${this.props.incomplete_error.change_treatment ? ' location__incomplete' : ''}${
            !this.props.is_edit_mode_stage_1 ? ' location__disabled' : ''
          }`}
          onClick={() => this.props.openChangeTreatmentStage1Modal(this.props.cad_status)}
        >
          Specify Change in Treatment*
        </span>
        :
        <div className="guide-item-row">
          Arch to Treat:{' '}
          {this.props.stage_1_questions.change_treatment_stage_1_selections.arch_to_treat
            ? this.props.stage_1_questions.change_treatment_stage_1_selections.arch_to_treat
            : 'N/A'}
        </div>
        <div className="guide-item-row">
          Teeth Excluded from Treatment:{' '}
          {this.props.stage_1_questions.change_treatment_stage_1_selections.no_bracket_bonded &&
          this.props.stage_1_questions.change_treatment_stage_1_selections.no_bracket_bonded.length > 0
            ? this.props.stage_1_questions.change_treatment_stage_1_selections.no_bracket_bonded.join(', ')
            : 'N/A'}
        </div>
      </div>
    );
  }

  /**
   * Displays reposition section
   * @function
   * @return {JSX} JSX for Reposition section
   */
  displayReposition() {
    return (
      <React.Fragment>
        <div className="question-row bold-text">Reposition using single-tooth IDB tray:</div>
        <div>
          <span
            className={`bracket-link guide-item-row${this.isIfuQuestionDisabled() ? ' location__disabled' : ''}`}
            onClick={() => this.props.openStage1TeethSelectorModal('repositioned', this.props.case_id.includes('-DE'))}
          >
            Specify location:
          </span>
          <span>
            {this.props.stage_1_questions.stage_1_selected_teeth.repositioned
              ? this.props.stage_1_questions.stage_1_selected_teeth.repositioned.join(', ')
              : 'N/A'}
          </span>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays Bond new bracket section
   * @function
   * @return {JSX} JSX for Bond new bracket section
   */
  displayNewBracketBonded() {
    const header = this.props.is_historical_single_tooth_idb ? 'Bond new bracket:' : 'Bond new bracket (Single-Tooth):';
    return (
      <React.Fragment>
        {' '}
        <div className="question-row bold-text">{header}</div>
        <div>
          <span
            className={`bracket-link guide-item-row${this.isIfuQuestionDisabled() ? ' location__disabled' : ''}`}
            onClick={() => this.props.openStage1TeethSelectorModal('new', this.props.case_id.includes('-DE'))}
          >
            Specify location:
          </span>
          <span>{this.props.stage_1_questions.stage_1_selected_teeth.new ? this.props.stage_1_questions.stage_1_selected_teeth.new.join(', ') : 'N/A'}</span>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays Brackets not included section
   * @function
   * @return {JSX} JSX for Brackets not included section
   */
  displayNotIncluded() {
    return (
      <React.Fragment>
        <div className="question-row bold-text">
          No bracket included in the initial IDB tray. {this.props.is_historical_single_tooth_idb ? 'Direct' : 'Indirect'} bond later:
        </div>
        <div>
          <span
            className={`bracket-link guide-item-row${this.isIfuQuestionDisabled() ? ' location__disabled' : ''}`}
            onClick={() => this.props.openStage1TeethSelectorModal('not_included', this.props.case_id.includes('-DE'))}
          >
            Specify location:
          </span>
          <span>
            {this.props.stage_1_questions.stage_1_selected_teeth.not_included
              ? this.props.stage_1_questions.stage_1_selected_teeth.not_included.join(', ')
              : 'N/A'}
          </span>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays Collision section
   * @function
   * @return {JSX} JSX for Collision section
   */
  displayCollision() {
    return (
      <React.Fragment>
        <div className="question-row bold-text">
          Expected {this.props.is_historical_single_tooth_idb ? 'collision' : 'interference'} with opposing teeth/bracket/Smartwire:
        </div>
        <div>
          <span
            className={`bracket-link guide-item-row${this.isIfuQuestionDisabled() ? ' location__disabled' : ''}`}
            onClick={() => this.props.openStage1TeethSelectorModal('collisions', this.props.case_id.includes('-DE'))}
          >
            Specify location:
          </span>
          <span>
            {this.props.stage_1_questions.stage_1_selected_teeth.collisions ? this.props.stage_1_questions.stage_1_selected_teeth.collisions.join(', ') : 'N/A'}
          </span>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays Offset Bracket Selection Section
   * @returns {JSX} JSX for Offset Bracket Selection
   */
  displayOffsetBracket = () => {
    return !this.props.is_historical_single_tooth_idb ? (
      <>
        <div className="question-row bold-text">Offset bracket. Reposition later:</div>
        <div>
          <span
            className={`bracket-link guide-item-row${this.isIfuQuestionDisabled() ? ' location__disabled' : ''}`}
            onClick={() => this.props.openStage1TeethSelectorModal('offset', this.props.case_id.includes('-DE'))}
          >
            Specify location:
          </span>
          <span>
            {this.props.stage_1_questions.stage_1_selected_teeth.offset ? this.props.stage_1_questions.stage_1_selected_teeth.offset.join(', ') : 'N/A'}
          </span>
        </div>
      </>
    ) : null;
  };

  /**
   * Displays Arch Segment selection section
   * @returns {JSX} JSX for arch segment selection
   */
  displayBondNewArchSegment = () => {
    return this.props.gen_2 ? (
      <React.Fragment>
        {' '}
        <div className="question-row bold-text">Bond new bracket (Arch Segment):</div>
        <div>
          <span
            className={`bracket-link guide-item-row${this.isIfuQuestionDisabled() ? ' location__disabled' : ''}`}
            onClick={() => this.props.openStage1TeethSelectorModal('arch', this.props.case_id.includes('-DE'))}
          >
            Specify location:
          </span>
          <span>{this.props.stage_1_questions.stage_1_selected_teeth.arch ? this.props.stage_1_questions.stage_1_selected_teeth.arch.join(', ') : 'N/A'}</span>
        </div>
      </React.Fragment>
    ) : null;
  };

  /**
   * Displays Upper and Lower IDB Tray selections
   * @function
   * @return {JSX} JSX for IDB section
   */
  displayIDB() {
    const arch = this.getTreatmentArch();
    return (
      <React.Fragment>
        <div className="question-row bold-text">IDB Tray:</div>
        {(arch === 'upper' || arch === 'both') && this.displayIDBTray('upper_idb_tray')}
        {(arch === 'lower' || arch === 'both') && this.displayIDBTray('lower_idb_tray')}
      </React.Fragment>
    );
  }

  /**
   * Displays single IDB tray selection
   * @function
   * @return {JSX} JSX for tray selection
   */
  displayIDBTray(type) {
    return (
      <div className="guide-item-row">
        <input
          type="checkbox"
          className="pointer"
          checked={this.props.stage_1_questions[type]}
          onChange={() => this.props.onChangeIdbTray(this.props.is_tab_view, type)}
          disabled={this.isIfuQuestionDisabled()}
        />
        {type.includes('upper') ? 'Upper' : 'Lower'} DE IDB Tray
      </div>
    );
  }

  /**
   * Displays wire selection section
   * @function
   * @return {JSX} JSX for wire selections
   */
  displayWireSelections() {
    const arch = this.getTreatmentArch();
    return (
      <React.Fragment>
        <div className={`question-row bold-text${this.props.incomplete_error.wire_selections ? ' location__incomplete' : ''}`}>Wire*:</div>
        {arch === 'upper' || arch === 'both' ? (this.props.gen_2 ? this.displayWireGen2('Upper') : this.displayWire('Upper')) : null}
        {arch === 'lower' || arch === 'both' ? (this.props.gen_2 ? this.displayWireGen2('Lower') : this.displayWire('Lower')) : null}
      </React.Fragment>
    );
  }

  /**
   * Gets treatment arch from change in treatment/requested treatment/tx plan
   * @function
   * @return {String} Treatment arch
   */
  getTreatmentArch() {
    if (this.props.stage_1_questions.change_treatment_stage_1 && this.props.stage_1_questions.change_treatment_stage_1_selections.treatment_arch) {
      return this.props.stage_1_questions.change_treatment_stage_1_selections.treatment_arch;
    }
    if (
      this.props.smile_design_questions.change_treatment_smile_design &&
      this.props.smile_design_questions.change_treatment_smile_design_selections.treatment_arch
    ) {
      return this.props.smile_design_questions.change_treatment_smile_design_selections.treatment_arch;
    }
    if (this.props.smile_design_questions.requested_treatment_selections.treatment_arch) {
      return this.props.smile_design_questions.requested_treatment_selections.treatment_arch;
    }
    return this.props.requested_treatment.treatment_arch;
  }

  /**
   * Displays single wire selection
   * @function
   * @return {JSX} JSX for wire selection
   */
  displayWire(type) {
    return (
      <div className="guide-item-row">
        <span className="wire-label">{type} Wire:</span>
        <span className="ifu-options wire">
          <select
            className="select-status select-wire"
            value={this.getWireSelection(type)}
            onChange={(e) => this.props.onWireSelect(this.props.is_tab_view, e.target.value, type)}
            disabled={this.isIfuQuestionDisabled()}
          >
            <option value="both">{type === 'Upper' ? '.016 and .014' : '.016 (Lower/Lower St) and .014'}</option>
            <option value="16">{type === 'Upper' ? '.016 only' : '.016 only (Lower/Lower St)'}</option>
            <option value="14">.014 only</option>
            <option value="none">None</option>
          </select>
        </span>
      </div>
    );
  }

  /**
   * Displays wire selection for Gen 2.0 cases
   * @function
   * @return {JSX} JSX for wire selection
   */
  displayWireGen2(type) {
    const upper_wires = ['Upper DE Smartwire 1', 'Upper DE Smartwire 2'];
    const lower_wires = ['Lower DE Smartwire 1', 'Lower DE Smartwire 2'];

    let selected_wires = this.props.stage_1_questions.wire_selections.filter((wire) => {
      return wire.includes(type);
    });

    return (
      <div className="guide-item-row">
        <div className="gen-2-wires">
          <span className="wire-label">{type} Wire:</span>
          <span className="ifu-options wire">
            <Datalist
              borderClassName="select-border"
              className="form-control datalist-select-option-medium datalist-wires"
              hideDefaultOption={true}
              multiple={true}
              name={`${type.toLowerCase()}_wires${this.props.is_tab_view ? '_tab' : ''}`}
              value={selected_wires}
              change={(e) => this.onWireSelectGen2(e, type)}
              item={`${type.toLowerCase()}_wires${this.props.is_tab_view ? '_tab' : ''}`}
              list={type === 'Upper' ? upper_wires : lower_wires}
              title="None"
              max_count={2}
              disabled={true}
            />
          </span>
        </div>
      </div>
    );
  }

  onWireSelectGen2 = (event, type) => {
    let selected_wires = [];
    if (event.target.selectedIndex > -1 && event.target.selectedOptions && event.target.selectedOptions.length > 0) {
      for (var i = 0; i < event.target.selectedOptions.length; i++) {
        const selected_option = event.target.selectedOptions[i];
        if (selected_option && selected_option.value) {
          selected_wires.push(selected_option.value);
        }
      }
    }
    this.props.onWireSelectGen2(selected_wires, type, this.props.is_tab_view);
  };

  /**
   * Displays Notes section
   * @function
   * @return {JSX} JSX for notes section
   */
  displayNotes() {
    return (
      <React.Fragment>
        {' '}
        <div className="question-row bold-text">Notes:</div>
        <div>
          <textarea
            className="form-control"
            placeholder="Enter notes here..."
            rows={4}
            maxLength={750}
            onChange={this.props.onStage1NoteChange}
            value={this.props.stage_1_questions.stage_1_notes}
            disabled={!this.props.is_edit_mode_stage_1 && !this.props.is_edit_notes_mode}
          />
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays change in treatment teeth selector modal
   * @function
   * @return {JSX} JSX for modal
   */
  displayChangeTreatmentModal() {
    return (
      <Modal
        theme="bpp"
        preset="teeth-chart-8"
        modal_size_class="modal-lg"
        header_text={`Change in Treatment - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        confirm_btn_text="Apply"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickChangeTreatmentStage1Modal(this.props.is_tab_view)}
        close_btn_text="Cancel"
        onCloseButtonClick={this.props.closeChangeTreatmentStage1Modal}
      />
    );
  }

  /**
   * Displays various teeth selector modals
   * @function
   * @param {String} modal_type - Modal type
   * @return {JSX} JSX for modal
   */
  displayTeethSelectorModal(modal_type) {
    return (
      <Modal
        theme="bpp"
        preset="teeth-chart-8"
        modal_size_class="modal-lg"
        header_text={`${this.getTeethSelectorModalHeader(modal_type)} - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        confirm_btn_text="Apply"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickTeethSelectorModal(this.props.is_tab_view, modal_type)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.closeStage1TeethSelectorModal(modal_type)}
      />
    );
  }

  /**
   * Gets header to display on teeth selector modal
   * @function
   * @param {String} modal_type - Modal type
   * @return {String} Header
   */
  getTeethSelectorModalHeader(modal_type) {
    const is_wip = this.props.is_historical_single_tooth_idb;
    const collisions_header = `Expected ${is_wip ? 'Collision' : 'Interference'} with Opposing Teeth/Bracket/Smartwire`;
    const not_included_header = `No Bracket Included in The Initial IDB tray. ${is_wip ? 'Direct' : 'Indirect'} Bond Later`;

    const headers = {
      not_included: not_included_header,
      collisions: collisions_header,
      repositioned: 'Reposition Using Single-Tooth IDB Tray',
      offset: 'Offset Bracket. Reposition Later',
      new: 'Bond New Bracket (Single-Tooth)',
      arch: 'Bond New Bracket (Arch Segments)',
    };
    return headers[modal_type];
  }

  /**
   * Displays buttons under questions in tab view
   * @function
   * @return {JSX} JSX for tab buttons
   */
  displayTabButtons() {
    return (this.props.is_edit_mode_stage_1 || this.props.is_edit_notes_mode) &&
      this.props.case_status !== 'Quality Control Review' &&
      !isCaseBlocked(this.props.case_status) ? (
      <React.Fragment>
        {this.props.incomplete_error.change_treatment && <div className="incomplete-error">Please complete the required field(s) marked in red.</div>}
        {this.props.incomplete_error.wire_selections && <div className="incomplete-error">Please select at least 1 wire type.</div>}
        <div className="button-panel">
          <button type="button" className="btn btn-light" onClick={() => this.props.saveStage1ProductionTxGuideSelections(this.props.gen_2)}>
            Save
          </button>
          <button type="button" className="btn btn-light btn-cancel" onClick={this.props.onEditStage1TxGuideCancel}>
            Cancel
          </button>
        </div>
      </React.Fragment>
    ) : (
      <div className="button-panel">
        <button type="button" className="btn btn-light" onClick={() => this.props.openStage1TxGuidePdfModal(this.props.case_id)}>
          Preview Form
        </button>
        {this.canEditQuestions() && (
          <button type="button" className="btn btn-light" onClick={() => this.setEditMode()}>
            Edit
          </button>
        )}
      </div>
    );
  }

  /**
   * Set appropriate edit mode depending on case status
   */
  setEditMode = () => {
    this.isEditNotesStage() ? this.props.setEditNotesMode(true) : this.props.setEditModeStage1(true);
  };

  /**
   * Checks if a case has run Ifu Generation
   * @returns {boolean} Ifu Step Status
   */
  ifuGenerationInProgress = () => {
    if (this.props.ifu_state && (this.props.ifu_state.ifu_screen === 'in-progress' || this.props.ifu_state.ifu_screen === 'result')) return true;
    return false;
  };

  /**
   * Checks if the edit mode should be limited to notes edit
   * @returns {boolean} edit notes status
   */
  isEditNotesStage = () => {
    const gen2 = this.props.gen_2;
    if (!gen2 && this.ifuGenerationInProgress()) {
      return true;
    }
    if (gen2 && this.props.cad_status && ['Stage II', 'Stage III'].includes(this.props.cad_status)) {
      return true;
    }
    return false;
  };

  canEditQuestions() {
    return (
      this.props.case_status !== 'Quality Control Review' &&
      this.props.case_status !== '​STATUS_DOCTOR_APPROVAL' &&
      !isCaseBlocked(this.props.case_status) &&
      userHasPermission('APPLIANCE_DESIGN_STAGE_1_COMPLETE', this.props.user_roles_and_permissions.permissions)
    );
  }

  /**
   * Gets wire selection to display
   * @function
   * @param {String} type - Upper or lower
   * @return {String} Wire selection
   */
  getWireSelection(type) {
    const wire = this.props.stage_1_questions.wire_selections.filter(function (w) {
      return w.indexOf(type) !== -1;
    });
    const wire_config = type === 'Upper' ? this.props.wire_config.upper : this.props.wire_config.lower;
    return Object.keys(wire_config).find((key) => JSON.stringify(wire_config[key]) === JSON.stringify(wire));
  }

  isIfuQuestionDisabled() {
    return (
      !this.props.skip_production_tx_guide &&
      (!this.props.is_edit_mode_stage_1 ||
        (this.props.case_id.includes('-DE') &&
          (this.props.stage_1_questions.change_treatment_stage_1
            ? !this.props.stage_1_questions.change_treatment_stage_1_selections.selected_teeth
            : !this.props.stage_1_questions.change_treatment_stage_1) &&
          this.hasNoSmileDesignTreatmentSelectionDE()))
    );
  }

  hasNoSmileDesignTreatmentSelectionDE() {
    return (
      this.props.case_id.includes('-DE') &&
      !this.props.smile_design_questions.change_treatment_smile_design.selected_teeth &&
      (this.props.submission_process.treatment_plan_revision === 'DE3.0'
        ? false
        : !this.props.smile_design_questions.requested_treatment_selections.selected_teeth)
    );
  }

  isWireSelectedGen2(wire, wire_type) {
    if (
      wire.includes('None') &&
      this.props.stage_1_questions.wire_selections.filter((wire) => {
        return wire.includes(wire_type);
      }).length === 0
    ) {
      return true;
    }
    if (this.props.stage_1_questions.wire_selections.indexOf(`${wire_type} ${wire}`) !== -1) {
      return true;
    }
    return false;
  }

  render() {
    const isDe = this.props.case_id.includes('-DE');
    return (
      <React.Fragment>
        <div className="question-container">
          <div className={`${this.props.className ? this.props.className : ''}${!this.props.gen_2 || this.props.is_tab_view ? ' process-box' : 'test'} design`}>
            {this.props.gen_2 ? <>Answer the following questions for the Production Treatment Guide:</> : null}
            {((!this.props.skip_production_tx_guide &&
              userHasPermission('APPLIANCE_DESIGN_STAGE_1_COMPLETE', this.props.user_roles_and_permissions.permissions)) ||
              this.props.is_tab_view) &&
              this.displayChangeInTreatment()}
            {isDe && (
              <React.Fragment>
                {this.displayReposition()}
                {!this.props.is_historical_single_tooth_idb && this.displayBondNewArchSegment()}
                {this.displayNewBracketBonded()}
              </React.Fragment>
            )}
            {this.displayNotIncluded()}
            {!isDe && (
              <>
                {this.displayCollision()}
                {this.displayOffsetBracket()}
              </>
            )}
            {isDe && (
              <React.Fragment>
                {this.props.is_after_3420_launch && this.displayIDB()}
                {!this.props.gen_2 && this.displayWireSelections()}
              </React.Fragment>
            )}
            {((!this.props.skip_production_tx_guide &&
              userHasPermission('APPLIANCE_DESIGN_STAGE_1_COMPLETE', this.props.user_roles_and_permissions.permissions)) ||
              this.props.is_tab_view) &&
              this.displayNotes()}
            {this.props.change_treatment_stage_1_modal && this.displayChangeTreatmentModal()}
            {this.props.teeth_selector_modals.arch && this.displayTeethSelectorModal('arch')}
            {this.props.teeth_selector_modals.not_included && this.displayTeethSelectorModal('not_included')}
            {this.props.teeth_selector_modals.collisions && this.displayTeethSelectorModal('collisions')}
            {this.props.teeth_selector_modals.repositioned && this.displayTeethSelectorModal('repositioned')}
            {this.props.teeth_selector_modals.offset && this.displayTeethSelectorModal('offset')}
            {this.props.teeth_selector_modals.new && this.displayTeethSelectorModal('new')}
          </div>
        </div>
        {this.props.is_tab_view && this.displayTabButtons()}
        {this.props.session_expired && <SessionExpire />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stage_1_questions: getStage1Questions(state),
    is_edit_mode_stage_1: getIsEditModeStage1(state),
    is_edit_notes_mode: getIsEditNotesMode(state),
    change_treatment_stage_1_modal: getChangeTreatmentStage1Modal(state),
    teeth_selector_modals: getTeethSelectorModals(state),
    incomplete_error: getStage1IncompleteError(state),
    wire_config: getWireConfig(state),
    smile_design_questions: getSmileDesignQuestions(state),
    ifu_state: getIfuState(state),
    is_after_3420_launch: getIsAfter3420Launch(state),
    session_expired: getStage1SessionExpired(state),
    is_historical_single_tooth_idb: getIsHistoricalSingleToothIdb(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSingleToothIdb: fetchSingleToothIdb,
      setEditModeStage1: setEditModeStage1,
      setEditNotesMode: setEditNotesMode,
      onChangeInTreatmentStage1: onChangeInTreatmentStage1,
      openChangeTreatmentStage1Modal: openChangeTreatmentStage1Modal,
      closeChangeTreatmentStage1Modal: closeChangeTreatmentStage1Modal,
      onConfirmButtonClickChangeTreatmentStage1Modal: onConfirmButtonClickChangeTreatmentStage1Modal,
      onStage1NoteChange: onStage1NoteChange,
      onEditStage1TxGuideCancel: onEditStage1TxGuideCancel,
      saveStage1ProductionTxGuideSelections: saveStage1ProductionTxGuideSelections,
      openStage1TeethSelectorModal: openStage1TeethSelectorModal,
      closeStage1TeethSelectorModal: closeStage1TeethSelectorModal,
      onConfirmButtonClickTeethSelectorModal: onConfirmButtonClickTeethSelectorModal,
      onChangeIdbTray: onChangeIdbTray,
      onWireSelect: onWireSelect,
      openStage1TxGuidePdfModal: openStage1TxGuidePdfModal,
      setRequestedTreatmentStage1: setRequestedTreatmentStage1,
      setTreatmentPlanRevision: setTreatmentPlanRevision,
      onWireSelectGen2: onWireSelectGen2,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Stage1ProductionTxGuide);
