import './wire_selection_review_confirm.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WorkOrderRef from '../../workorder_reference/work_order_reference';
import { sortWires } from './wire_selection_review_confirm';
import { onDownload, removeCaseIdInitialNumber } from '../../../common/functions';
import { getCustomWoFileNameError } from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import IfuRegeneration from '../cad/ifu/ifu_regeneration';
import { getIfuState } from '../../../redux/reducers/ifu/ifu_state';

const END_DATE = '2024-03-31T23:59:59';

const custom_part_num = {
  lower_specialty: {
    name: 'Gen 2.0, Low, SW0 Custom, Tumb',
    part_no: '40351-05',
  },
  upper_specialty: {
    name: 'Gen 2.0, Up, SW0 Custom, Tumb',
    part_no: '40352-05',
  },
  lower_initial: {
    name: 'Gen 2.0, Low, SW1 Custom, Tumb',
    part_no: '40375-05',
  },
  upper_initial: {
    name: 'Gen 2.0, Up, SW1 Custom, Tumb',
    part_no: '40376-05',
  },
  lower_intermediate: {
    name: 'Gen 2.0, Low, SW2 Custom, Tumb',
    part_no: '40377-05',
  },
  upper_intermediate: {
    name: 'Gen 2.0, Up, SW2 Custom, Tumb',
    part_no: '40378-05',
  },
  lower_final: {
    name: 'Gen 2.0, Low, SW3 Custom, Tumb',
    part_no: '40379-05',
  },
  upper_final: {
    name: 'Gen 2.0, Up, SW3 Custom, Tumb',
    part_no: '40380-05',
  },
};

/**
 * Retrieves current rev of custom wo
 * @function
 * @param {list} wires - List of wires
 * @returns {string} - Current custom wo rev
 */
const getCustomWoCurrentRev = (wires) => {
  for (const wire of wires) {
    if (wire.current_rev) return wire.wire_rev;
  }
  return 'A';
};

function WireWOCreationDetail(props) {
  const [showNewIcon, setShowNewIcon] = useState(false);
  useEffect(() => {
    const today = new Date();
    const endDate = new Date(END_DATE);
    setShowNewIcon(today <= endDate);
  }, []);

  const rework_in_progress = props.wire_fabrication_review_status === 'WFR Rework';
  const custom_wires = props.ws.filter((wire) => {
    if (wire.selection_override === 'none' || (wire.selection === 'none' && !wire.selection_override)) return false;
    const default_is_not_template =
      wire.selection_override === null && wire.selection !== 'template' && wire.selection !== 'universal_template' && wire.selection !== 'none';
    const user_choice_is_not_template =
      wire.selection_override !== null && wire.selection_override !== 'universal_template' && wire.selection_override !== 'template';
    const is_correct_arch = props.arch === 'both' || wire.wire_name.indexOf(props.arch) >= 0;

    if ((default_is_not_template || user_choice_is_not_template) && is_correct_arch) {
      if (rework_in_progress) {
        return wire.status === 'Failure' && wire.comment;
      }
      return true;
    }

    return false;
  });

  const sorted_wires = sortWires(custom_wires);
  const current_rev = props.current_rev;
  const custom_wo_current_rev = getCustomWoCurrentRev(props.ws);

  const adjust_bmf_file = props.ws_files.findLast((file) => file.file_type === 'ws_bmf' && file.upload_data.includes('.pdf'));
  const adjust_bmf_file_csv = props.ws_files.findLast(
    (file) => file.upload_data.includes(`${props.case_details.case_id}_adjusted_bmf.csv`) && file.file_type === 'ws_bmf_csv'
  );

  const bmf_url = props.bmf_file ? props.bmf_file.url : '';
  const bmf_rev = props.bmf_file ? props.bmf_file.bmf_rev : 'N/A';
  const bmf_csv = adjust_bmf_file_csv ? adjust_bmf_file_csv.upload_data : '';

  const guide_file = props.ws_files.find((file) => file.file_type === 'ws_guide' && file.upload_data.includes(`/${current_rev}/`));
  const custom_wo = props.ws_files.find((file) => file.file_type === 'ws_custom_wo' && file.upload_data.includes(`/${custom_wo_current_rev}/`));
  const ws_1 = props.case_details.ws_version === '1.0' ? true : false;

  /***
   * Converts custom wo db format to ui format
   * @param {String} original_filename - custom work order filename
   * @return {String} UI formatted custom wo
   */
  const convert_wo_to_ui_format = (original_filename) => {
    const remove_extension = original_filename.replace('.pdf', '');
    return remove_extension.replace('_', ' - ');
  };

  const isIfuCompleted =
    ['Tx Instruction Generation Success', 'Tx Instruction Regeneration Overridden', 'Tx Instruction Manual Process'].includes(
      props.ifuState?.txinstruction_status
    ) ||
    props.ifuState?.ifu_status === 'IFU Override Process' ||
    (props.ifuState?.txinstruction_status === 'Tx Instruction Regeneration Manual Process' && props.ifuState?.txinstruction_regenerated_manual_file);

  return (
    <div className="wire-selection-section">
      {ws_1 && (
        <WorkOrderRef
          classOverwrite={'work-ref-order--ws'}
          inputClassOverwrite="work-order-ref-textbox__ws"
          ref_no={props.ws_wo}
          label="Custom Wire WO Ref:"
          placeholder="WO1234567"
          permission={'CASE_WIRE_SELECTION_CUSTOM'}
          update_wo={(wo) => {
            props.wsSetWo(wo);
          }}
          ref_no_required={'true'}
        />
      )}
      <div className="bold-text center-text margin-top-20">
        Custom Wire WO Creation{props.case_details.ws_rework_no ? ` (Rework ${props.case_details.ws_rework_no})` : null}
      </div>
      <div className="center-text">
        The required custom wire(s) for this case has been preselected. Please create a work order in ERP and{' '}
        {ws_1 ? 'enter the Custom Wire WO ref.' : 'upload the WO.'} Click <b>Complete</b> to mark the process as complete.
      </div>
      <div className="wire-selection-links">
        {bmf_url && (
          <div className="bmf-link">
            <a className="underline-text viewable-text view-upload" href={`/${bmf_url}`} download onClick={onDownload}>
              Bracket Measurements Form <i className="fa fa-file-excel-o" aria-hidden="true" />
            </a>
            <span>
              (<span className="bold-text">Rev#: </span>
              {bmf_rev})
            </span>
          </div>
        )}
        {adjust_bmf_file && (
          <div className="center-text">
            <span
              className="underline-text viewable-text file-span"
              onClick={() => {
                props.setModal('bmf_pdf', adjust_bmf_file);
              }}
            >
              Adjusted Bracket Measurements Form
              <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </span>
          </div>
        )}
        {bmf_csv && (
          <div className="center-text">
            <a className="underline-text viewable-text file-span csv-link" href={`/${bmf_csv}`} download onClick={onDownload}>
              Download ABMF CSV
            </a>
            <i className="fa fa-file-excel-o csv-icon-file" aria-hidden="true" />
            {showNewIcon && <span className="new-icon"> New!</span>}
          </div>
        )}

        {guide_file && (
          <div className="center-text">
            <span
              className="underline-text viewable-text file-span"
              onClick={() => {
                props.setModal('ws_pdf', guide_file);
              }}
            >
              Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </span>
            {rework_in_progress ? <span className="bold-text"> (Rev#: {current_rev})</span> : null}
          </div>
        )}
      </div>
      <span className="item-details-part-id bold-text">Part No.</span>
      <span className="item-details-description bold-text">Item</span>
      <span className="item-details-quantity-alt bold-text text-center">Qty</span>
      <div className="bpps__line-divison" />

      {sorted_wires.map((wire, idx) => (
        <div key={idx}>
          <div className="item-details-part-id">{wire.wire_name in custom_part_num ? custom_part_num[wire.wire_name]['part_no'] : 'Unknown'}</div>
          <div className="item-details-description">{wire.wire_name in custom_part_num ? custom_part_num[wire.wire_name]['name'] : 'Unknown'}</div>
          <div className="item-details-quantity-alt text-center">1</div>
        </div>
      ))}

      {!ws_1 && (
        <div className="wo-upload process-box margin-top-20">
          <label className="bold-text">Custom Wire WO</label>
          {custom_wo ? (
            <>
              Successfully Uploaded
              <div className="wo-file">
                <span
                  className="wo-link"
                  onClick={() => {
                    props.setModal('wo_pdf', custom_wo);
                  }}
                >
                  {convert_wo_to_ui_format(custom_wo.original_filename)}{' '}
                </span>
                <i
                  className="fa fa-file-pdf-o"
                  onClick={() => {
                    props.setModal('wo_pdf', custom_wo);
                  }}
                />
                <i
                  className="fa fa-trash"
                  onClick={() => {
                    props.onRemoveClick('Remove Custom WO', 'ws_custom_wo', custom_wo.upload_data);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              Please Upload the WO
              <button
                className="btn btn-light"
                onClick={() => {
                  props.onUploadClick('', 'Custom WO', 'ws_custom_wo', props.redo, custom_wo_current_rev);
                }}
              >
                Upload
              </button>
              {props.custom_wo_file_name_error.error && (
                <div className="custom-wo-error">
                  {props.custom_wo_file_name_error.error_type === 'format'
                    ? `Filename should be ${removeCaseIdInitialNumber(props.case_details.case_id)} - WO-XXXXX`
                    : props.custom_wo_file_name_error.error_type === 'case_id'
                    ? 'Case ID Mismatch'
                    : props.custom_wo_file_name_error.error_type === 'length'
                    ? 'Max WO Length is 20 Chars'
                    : 'Duplicate WO Number'}
                </div>
              )}
            </>
          )}
        </div>
      )}
      <IfuRegeneration case_id={props.case_details.case_id} />
      <div className="button-panel margin-top-20">
        <button
          className="btn btn-light"
          onClick={() => {
            props.setModal('complete_ws');
          }}
          disabled={!isIfuCompleted || (ws_1 ? !props.ws_wo : !custom_wo)}
        >
          Complete
        </button>
      </div>
    </div>
  );
}

WireWOCreationDetail.propTypes = {
  custom_wo_file_name_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    custom_wo_file_name_error: getCustomWoFileNameError(state),
    ifuState: getIfuState(state),
  };
};

export { getCustomWoCurrentRev };
export default connect(mapStateToProps, null)(WireWOCreationDetail);
