import React from 'react';
import _ from 'lodash';
import Axios from 'axios';
import {
  setSelectedTeeth,
  setMessage,
  setTextBody,
  setShowArchSelection,
  setTeethSelectorLabel,
  setTreatmentArch,
  setTreatmentPlanRevision as setTeethChartTreatmentPlanRevisionAction,
  setOpposingTreatmentArch,
  setShowTextBox,
  setTextBoxLabel,
  setTextBoxPlaceholder,
  setError,
  clearTeethChartInformation,
  setShowPresets,
} from '../../common/teeth_chart';
import { orderTeeth, getUnselectedTeeth, removeOpposingTeeth, textFieldCheck, removeEmoji } from '../../../../common/functions';
import { isSmileDesignTxGuideFormCompleted } from '../../../../common/helpers';
import TeethUtils from '../../../../common/teeth_utils';

// Action Types
export const OPEN_CHANGE_TREATMENT_MODAL = 'OPEN_CHANGE_TREATMENT_MODAL';
export const CLOSE_CHANGE_TREATMENT_MODAL = 'CLOSE_CHANGE_TREATMENT_MODAL';
export const SET_EDIT_MODE_SMILE_DESIGN = 'SET_EDIT_MODE_SMILE_DESIGN';
export const SET_SMILE_DESIGN_QUESTIONS = 'SET_SMILE_DESIGN_QUESTIONS';
export const FETCH_SMILE_DESIGN_QUESTIONS_SUCCESS = 'FETCH_SMILE_DESIGN_QUESTIONS_SUCCESS';
export const OPEN_REQUESTED_TREATMENT_MODAL = 'OPEN_REQUESTED_TREATMENT_MODAL';
export const CLOSE_REQUESTED_TREATMENT_MODAL = 'CLOSE_REQUESTED_TREATMENT_MODAL';
export const SET_SMILE_DESIGN_INCOMPLETE_ERROR = 'SET_SMILE_DESIGN_INCOMPLETE_ERROR';
export const UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_ERROR = 'UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_ERROR';
export const UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_PENDING = 'UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_PENDING';
export const UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_SUCCESS = 'UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_SUCCESS';
export const SET_REQUESTED_TREATMENT = 'SET_REQUESTED_TREATMENT';
export const SET_TREATMENT_PLAN_REVISION = 'SET_TREATMENT_PLAN_REVISION';
export const OPEN_SMILE_DESIGN_TX_GUIDE_PDF_MODAL = 'OPEN_SMILE_DESIGN_TX_GUIDE_PDF_MODAL';
export const CLOSE_SMILE_DESIGN_TX_GUIDE_PDF_MODAL = 'CLOSE_SMILE_DESIGN_TX_GUIDE_PDF_MODAL';
export const SET_SMILE_DESIGN_TX_GUIDE_FILE_PATH = 'SET_SMILE_DESIGN_TX_GUIDE_FILE_PATH';
export const SET_SMILE_DESIGN_QUESTIONS_OLD = 'SET_SMILE_DESIGN_QUESTIONS_OLD';
export const SET_SMILE_DESIGN_SESSION_EXPIRED = 'SET_SMILE_DESIGN_SESSION_EXPIRED';
export const SAVE_SMILE_DESIGN_SUCCESS = 'SAVE_SMILE_DESIGN_SUCCESS';
export const RESET_SAVE_SMILE_DESIGN_SUCCESS = 'RESET_SAVE_SMILE_DESIGN_SUCCESS';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Sets selections for the case's Smile Design treatment guide
 * @function
 * @param {String} case_id - Case id
 * @param {Object} production_tx_guide - Selections to update the treatment guide with
 * @return {Object} Action type
 */
export function setSmileDesignProductionTxGuide(case_id, production_tx_guide) {
  return (dispatch, getState) => {
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);

    dispatch(clearSmileDesignIncompleteErrors());

    for (let question of Object.keys(smile_design_questions_updated)) {
      smile_design_questions_updated[question] = production_tx_guide[question];
    }
    let smile_design_questions_old = _.cloneDeep(smile_design_questions_updated);

    dispatch({
      type: FETCH_SMILE_DESIGN_QUESTIONS_SUCCESS,
      smile_design_questions: smile_design_questions_updated,
      case_id: case_id,
    });

    dispatch({
      type: SET_SMILE_DESIGN_QUESTIONS_OLD,
      smile_design_questions_old: smile_design_questions_old,
      case_id: case_id,
    });
  };
}

/**
 * Handles event when user requests/unrequests change in treatment
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onChangeInTreatment(is_tab_view) {
  return (dispatch, getState) => {
    dispatch(setSmileDesignIncompleteError('change_treatment', false));
    dispatch(setSmileDesignIncompleteError('identical_treatment', false));
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    smile_design_questions_updated.change_treatment_smile_design = !smile_design_questions.change_treatment_smile_design;

    if (is_tab_view) {
      dispatch(updateSmileDesignProductionTxGuideSuccess(smile_design_questions_updated));
    } else {
      dispatch(updateSmileDesignProductionTxGuide(smile_design_questions_updated));
    }
  };
}

/**
 * Handles event when user selects/deselects ideal inter-arch collision
 * @function
 * @param {Boolean} is_tab_view - Indicator if production tx guide form is in tab view
 * @return {Object} Action type
 */
export function onInterArchCollisionChange(is_tab_view) {
  return (dispatch, getState) => {
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    smile_design_questions_updated.inter_arch_collision = !smile_design_questions.inter_arch_collision;

    dispatch(setSmileDesignIncompleteError('inter_arch_collision_region', false));
    if (is_tab_view) {
      dispatch(updateSmileDesignProductionTxGuideSuccess(smile_design_questions_updated));
    } else {
      dispatch(updateSmileDesignProductionTxGuide(smile_design_questions_updated));
    }
  };
}

/**
 * Handles event when user specifies allowed region for idea inter-arch collision
 * @function
 * @param {Boolean} is_tab_view - Indicator if production tx guide form is in tab view
 * @return {Object} Action type
 */
export function onInterArchCollisionRegionChange(event) {
  return (dispatch, getState) => {
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    smile_design_questions_updated.inter_arch_collision_region = removeEmoji(textFieldCheck(event.target.value));

    dispatch(setSmileDesignIncompleteError('inter_arch_collision_region', false));
    dispatch({
      type: SET_SMILE_DESIGN_QUESTIONS,
      smile_design_questions: smile_design_questions_updated,
    });
  };
}

/**
 * Handles inbrace_ifs radio button change
 * @param {boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @param {boolean} value - The value to change the property to
 * @returns
 */
export function onInBraceIfsChange(is_tab_view, value) {
  return (dispatch, getState) => {
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    smile_design_questions_updated.inbrace_ifs_required = value;

    dispatch(setSmileDesignIncompleteError('inbrace_ifs_required', false));
    if (!smile_design_questions_updated.inbrace_ifs_required) {
      smile_design_questions_updated.inbrace_ifs_applied = '';
    }

    dispatch(setSmileDesignIncompleteError('inbrace_ifs_applied', false));
    if (is_tab_view) {
      dispatch(updateSmileDesignProductionTxGuideSuccess(smile_design_questions_updated));
    } else {
      dispatch(updateSmileDesignProductionTxGuide(smile_design_questions_updated));
    }
  };
}

/**
 * Handles inbrace_ifs text area input change
 * @returns
 */
export function onInBraceIfsAppliedChange(event) {
  return (dispatch, getState) => {
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    const inbraceIfsApplied = removeEmoji(textFieldCheck(event.target.value)); // removes emojis from text
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    smile_design_questions_updated.inbrace_ifs_applied = inbraceIfsApplied;
    dispatch(setSmileDesignIncompleteError('inbrace_ifs_applied', false));
    dispatch({
      type: SET_SMILE_DESIGN_QUESTIONS,
      smile_design_questions: smile_design_questions_updated,
    });
  };
}

/**
 * Handles event when user enters additional special requests from doctor
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type
 */
export function onSmileDesignNoteChange(event) {
  return (dispatch, getState) => {
    const { smile_design_questions } = getState().smileDesignProductionTxGuideReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);

    smile_design_questions_updated.smile_design_notes = removeEmoji(textFieldCheck(event.target.value));

    dispatch({
      type: SET_SMILE_DESIGN_QUESTIONS,
      smile_design_questions: smile_design_questions_updated,
    });
  };
}

/**
 * Sets error when treatment guide form is incomplete
 * @function
 * @param {String} error_type - Incomplete error type
 * @param {Boolean} is_error = True or False
 * @return {Object} Action type
 */
export function setSmileDesignIncompleteError(error_type, is_error) {
  return {
    type: SET_SMILE_DESIGN_INCOMPLETE_ERROR,
    error_type: error_type,
    is_error: is_error,
  };
}

/**
 * Toggles edit mode for treatment guide
 * @function
 * @param {Boolean} is_edit_mode - True or False
 * @return {Object} Action type
 */
export function setEditModeSmileDesign(is_edit_mode) {
  return {
    type: SET_EDIT_MODE_SMILE_DESIGN,
    is_edit_mode_smile_design: is_edit_mode,
  };
}

/**
 * Handles event when user cancels editing production tx guide
 * @function
 * @return {Object} Action type
 */
export function onEditSmileDesignTxGuideCancel() {
  return (dispatch) => {
    dispatch(fetchSmileDesignProductionTxGuide());
    dispatch(setSmileDesignIncompleteError('inter_arch_collision_region', false));
    dispatch(setSmileDesignIncompleteError('change_treatment', false));
    dispatch(setSmileDesignIncompleteError('requested_treatment', false));
    dispatch(setSmileDesignIncompleteError('identical_treatment', false));
    dispatch(setSmileDesignIncompleteError('inbrace_ifs_applied', false));
  };
}

export function setRequestedTreatment(requested_treatment) {
  return {
    type: SET_REQUESTED_TREATMENT,
    requested_treatment: requested_treatment,
  };
}

export function setTreatmentPlanRevision(treatment_plan_revision) {
  return {
    type: SET_TREATMENT_PLAN_REVISION,
    treatment_plan_revision: treatment_plan_revision,
  };
}

/**
 * Retrieves production tx guide for a case
 * @function
 * @return {Object} Action type
 */
export function fetchSmileDesignProductionTxGuide() {
  return (dispatch, getState) => {
    const { case_id } = getState().smileDesignProductionTxGuideReducer;
    Axios.get(`/apiv3/productiontxguide/${case_id}`)
      .then((res) => {
        dispatch(setSmileDesignProductionTxGuide(case_id, res.data));
        dispatch(setEditModeSmileDesign(false));
      })
      .catch((error) => {});
  };
}

/**
 * API call for updating the production tx guide
 * @function
 * @param {Object} updates - Selections to update the production tx guide with
 * @return {Object} Action type
 */
export function updateSmileDesignProductionTxGuide(updates) {
  return (dispatch, getState) => {
    const { case_id, smile_design_questions_old } = getState().smileDesignProductionTxGuideReducer;
    const data = { update_data: _.cloneDeep(updates), old_values: smile_design_questions_old };
    if (!data.update_data.change_treatment_smile_design) {
      data.update_data.change_treatment_smile_design_selections = {};
    }
    dispatch(updateSmileDesignProductionTxGuidePending());
    Axios.put(`/apiv3/productiontxguide/${case_id}/smile_design`, data)
      .then((res) => {
        dispatch(updateSmileDesignProductionTxGuideSuccess(updates));
        dispatch(closeChangeTreatmentModal());
        dispatch(closeRequestedTreatmentModal());
        dispatch({
          type: SET_SMILE_DESIGN_QUESTIONS_OLD,
          smile_design_questions_old: _.cloneDeep(data.update_data),
        });
        dispatch(setSaveSmileDesignSelectionSuccess());
      })
      .catch((error) => {
        if (error.response.status === 409) {
          dispatch({
            type: SET_SMILE_DESIGN_SESSION_EXPIRED,
            session_expired: true,
          });
        }
        dispatch(updateSmileDesignProductionTxGuideError(error.response.status));
      });
  };
}

export function updateSmileDesignProductionTxGuideSuccess(smile_design_questions) {
  return {
    type: UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_SUCCESS,
    smile_design_questions: smile_design_questions,
  };
}

export function updateSmileDesignProductionTxGuidePending() {
  return {
    type: UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_PENDING,
  };
}

export function updateSmileDesignProductionTxGuideError(status_code) {
  return {
    type: UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_ERROR,
    session_expire: status_code === 409,
  };
}

export function setSaveSmileDesignSelectionSuccess() {
  return {
    type: SAVE_SMILE_DESIGN_SUCCESS,
  };
}

export function resetSmileDesignSelectionsSuccess() {
  return {
    type: RESET_SAVE_SMILE_DESIGN_SUCCESS,
  };
}

/**
 * Handles event when user saves production tx guide edits in tab
 * @function
 * @return {Object} Action type
 */
export function saveSmileDesignProductionTxGuideSelections() {
  return (dispatch, getState) => {
    const { smile_design_questions, case_id, requested_treatment, treatment_plan_revision } = getState().smileDesignProductionTxGuideReducer;
    const form_completion = isSmileDesignTxGuideFormCompleted(smile_design_questions, case_id, requested_treatment, true, treatment_plan_revision);

    if (form_completion.is_completed) {
      if (!smile_design_questions.change_treatment_smile_design) {
        smile_design_questions.change_treatment_smile_design_selections = {};
      }
      if (!smile_design_questions.inbrace_ifs_required) {
        smile_design_questions.inbrace_ifs_applied = '';
      }

      if (!smile_design_questions.inter_arch_collision) {
        smile_design_questions.inter_arch_collision_region = '';
      }

      dispatch(updateSmileDesignProductionTxGuide(smile_design_questions));
      dispatch(setEditModeSmileDesign(false));
    } else {
      for (const incompleted_type of form_completion.incompleted_types) {
        dispatch(setSmileDesignIncompleteError(incompleted_type, true));
      }
    }
  };
}

// Modals

/**
 * Opens Change in Treatment teeth selector modal
 * @function
 * @return {Object} Action type
 */
export function openChangeTreatmentModal() {
  return (dispatch, getState) => {
    const { smile_design_questions, requested_treatment, treatment_plan_revision } = getState().smileDesignProductionTxGuideReducer;

    const modal_text_body = getTeethSelectorModalTextBody(
      getChangeTreatmentInformation(smile_design_questions, 'arch_to_treat', requested_treatment),
      getChangeTreatmentInformation(smile_design_questions, 'no_bracket_bonded', requested_treatment)
    );

    dispatch(setTeethChartTreatmentPlanRevisionAction(treatment_plan_revision));
    dispatch(setSmileDesignIncompleteError('change_treatment', false));
    dispatch(setSmileDesignIncompleteError('identical_treatment', false));
    dispatch(setMessage('Specify change in treatment.'));
    dispatch(setTeethSelectorLabel('Extent of Treatment:'));
    dispatch(setShowArchSelection(true));
    dispatch(setShowTextBox(true));
    dispatch(setTextBoxLabel('Reason(s)*:'));
    dispatch(setTextBoxPlaceholder('Enter reason(s) here...'));
    dispatch(setTextBody(modal_text_body));
    dispatch(setTreatmentArch(getChangeTreatmentInformation(smile_design_questions, 'treatment_arch', requested_treatment, 'both')));
    dispatch(setOpposingTreatmentArch(getChangeTreatmentInformation(smile_design_questions, 'opposing_treatment_arch', requested_treatment, '')));
    dispatch(setSelectedTeeth(getChangeTreatmentInformation(smile_design_questions, 'selected_teeth', requested_treatment, [])));
    dispatch(setShowPresets());

    dispatch({
      type: OPEN_CHANGE_TREATMENT_MODAL,
    });
  };
}

/**
 * Gets values selected from Change in Treatment (to display in the teeth selector modal)
 * @function
 * @param {Object} questions - Production tx guide questions
 * @param {String} treatment_item - Item to retrieve
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} default_value - Default value if item doesn't exist
 * @return {String} Change in Treatment value
 */
function getChangeTreatmentInformation(questions, treatment_item, requested_treatment, default_value = null) {
  return questions.change_treatment_smile_design_selections[treatment_item]
    ? questions.change_treatment_smile_design_selections[treatment_item]
    : questions.requested_treatment_selections[treatment_item]
    ? questions.requested_treatment_selections[treatment_item]
    : requested_treatment[treatment_item]
    ? requested_treatment[treatment_item]
    : default_value;
}

/**
 * Displays arch to treat and teeth with no bracket bonded to display in teeth selector modal
 * @function
 * @param {String} arch_to_treat - Arch to treat
 * @param {Array} no_bracket_bonded - Teeth with no bracket bonded
 * @return {JSX} JSX with teeth information
 */
function getTeethSelectorModalTextBody(arch_to_treat, no_bracket_bonded) {
  return (
    <div className="p-slim grey-text modal-text-body">
      <div>Current Treatment: {arch_to_treat ? arch_to_treat : 'N/A'}.</div>
      <div>Teeth Excluded from Treatment: {no_bracket_bonded && no_bracket_bonded.length > 0 ? no_bracket_bonded.join(', ') : 'N/A'}</div>
    </div>
  );
}

/**
 * Opens Requested Treatment teeth selector modal
 * @function
 * @return {Object} Action type
 */
export function openRequestedTreatmentModal() {
  return (dispatch, getState) => {
    const { smile_design_questions, requested_treatment } = getState().smileDesignProductionTxGuideReducer;

    dispatch(setSmileDesignIncompleteError('requested_treatment', false));
    dispatch(setMessage('Specify requested treatment.'));
    dispatch(setTeethSelectorLabel('Extent of Treatment:'));
    dispatch(setShowArchSelection(true));
    dispatch(setTreatmentArch(getRequestedTreatmentInformation(smile_design_questions, 'treatment_arch', requested_treatment, 'both')));
    dispatch(setOpposingTreatmentArch(getRequestedTreatmentInformation(smile_design_questions, 'opposing_treatment_arch', requested_treatment, '')));
    dispatch(setSelectedTeeth(getRequestedTreatmentInformation(smile_design_questions, 'selected_teeth', requested_treatment, [])));
    dispatch(setShowPresets());

    dispatch({
      type: OPEN_REQUESTED_TREATMENT_MODAL,
    });
  };
}

/**
 * Gets values from Requested Treatment (to display in the teeth selector modal)
 * @function
 * @param {Object} questions - Treatment guide questions
 * @param {String} treatment_item - Item to retrieve
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} default_value - Default value if item doesn't exist
 * @return {String} Requested Treatment value
 */
function getRequestedTreatmentInformation(questions, treatment_item, requested_treatment, default_value = null) {
  return questions.requested_treatment_selections[treatment_item]
    ? questions.requested_treatment_selections[treatment_item]
    : requested_treatment[treatment_item]
    ? requested_treatment[treatment_item]
    : default_value;
}

export function closeRequestedTreatmentModal() {
  return (dispatch) => {
    dispatch(clearTeethChartInformation());
    dispatch({
      type: CLOSE_REQUESTED_TREATMENT_MODAL,
    });
  };
}

export function closeChangeTreatmentModal() {
  return (dispatch) => {
    dispatch(clearTeethChartInformation());
    dispatch({
      type: CLOSE_CHANGE_TREATMENT_MODAL,
    });
  };
}

/**
 * Handles event when user saves teeth selection in Change in Treatment modal
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onConfirmButtonClickChangeTreatmentModal(is_tab_view, gen_2) {
  return (dispatch, getState) => {
    const { smile_design_questions, requested_treatment, case_id } = getState().smileDesignProductionTxGuideReducer;
    const { selected_teeth, treatment_arch, opposing_treatment_arch, text_box_note } = getState().teethChartReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    const formatted_teeth = orderTeeth(removeOpposingTeeth(treatment_arch, selected_teeth));
    const error = getError(
      smile_design_questions_updated,
      formatted_teeth,
      treatment_arch,
      requested_treatment,
      opposing_treatment_arch,
      text_box_note,
      'change_treatment'
    );

    if (_.isEmpty(error)) {
      smile_design_questions_updated.change_treatment_smile_design_selections = {
        selected_teeth: formatted_teeth,
        treatment_arch: treatment_arch,
        opposing_treatment_arch: opposing_treatment_arch,
        arch_to_treat: TeethUtils.formatTreatmentArch(treatment_arch, formatted_teeth, opposing_treatment_arch, case_id),
        no_bracket_bonded: getUnselectedTeeth(formatted_teeth),
        reason: text_box_note,
      };

      if (gen_2 && case_id.includes('-DE')) {
        const upperWires = ['Upper DE Smartwire 1', 'Upper DE Smartwire 2'];
        const lowerWires = ['Lower DE Smartwire 1', 'Lower DE Smartwire 2'];
        smile_design_questions_updated.wire_selections =
          treatment_arch === 'upper' ? upperWires : treatment_arch === 'lower' ? lowerWires : upperWires.concat(lowerWires);
      }
      if (is_tab_view) {
        dispatch(updateSmileDesignProductionTxGuideSuccess(smile_design_questions_updated));
        dispatch(closeChangeTreatmentModal());
      } else {
        dispatch(updateSmileDesignProductionTxGuide(smile_design_questions_updated));
      }
    } else {
      error.type ? dispatch(setTeethSelectionError(error.message, error.type)) : dispatch(setTeethSelectionError(error.message));
    }
  };
}

/**
 * Determines if there is an error in the teeth selector modals
 * @function
 * @param {Object} questions - Treatment guide questions
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} treatment_arch - Selector treatment arch
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} text_box_note - Note entered in text box in modal
 * @param {String} modal - Modal type
 * @return {Object} Error information
 */
function getError(questions, selected_teeth, treatment_arch, requested_treatment, opposing_treatment_arch, text_box_note = '', modal = '') {
  let error = {};
  if (selected_teeth.length === 0) {
    error.message = 'Please specify the extent of treatment.';
  } else if (hasSameTeethAsRequestedTreatment(questions, selected_teeth, requested_treatment, opposing_treatment_arch)) {
    error.message = 'Your treatment selections are identical to the requested treatment. Please update again.';
  } else if (hasSameTeethAsCurrentTreatment(questions, selected_teeth, requested_treatment, opposing_treatment_arch)) {
    error.message = 'Your treatment selections are identical to the current treatment. Please update again.';
  } else if (hasMissingTeethFromArch(selected_teeth, treatment_arch, 'upper')) {
    error.message = 'Please specify the extent of treatment for the upper arch.';
  } else if (hasMissingTeethFromArch(selected_teeth, treatment_arch, 'lower')) {
    error.message = 'Please specify the extent of treatment for the lower arch.';
  } else if (modal === 'change_treatment' && !text_box_note) {
    error.message = 'Please enter reason for change in treatment.';
    error.type = 'text_box';
  }
  return error;
}

/**
 * Determines if selected teeth is identical to the current treatment
 * @function
 * @param {Object} questions - Treatment guide questions
 * @param {Array} selected_teeth - Selected teeth
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} opposing_treatment_arch - Selected opposing treatment arch
 * @return {Boolean} True or False
 */
function hasSameTeethAsCurrentTreatment(questions, selected_teeth, requested_treatment, opposing_treatment_arch) {
  const current_treatment_teeth = getChangeTreatmentInformation(questions, 'selected_teeth', requested_treatment, []);
  const current_opposing_treatment_arch = getChangeTreatmentInformation(questions, 'opposing_treatment_arch', requested_treatment, '');
  const same_arch_to_treat = questions.change_treatment_smile_design_selections.arch_to_treat === questions.requested_treatment_selections.arch_to_treat;
  return (
    same_arch_to_treat &&
    questions.change_treatment_smile_design &&
    selected_teeth.join(',') === orderTeeth(current_treatment_teeth).join(',') &&
    opposing_treatment_arch === current_opposing_treatment_arch
  );
}

/**
 * Determines if selected teeth is identical to the requested treatment
 * @function
 * @param {Object} questions - Treatment guide questions
 * @param {Array} selected_teeth - Selected teeth
 * @param {Object} requested_treatment - Original requested treatment information
 * @param {String} opposing_treatment_arch - Selected opposing treatment arch
 * @return {Boolean} True or False
 */
function hasSameTeethAsRequestedTreatment(questions, selected_teeth, requested_treatment, opposing_treatment_arch) {
  const requested_treatment_teeth = getRequestedTreatmentInformation(questions, 'selected_teeth', requested_treatment, []);
  const requested_opposing_treatment_arch = getRequestedTreatmentInformation(questions, 'opposing_treatment_arch', requested_treatment, '');
  return selected_teeth.join(',') === orderTeeth(requested_treatment_teeth).join(',') && opposing_treatment_arch === requested_opposing_treatment_arch;
}

/**
 * Determines if selected teeth is missing teeth from upper or lower arch
 * @function
 * @param {Array} selected_teeth - Selected teeth
 * @param {String} treatment_arch - Selected treatment arch
 * @param {String} arch - Upper or Lower
 * @return {Boolean} True or False
 */
function hasMissingTeethFromArch(selected_teeth, treatment_arch, arch) {
  return treatment_arch === 'both' && selected_teeth.filter((tooth) => tooth.startsWith(`${arch[0].toUpperCase()}`)).length === 0;
}

/**
 * Sets error to be displayed in teeth selector modal
 * @function
 * @param {String} error_message - Error message
 * @param {String} error_type - Type of error
 * @return {Object} Action type
 */
function setTeethSelectionError(error_message, error_type = 'teeth_selection') {
  return (dispatch) => {
    dispatch(setError(true, error_message, error_type));
  };
}

/**
 * Handles event when user saves teeth selection from Requested Treatment modal
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onConfirmButtonClickRequestedTreatmentModal(is_tab_view) {
  return (dispatch, getState) => {
    const { smile_design_questions, requested_treatment, case_id } = getState().smileDesignProductionTxGuideReducer;
    const { selected_teeth, treatment_arch, opposing_treatment_arch } = getState().teethChartReducer;
    let smile_design_questions_updated = _.cloneDeep(smile_design_questions);
    const formatted_teeth = orderTeeth(removeOpposingTeeth(treatment_arch, selected_teeth));
    const error = getError(smile_design_questions_updated, formatted_teeth, treatment_arch, requested_treatment, opposing_treatment_arch);

    if (_.isEmpty(error)) {
      smile_design_questions_updated.requested_treatment_selections = {
        selected_teeth: formatted_teeth,
        treatment_arch: treatment_arch,
        opposing_treatment_arch: opposing_treatment_arch,
        arch_to_treat: TeethUtils.formatTreatmentArch(treatment_arch, formatted_teeth, opposing_treatment_arch, case_id),
        no_bracket_bonded: getUnselectedTeeth(formatted_teeth),
      };
      if (is_tab_view) {
        dispatch(updateSmileDesignProductionTxGuideSuccess(smile_design_questions_updated));
        dispatch(closeRequestedTreatmentModal());
      } else {
        dispatch(updateSmileDesignProductionTxGuide(smile_design_questions_updated));
      }
    } else {
      dispatch(setTeethSelectionError(error.message));
    }
  };
}

export function openSmileDesignTxGuidePdfModal(case_id) {
  return (dispatch) => {
    dispatch({
      type: OPEN_SMILE_DESIGN_TX_GUIDE_PDF_MODAL,
      file_path: '',
    });
    Axios.post(`/apiv3/productiontxguide/${case_id}/generate`).then((res) => {
      dispatch({
        type: SET_SMILE_DESIGN_TX_GUIDE_FILE_PATH,
        file_path: res.data.file_path,
      });
    });
  };
}

export function closeSmileDesignTxGuidePdfModal() {
  return {
    type: CLOSE_SMILE_DESIGN_TX_GUIDE_PDF_MODAL,
  };
}

function clearSmileDesignIncompleteErrors() {
  return (dispatch) => {
    dispatch(setSmileDesignIncompleteError('inter_arch_collision_region', false));
    dispatch(setSmileDesignIncompleteError('change_treatment', false));
    dispatch(setSmileDesignIncompleteError('requested_treatment', false));
    dispatch(setSmileDesignIncompleteError('identical_treatment', false));
    dispatch(setSmileDesignIncompleteError('inbrace_ifs_applied', false));
  };
}
