import Axios from 'axios';
import Modal from '../../../modal/modal';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchIfuState } from '../../../../redux/actions/ifu/ifu_state';
import { getIfuState } from '../../../../redux/reducers/ifu/ifu_state';
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

const IfuRegeneration = ({ ifuState, fetchIfuState, case_id }) => {
  const [showIfuOverrideModal, setShowIfuOverrideModal] = useState(false);

  return (
    <>
      {ifuState?.txinstruction_status === 'Tx Instruction Gen2 Generation Process' ? (
        <div className="text-center margin-top-30">
          <div className="margin-bottom-10">IFU regeneration in progress...</div>
          <i className="fa fa-refresh font-size-xlg rotate" aria-hidden="true" />
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('CASE_IFU_OVERRIDE', user_roles_and_permissions.permissions) ? (
                <div className="margin-top-20">
                  <button className="btn btn-light" onClick={() => setShowIfuOverrideModal(true)}>
                    Override
                  </button>
                </div>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        </div>
      ) : ifuState?.txinstruction_status === 'Tx Instruction Generation Failed' ? (
        <div className="text-center margin-top-20 warning-text">
          <div>IFU regeneration failed for the following reason:</div>
          <div>{ifuState.txinstruction_status_comment}</div>
        </div>
      ) : ifuState?.txinstruction_status === 'Tx Instruction Regeneration Manual Process' && !ifuState?.txinstruction_regenerated_manual_file ? (
        <div className="text-center margin-top-20 warning-text">Please upload new updated IFU above</div>
      ) : null}
      {showIfuOverrideModal && (
        <Modal
          preset="decision"
          header_text="Override IFU Regeneration"
          message_text="Are you sure you want to override IFU regeneration?"
          confirm_btn_text="Override"
          close_btn_text="Cancel"
          onCloseButtonClick={() => setShowIfuOverrideModal(false)}
          onConfirmButtonClick={() => {
            Axios.post(`/apiv3/wireselection/${case_id}/override_ifu`).then(function (res) {
              if (res && res.data) {
                fetchIfuState(case_id);
              }
              setShowIfuOverrideModal(false);
            });
          }}
          theme="bpp"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ifuState: getIfuState(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIfuState: fetchIfuState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IfuRegeneration);
